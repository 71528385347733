import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, useMediaQuery } from '@mui/material';
import useSplashImage from "@hooks/SplashScreen/useSplashImage"
import useFetchData from '@hooks/SplashScreen/useFetchData';
import MuiLinearProgress from '@components/MuiLinearProgress';
import { sendPostMessageToParent } from '@functions/trafficController';
import WeatherWarning from '@pages/checkout/WeatherWarning';
import useCheckToken from '@hooks/SplashScreen/useCheckToken';
const useStyles = makeStyles({
  container: {
    margin: '0 auto',
    height: '100vh',
    backgroundColor: '#FFFFFF',
    position: 'relative'
  },
  image: {
    height: '100vh',
    objectFit: 'cover',
    width: '100%',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: (props) => (props.mode ? '#eee' : 'black'),
  },
  colorPrimary: {
    backgroundColor: (props) => (props.mode ? '#020202' : '#eee'),
  },
});

const SplashScreen = (props) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const classes = useStyles({
    mode: prefersDarkMode,
  });
  useCheckToken()

  const [modal, setModal] = useState(false);
  const getConfig = (data) => {
    if (data && data.use_sicc_member && data.use_weather_warning && credentials.sicc) {
      setModal(true);
    } else {
      //langsung next
      nextSplahscreen();
    }
  }
  const getSplashScreen = useSplashImage()
  const [realmConfig, nextSplahscreen, credentials] = useFetchData(props.setTheme, getConfig)

  const handleImageShown = () => {
    sendPostMessageToParent("splashShown", "testing")
  }


  //weather true dia ke modal dulu
  //modal yes dia ke next splash
  //weather false langsung next splash
  const clickModal = (data) => {
    setModal(false);
    if (data) {
      ///ke splashscrenn
      nextSplahscreen();
    } else {
      // ke page lain
      window.top.close();
      console.log('ke page lainn-------');
    }
  }



  return (
    <Box
      className={classes.container}
    >
      <MuiLinearProgress />
      <img alt="placeholder12345678" style={{ maxWidth: 450, maxHeight: '100vh', height: '100%', width: "100%", objectFit: 'cover' }} src={getSplashScreen("splash")} onLoad={handleImageShown} />
      {
        modal &&
        <WeatherWarning actionClick={(value) => clickModal(value)} theme={realmConfig.config} />
      }
    </Box>
  );
};

export default SplashScreen;
