import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import {
  getBackgroundColor,
  getPrimaryFontColor,
  getSecondaryFontColor,
  getSecondaryThemeColor,
  getPrimaryThemeColor,
} from '@functions/ThemeSettings';
import OswaldTTF from '@assets/fonts/Oswald-Regular.ttf';
import MerriweatherTTF from '@assets/fonts/Merriweather-Regular.ttf';
import HelveticaNeueTTF from '@assets/fonts/HelveticaNeueMedium.ttf';
import UrbanistTTF from '@assets/fonts/Urbanist.ttf';
import PublicSansTTF from '@assets/fonts/PublicSans.ttf';
import OutfitTTF from '@assets/fonts/Outfit.ttf';
import ManropeTTF from '@assets/fonts/Manrope.ttf';
import InterTTF from '@assets/fonts/Inter.ttf';
import DMSansTTF from '@assets/fonts/DM_Sans.ttf';

const fastSolution = (parameter) => {
  return new URLSearchParams(window.location.href).get(parameter);
};

let primaryThemeColor = getPrimaryThemeColor();
let secondaryThemeColor = getSecondaryThemeColor();
let primaryFontColor = getPrimaryFontColor();
let secondaryFontColor = getSecondaryFontColor();
let backgroundColor = fastSolution('background') || getBackgroundColor();

let theme = createTheme({
  palette: {
    primary: {
      main: primaryThemeColor,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: secondaryThemeColor,
      contrastText: '#000000',
    },
    error: {
      main: '#B00020',
    },
    warning: {
      main: '#E2CF21',
    },
    info: {
      main: '#0096C7',
    },
    success: {
      main: '#02A328',
    },
    text: {
      primary: primaryFontColor,
      secondary: secondaryFontColor,
      disabled: '#C7C7C7',
      grey: '#646463',
    },
  },
  typography: {
    fontFamily: '"Helvetica Neue", sans-serif',
  },
  // Using Assets Font
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Oswald';
          src: local('Oswald'), url(${OswaldTTF}) format('truetype');
        }
        @font-face {
          font-family: 'Merriweather';
          src: local('Merriweather'), url(${MerriweatherTTF}) format('truetype');
        }
        @font-face {
          font-family: 'Helvetica Neue';
          src: local('Oswald'), url(${HelveticaNeueTTF}) format('truetype');
        }
        @font-face {
          font-family: 'Urbanist';
          src: local('Urbanist'), url(${UrbanistTTF}) format('truetype');
        }
        @font-face {
          font-family: 'Public Sans';
          src: local('Public Sans'), url(${PublicSansTTF}) format('truetype');
        }
        @font-face {
          font-family: 'Outfit';
          src: local('Outfit'), url(${OutfitTTF}) format('truetype');
        }
        @font-face {
          font-family: 'Manrope';
          src: local('Manrope'), url(${ManropeTTF}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          src: local('Inter'), url(${InterTTF}) format('truetype');
        }
        @font-face {
          font-family: 'DM Sans';
          src: local('DM Sans'), url(${DMSansTTF}) format('truetype');
        }


      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    // Add any other components that need the font override
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
        },
      },
    },
  },
  spacing: 4,
  containerWidth: 450,
  siteBackgroundColor: backgroundColor,
});

theme = responsiveFontSizes(theme);

// Function to update theme font dynamically
export const updateFontFamily = (fontFamily, scale = 1) => {
  // console.log('Before Updated theme typography:', theme.typography);
  // console.log('Font Scale: ', scale);
  const baseFontSize = 1;
  const keysToUpdate = ['subtitle1', 'subtitle2', 'body1', 'body2'];

  // Dynamically update the font family across all typography styles
  Object.keys(theme.typography).forEach((key) => {
    if (
      keysToUpdate.includes(key) &&
      typeof theme.typography[key] === 'object'
    ) {
      theme.typography[key].fontFamily = fontFamily;
      // Ensure no cumulative scaling
      const currentFontSize = theme.typography[key].fontSize;
      if (
        typeof currentFontSize === 'string' &&
        currentFontSize.endsWith('rem')
      ) {
        // Scale relative to the base size
        theme.typography[key].fontSize = `${baseFontSize * scale}rem`;
      } else if (typeof currentFontSize === 'number') {
        // For numeric font sizes, scale based on the base size
        theme.typography[key].fontSize = baseFontSize * scale;
      } else {
        // Fallback for missing or invalid fontSize
        theme.typography[key].fontSize = `${baseFontSize * scale}rem`;
      }
    }
  });

  // Update the global fontFamily property
  theme.typography.fontFamily = fontFamily;
  theme.typography.fontSize = theme.typography.fontSize * scale;

  // console.log('Updated theme typography:', theme.typography);
  window.dispatchEvent(new CustomEvent('themeChange'));
};

export default theme;
