import { create } from 'zustand';
import { persist } from 'zustand/middleware';
export const useStoreSellTime = create(
  persist(
    (set, get) => ({
      sellTimeProducts: [],
      invalidTimeProducts: [],
      setSellTimeProducts: (newData) => {
        set((state) => ({
          sellTimeProducts: [...state.sellTimeProducts, ...newData],
        }));
      },
      setInvalidTimeProducts: (newData) => {
        set({ invalidTimeProducts: newData });
      },
      clearSellTimeProducts: () => {
        set({ sellTimeProducts: [] });
      },
      clearInvalidTImeProducts: () => {
        set({ invalidTimeProducts: [] });
      },
      updateSellTimeProducts: (invalidItems) => {
        const sellTimeProducts = get().sellTimeProducts;
        const updatedSellTimeProducts = sellTimeProducts.map((product) => {
          let newInvalid = false;
          console.log('invalidItems', invalidItems);
          invalidItems.map((invalidItem) => {
            if (invalidItem.is_variant && invalidItem.variant_item.length > 0) {
              newInvalid = invalidItem.variant_item.some(
                (varItem) => varItem.uuid_product === product.uuid_product,
              );
              console.log('newInvalid - variant', newInvalid);
            } else {
              // For non-variant products, check by product uuid.
              newInvalid = invalidItems.some(
                (invalidItem) =>
                  invalidItem.uuid_product === product.uuid_product,
              );
              console.log('newInvalid - non variant', newInvalid);
            }
          });

          // Keep product inactive if it's already inactive;
          // otherwise, set it inactive if found in invalidItems.
          const newIsActive = product.is_active === false ? false : !newInvalid;

          return {
            ...product,
            is_active: newIsActive,
          };
        });
        set({ sellTimeProducts: updatedSellTimeProducts });
      },
    }),
    {
      name: 'list_selltime',
    },
  ),
);
