export const getTimezoneName = (offset) => {
  switch (offset) {
    case '+7':
      return 'Asia/Jakarta';
    case '+8':
      return 'Asia/Singapore';
    case '+9':
      return 'Asia/Tokyo';
    // Add other cases as needed
    default:
      return 'Asia/Jakarta'; // Default case
  }
};
