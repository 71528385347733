import { sendErrorData } from '@functions/ErrorMessagesSender';
import URLS from '@/URLS';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreAppData } from '@state/useGlobalState';
import useHITApi from '@hooks/useHITApi';

export default function useCheckValidStore() {
    const history = useHistory();
    const { appData } = useStoreAppData(state => ({ appData: state.appData }))
    const { config } = appData;
    const [valid, setValid] = useState(false);
    const hitAPI = useHITApi();

    const fecthCheckValidStore = async () => {
        let result;
        const table_id = config.tableID
        try {
            if (!config.tableID) return true;
            const CHECK_STORE = import.meta.env[
                `VITE_REACT_APP_GET_CHECK_VALID_STORE_${import.meta.env.MODE}`
            ];
            return await hitAPI(CHECK_STORE, 'POST', {table_id}, true);
        } catch (error) {
            sendErrorData({
                message: `${error.message} | ${result ? JSON.stringify(result.json()) : 'null result from server'}`,
                table: config.tableName,
                file: 'useCheckValidStore.js',
                func: 'fecthCheckValidStore()',
                payload: table_id
            });
            return false;
        }
    }

    const checkValidStore = async () => {
        if (config.order_method === 'normal_order' || config.order_method === 'waiter_order') {
            let validStore = await fecthCheckValidStore();
            if (!validStore) {
                setValid(false);
                sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
                history.replace(URLS.CONNECTION_FAILURE, {
                    message: 'The selected table is unavailable at this store.'
                });
            }
        }
        setValid(true);
    }

    useEffect(() => {
        if (config && config !== undefined && config !== null) {
            checkValidStore();
        }
    }, [appData])

    return valid;
}
