import { create } from 'zustand';
import localStorageNames from '@data/localStorageNames';
import { useStoreFirstCredential } from './useStoreFirstCredential';
import countObjectValues from '@functions/countObjectValues';
import { sumTotalPriceWithPrepItem } from '@functions/sumTotalPriceWithPrepItem';
import { useStoreOrderCategory } from './useStoreOrderCategory';
import { persist } from 'zustand/middleware';
import { checkItemIsHaveGroup } from '@functions/checkItemHaveGroup';
import {
  updateChildItemsForParent,
  adjustGroupItems,
} from '@libs/CartItem/changeQuantity';

function updateChildChooseMax(data) {
  // Find all parent groups with their quantities
  const parentGroups = data.filter(
    (item) => item.isParentGroup && item.group?.length > 0,
  );

  parentGroups.forEach((parent) => {
    const parentQuantity = parent.quantity;

    // Find child groups related to the parent
    data.forEach((child) => {
      if (child.parentGroupId === parent.groupId) {
        if (child.choose.baseMax === undefined) {
          // Initialize baseMax only once
          child.choose.baseMax = child.choose.max;
        }
        // Calculate the updated max value based on the parent's quantity
        child.choose.max = child.choose.baseMax * parentQuantity;
      }
    });
  });

  return data;
}

export const useStoreCartItems = create(
  persist(
    (set, get) => ({
      cartItems: [],
      setCartItems: (newItems) => {
        // Process all parent items and their children
        // Thist function will update the child of parent item
        updateChildChooseMax([...newItems]);

        // Set the processed items to cart
        set({ cartItems: newItems });
      },

      setCartItemLocalStorage: (newData) => {
        if (useStoreFirstCredential.getState().isLoggedIn()) {
          set({ cartItems: newData });
        }
      },
      getCartItems: () => {
        return get().cartItems;
      },
      clearCart: () => {
        set({ cartItems: [] });
      },
      getBadgeCount: () => {
        if (get().getCartItems().length === 0) {
          return 0;
        }
        return countObjectValues(get().getCartItems(), 'quantity');
      },
      totalQty: () => {
        return countObjectValues(get().getCartItems(), 'quantity');
      },
      totalPrice: () => {
        let subTotal = 0;
        get()
          .getCartItems()
          .forEach((item) => {
            subTotal += sumTotalPriceWithPrepItem(
              item,
              item.quantity,
              useStoreOrderCategory.getState().getSellBandId(),
            );
          });
        return subTotal;
      },
      totalPriceWithCharge: (serviceCharge) => {
        const totalAmount = serviceCharge.reduce((sum, item) => {
          return sum + item.amount;
        }, 0);
        return totalAmount + get().totalPrice();
      },
      changeCartItemQuantity: (item, quantity) => {
        let items = get().getCartItems();

        // Handle parent group quantity changes
        if (item.hasOwnProperty('isParentGroup') && item.group?.length > 0) {
          items = updateChildItemsForParent(items, item, quantity);
        }

        // Handle individual item quantity changes
        if (item.uuid_group) {
          items = adjustGroupItems(
            items,
            item,
            quantity,
            item.choose?.max || 0,
          );
        } else {
          items = items.map((cartItem) =>
            cartItem.uuid_product === item.uuid_product &&
            cartItem.timestamp === item.timestamp
              ? { ...cartItem, quantity }
              : cartItem,
          );
        }

        // Remove items with zero quantity and update cart
        // items = items.filter((item) => item.quantity > 0);
        get().setCartItems(items);
      },
      isChooseRewards: () => {
        let parentItems = get()
          .getCartItems()
          .filter((x) => x.promoFrom === undefined);
        const lastParentItem = parentItems[parentItems.length - 1];
        let rewardItems = get()
          .getCartItems()
          .filter((x) => x.promoFrom === lastParentItem.timestamp);
        if (lastParentItem.quantity === rewardItems.length) {
          return true;
        } else {
          return false;
        }
      },
      removeItemInCart: (item) => {
        let rawItems = get().getCartItems();

        // If item is a parent group, remove it and all its children
        if (checkItemIsHaveGroup(item) && item.groupId) {
          rawItems = rawItems.filter(
            (cartItem) =>
              cartItem.groupId !== item.groupId && // Remove parent
              cartItem.parentGroupId !== item.groupId, // Remove children
          );
        }

        // Remove the specific item by timestamp
        rawItems = rawItems.filter((x) => x.timestamp !== item.timestamp);

        // Remove any promo items linked to this item
        rawItems = rawItems.filter(
          (oldItem) => oldItem.promoFrom !== item.timestamp,
        );

        set({ cartItems: rawItems });
      },
      removePromoItem: (promoItem) => {
        let rawItems = get().getCartItems();

        // Look for a valid parent whose identifier matches the child's promoFrom.
        const parentExists = rawItems.some(
          (item) =>
            !item.promoFrom && // must be a parent
            item.timestamp === promoItem.promoFrom,
        );

        // If no valid parent exists, remove all children associated with that parent.
        if (!parentExists) {
          const updatedItems = rawItems.filter(
            (item) => item.promoFrom !== promoItem.promoFrom,
          );
          set({ cartItems: updatedItems });
        }
      },
    }),
    {
      name: localStorageNames.CART_ITEMS,
    },
  ),
);
