import React, { useEffect, useState } from 'react';
import { Box, ButtonBase, Grid, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import ReButtonQuantity from '@components/ReButtonQuantity';
import DetailOrder from './DetailOrder';
import { convertCurrency } from '@functions/convertNumber';
import { getPriceBySellBand } from '@functions/getItemPriceBySellBand';
import theme from '@styles/theme';
import { useCartContext } from '@context/CartContext';
import { useStoreCartItems } from '@state/useStoreCartItems';
import { useStoreOrderCategory } from '@state/useStoreOrderCategory';
import { useStoreAppColor } from '@state/useStoreAppColor';
import useStyles from './checkout.styles';
import { getItem } from '@libs/CartItem/getItem';
import { isProductHaveRewards } from '@libs/CartItem/helper';
import { useGeneralContext } from '@context/GeneralContext';
const DetailOrderVariant = ({ item, index }) => {
  const { getContextState } = useCartContext();
  const showPayment = getContextState('paymentResId').visible;
  let prepGroup = getItem(item);

  return (
    <Grid
      item
      sx={{ width: '100%', paddingLeft: showPayment ? theme.spacing(2) : 0 }}>
      <DetailOrder
        selectedItem={item}
        prepGroup={prepGroup || []}
        additionalRequest={item.additional_request || []}
        subProduct={item.sub_product || []}
        qtyProduct={item.quantity}
        isParentGroup={item.isParentGroup}
      />
    </Grid>
  );
};

const DetailOrderWrapper = ({ item, index }) => {
  const { getContextState } = useCartContext();
  const showPayment = getContextState('paymentResId').visible;
  const { removeItemInCart: removeItem } = useStoreCartItems((state) => ({
    removeItemInCart: state.removeItemInCart,
  }));
  return (
    <Box width="100%">
      <Box
        display="flex"
        flexGrow={1}
        justifyContent="left"
        alignItems="center"
        sx={{ paddingLeft: item.childGroup ? '20px' : '0px' }}>
        <DetailOrderVariant item={item} />
      </Box>
      <Box sx={{ float: 'right' }}>
        {item.promoName !== undefined &&
          item.promoName !== null &&
          item.promoName !== '' &&
          !showPayment && (
            <ButtonBase
              onClick={() => removeItem(item)}
              id={`removeItem-${index}`}>
              <Delete
                sx={{
                  fontSize: 20,
                  color: theme.palette.error.main,
                }}
              />
            </ButtonBase>
          )}
      </Box>
    </Box>
  );
};

const CartItemPromo = ({ item }) => {
  const { getColorApp } = useStoreAppColor((state) => ({
    getColorApp: state.getColorApp,
  }));

  return (
    <Grid container sx={{ display: 'flex' }}>
      {item.promoName !== undefined &&
        item.promoName !== null &&
        item.promoName !== '' && (
          <Box
            fontSize={10}
            sx={{
              padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
              paddingLeft: 1,
              paddingRight: 1,
              color: getColorApp().secondaryFontColor,
              backgroundColor: getColorApp().primaryThemeColor,
              borderRadius: theme.spacing(1),
            }}>
            {item.promoName}
          </Box>
        )}
    </Grid>
  );
};

const CartItemDetails = ({ item }) => {
  const { getContextState } = useCartContext();
  const { getSellBandId } = useGeneralContext();
  const showPayment = getContextState('paymentResId').visible;

  const data = getItem(item, 'name');
  return (
    <Grid
      container
      direction="row"
      sx={{
        paddingLeft: item.childGroup ? '20px' : '0px',
        justifyContent: 'space-between',
        display: 'flex',
      }}>
      <Grid item sx={{ width: '65%' }}>
        <Typography variant="body2" className={'clampOnLine2'}>
          {showPayment && `${item.quantity} x `}
          {data.plu_name}
        </Typography>
      </Grid>
      <Grid item sx={{ width: '35%', textAlign: 'right' }}>
        <Typography variant="subtitle2">
          <b>
            {convertCurrency(
              getPriceBySellBand(item, getSellBandId()) * item.quantity,
            )}
          </b>
        </Typography>
      </Grid>
    </Grid>
  );
};

const CartItemActionGrid = ({ item, index }) => {
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const classes = useStyles(appColor);
  const [quantity, setChangeQuantity] = useState(0);
  const { max = Infinity } = item.choose || {};
  const MAXOFFSET = 1;

  const {
    removeItemInCart: removeItem,
    changeCartItemQuantity: changeItemQty,
  } = useStoreCartItems((state) => ({
    removeItemInCart: state.removeItemInCart,
    changeCartItemQuantity: state.changeCartItemQuantity,
  }));

  useEffect(() => {
    setChangeQuantity();
  }, [changeItemQty]);

  const handleRemoveItem = (item) => {
    removeItem(item);
  };

  return (
    <Grid
      container
      direction="row"
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <Grid item sx={{ paddingLeft: item.childGroup ? '20px' : '0px' }}>
        {/* Subtract 1 from max to correct the maximum value, as the quantity starts from 1. Adding the max value to the default results in an off-by-one error. */}
        {/* This is error from the quantity button, because it default value is 1, so we need to subtract 1 from the max value */}
        <ReButtonQuantity
          onChangeValue={(value) => changeItemQty(item, value)}
          propsValue={item.quantity}
          qtyProduct={item.quantity}
          max={max - MAXOFFSET}
          isDisable={isProductHaveRewards(item)}
        />
      </Grid>
      <Grid item>
        <ButtonBase
          onClick={() => handleRemoveItem(item)}
          id={`removeItem-${index}`}>
          <Delete sx={{ fontSize: 20, color: theme.palette.error.main }} />
        </ButtonBase>
      </Grid>
    </Grid>
  );
};

const CartItem = () => {
  const { getContextState } = useCartContext();
  const showPayment = getContextState('paymentResId').visible;

  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const classes = useStyles(appColor);
  const { cartItems: data } = useStoreCartItems((state) => ({
    cartItems: state.cartItems,
  }));

  return (
    <Grid container direction="column" className={`${classes.borderingGrid}`}>
      {Array.isArray(data) &&
        data.map((item, index) => (
          <Box
            id={`orderItem-${index}`}
            key={index}
            className={`${classes.cartItem}`}
            sx={
              item.promoName !== undefined &&
              item.promoName !== null &&
              item.promoName !== ''
                ? {
                    paddingLeft: theme.spacing(3),
                    paddingTop: theme.spacing(1),
                  }
                : { paddingTop: theme.spacing(4) }
            }>
            <CartItemPromo item={item} />

            <CartItemDetails item={item} />

            <DetailOrderWrapper item={item} index={index} />

            {!(
              item.promoName !== undefined &&
              item.promoName !== null &&
              item.promoName !== ''
            ) &&
              !showPayment && <CartItemActionGrid item={item} index={index} />}
          </Box>
        ))}
    </Grid>
  );
};

export default CartItem;
