import React from 'react';
import { Box } from '@mui/material';

const MapIframe = ({
  src,
  width = '450',
  height = '450',
  allowFullScreen = true,
  loading = 'lazy',
  referrerPolicy = 'no-referrer-when-downgrade',
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '16px',
        overflow: 'hidden',
        boxShadow: 3,
      }}>
      <iframe
        src={src}
        width="100%"
        height={height}
        style={{ border: 0 }}
        allowFullScreen={allowFullScreen}
        loading={loading}
        referrerPolicy={referrerPolicy}></iframe>
    </Box>
  );
};

export default MapIframe;
