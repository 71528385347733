import { useCartContext } from '@context/CartContext';
import { useGeneralContext } from '@context/GeneralContext';
import { useAuthContext } from '@context/AuthContext';
import { useTranslation } from 'react-i18next';

export default function useSearchProductPage() {
    const { openErrorSnackBar, openSuccessSnackBar } = useGeneralContext();
    const { addToCart, setCartModal, setImageModal  } = useCartContext();
    const { getSetting } = useAuthContext();
    const { t } = useTranslation();
    
    const actionSearch = (keywords, products) => {
        if (keywords !== null && keywords !== '') {
            const uniqueIds = [];
            let result = products.filter((product, index) => {
                let keyword = keywords.toLowerCase();
                let findData =
                    // If keyword is empty, include all products
                    (product.plu_name.toLowerCase().includes(keyword) ||
                        (product.plu_number && product.plu_number.startsWith(keyword))) &&
                    (!product.is_variant ||
                        (product.variant_item && product.variant_item.length > 0));

                if (findData) {
                    const isDuplicate = uniqueIds.includes(
                        products[index].uuid_product,
                    );

                    if (!isDuplicate) {
                        uniqueIds.push(products[index].uuid_product);
                        return true;
                    }
                }
                return false;
            });

            if (!(result !== null && result.length > 0)) {
                openErrorSnackBar(t('searchMessage'));
            }
            return result;
        }
    };

    const handleClickItem = (data, type) => {
        if (data.is_active) {
            if (type === 'image') {
                setImageModal(true, data.url);
            } else {
                data.group = [];
                if (
                    getSetting() &&
                    getSetting().isDirectOrder &&
                    !data.is_variant &&
                    data.modifier_group.length < 1 &&
                    data.group.length < 1 &&
                    !data.is_have_group
                ) {
                    data.timestamp = new Date().getTime();
                    data.quantity = 1;
                    addToCart(data);
                    openSuccessSnackBar(
                        `1 x "${data.plu_name}" ${t('hasBeenAddedToCart')}`,
                    );
                } else {
                    setCartModal(true, data);
                }
            }
        }
    };

    return [
        actionSearch,
        handleClickItem
    ]
}
