import React, { useState, useEffect, useRef } from 'react';
import { Typography, Grid, TextField, Box, Button } from '@mui/material';
import ReDialog from '@components/ReDialog';
import RePackageItem from '@components/RePackageItem';
import ReButtonQuantity from '@components/ReButtonQuantity';
import { makeStyles } from '@mui/styles';
import { AddShoppingCart } from '@mui/icons-material';
import { convertCurrency } from '@functions/convertNumber';
import { sumTotalPriceWithPrepItem } from '@functions/sumTotalPriceWithPrepItem';
import { useGeneralContext } from '@context/GeneralContext';
import { useCartContext } from '@context/CartContext';
import { useTranslation } from 'react-i18next';
import theme from '@styles/theme';
import { useAuthContext } from '@context/AuthContext';
import ReLazyImage from './ReLazyImage';
import RePrepItemAccordion from './RePrepItemAccordion';
import ReVariantAccordion from './ReVariantAccordion';
import { getImageFullPage } from '@functions/imageOptimization';
import { useRealmContext } from '@context/RealmContext';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreNutriGrade } from '@state/useStoreNutriGrade';
import { useStoreItemsDetails } from '@state/useStoreItemsDetails';
import { useStoreOrderCategory } from '@state/useStoreOrderCategory';
import useAddToCartModal from '@hooks/useAddToCartModal';
import { useTokenCredentials } from '@state/useTokenCredentials';

const useStyles = makeStyles({
  additionalRequest: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    '& .MuiInputLabel-root': {
      color: (props) => props.primaryFontColor,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      color: '#666666', // Set your desired color here
    },
    '& label.Mui-focused': {
      color: (props) => props.primaryFontColor,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: (props) => props.primaryFontColor,
      },
    },
  },
  priceAndQtyContainer: {
    marginTop: '.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonAddToCart: {
    width: '100%',
    marginTop: '2rem !important',
  },
  buttonImage: {
    borderRadius: theme.spacing(1),
    borderWidth: 'thin',
    borderStyle: 'solid',
    marginBottom: theme.spacing(1),
    margin: 1,
  },
  marginTopBottom: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  marginLeftRight: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  positionNutriGrade: {
    position: 'absolute',
    left: theme.spacing(2),
    bottom: theme.spacing(2),
    width: '15%',
  },
  addToCartModal: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
});

const ReAddToCartModal = ({ item, closeModal, isRewardsPage }) => {
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const classes = useStyles(appColor);

  const { getSellBandId } = useStoreOrderCategory((state) => ({
    getSellBandId: state.getSellBandId,
  }));
  const { openSuccessSnackBar } = useGeneralContext();
  const { addToCart } = useCartContext();
  const { getSetting } = useAuthContext();
  const { getProductGroup } = useRealmContext();
  const { t } = useTranslation();
  const noderef = useRef();
  const { itemsDetails } = useStoreItemsDetails((state) => ({
    itemsDetails: state.itemsDetails,
  }));

  const [localState, setLocalState] = useState({
    qty: 1,
    additional_request: '',
    selectedItem: {},
    isShowImage: false,
    indexVariantSelected: -1,
    buttonDisabled: true,
  });
  const { urlNutriGrade } = useStoreNutriGrade((state) => ({
    urlNutriGrade: state.urlNutriGrade,
  }));

  const [loading, setLoading] = useState(false);
  const [
    mergeModifierWithAdditionalRequest,
    removeSelectedItemFromCart,
    disabledButton,
    handleCheckVariant,
    handleCheckModifier,
    handleCheckPrepItem,
    handleChangePrepQuantity,
  ] = useAddToCartModal();

  const { getTokenCredentials } = useTokenCredentials((state) => ({
    getTokenCredentials: state.getTokenCredentials,
  }));

  const getProdGroup = async (param, data) => {
    setLoading(true); // Start loading
    try {
      const prodGroup = await getProductGroup(param);
      if (prodGroup && !prodGroup.error) {
        updateLocalState({
          selectedItem: { ...data, ...prodGroup.result },
          buttonDisabled: false,
        });
      }
    } catch (error) {
      console.error('Error fetching product group:', error);
      // You can also set error state here if needed
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    // STRINGIFY THEN PARSE, BECAUSE DEEP NESTED OBJECT CANT BE "SPREAD" AS USUAL,
    // SPREAD ONLY WORK ONLY FOR 1st LEVEL OF OBJECT (NOT NESTED OBJECT)
    let reConstructItem = JSON.stringify({ ...item });
    reConstructItem = JSON.parse(reConstructItem);

    if (item.is_have_group) {
      const param = {
        uuid_product: item.uuid_product,
      };
      getProdGroup(param, reConstructItem);
    } else {
      //NOTE: Ambil variant item yang sesuai dengan product item yang dipilih
      // Karena itemsDetails adalah Map manfaatkan uuid_product item yang dipilih
      // console.log(reConstructItem)
      const selectedVariant = itemsDetails.get(reConstructItem.uuid_product);
      updateLocalState({
        selectedItem: { ...reConstructItem, ...selectedVariant },
        buttonDisabled: false,
      });
    }
  }, [getTokenCredentials()]);

  const updateLocalState = (newData) => {
    setLocalState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getLocalState = (key) => {
    return key ? localState[key] : localState;
  };

  const handleChangeQuantity = (value) => {
    updateLocalState({
      qty: value,
    });
  };

  const handleAddToCart = () => {
    const selectedItem = getLocalState('selectedItem');

    selectedItem.quantity = getLocalState('qty');

    ///bikin function untuk mreteli dadi object is_product
    let modifierGroup = [];

    if (selectedItem.is_variant) {
      let variantSelected = selectedItem.variant_item.filter(
        (item) => item.checked,
      );

      modifierGroup = variantSelected[0].modifier_group;
    } else {
      modifierGroup = selectedItem.modifier_group;
    }

    selectedItem.additional_request = mergeModifierWithAdditionalRequest(
      document.getElementById('additional')
        ? document.getElementById('additional').value
        : null,
      modifierGroup,
    );

    delete selectedItem.modifier_group;
    selectedItem.timestamp = new Date().getTime();
    selectedItem.variant_item = getLocalState('selectedItem').variant_item.map(
      (item) => {
        if (item.checked) {
          let newItem = { ...item };
          delete newItem.group; // Delete the group property
          return newItem;
        }
        return item; // Return the original item if not checked
      },
    );
    // Untuk memastikan update state selectedItem terupdate dengan benar
    let localItems = JSON.parse(JSON.stringify(selectedItem));

    addToCart(localItems);

    removeSelectedItemFromCart(selectedItem);
    closeModal();
    openSuccessSnackBar(
      `${getLocalState('qty')} x "${selectedItem.plu_name}" ${t(
        'hasBeenAddedToCart',
      )}`,
    );
  };

  const handleClose = () => {
    console.log('close---------');
    let localItem = { ...getLocalState('selectedItem') };
    removeSelectedItemFromCart(localItem);
    updateLocalState({
      indexVariantSelected: -1,
    });
    closeModal();
  };

  return (
    <ReDialog
      title={getLocalState('selectedItem').plu_name}
      closeModal={handleClose}
      useCloseButton={true}
      content={
        <Box id={'addToCartModal'} className={classes.addToCartModal}>
          {getSetting() &&
            getSetting().isImageDetail &&
            getLocalState('selectedItem').url && (
              <Box display="flex" flexDirection="column">
                {getSetting().isShowImageType !== 'auto' && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    position="relative">
                    <Typography
                      component="button"
                      variant="caption"
                      className={classes.buttonImage}
                      sx={{
                        borderColor: appColor.secondaryThemeColor,
                        outlineColor: appColor.secondaryThemeColor,
                        backgroundColor: appColor.secondaryThemeColor,
                        color: appColor.secondaryFontColor,
                      }}
                      onClick={() => {
                        updateLocalState({
                          isShowImage: !getLocalState('isShowImage'),
                        });
                      }}>
                      {getLocalState('isShowImage')
                        ? `${t('closeImage')}`
                        : `${t('showImage')}`}
                    </Typography>
                  </Box>
                )}
                {(getSetting().isShowImageType === 'auto' ||
                  getLocalState('isShowImage')) && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      id="img"
                      position="relative">
                      <ReLazyImage
                        url={getImageFullPage(getLocalState('selectedItem').url)}
                        index="detail-product"
                      />
                      {urlNutriGrade(item, 'v') && (
                        <Box
                          className={`${classes.positionNutriGrade} nutri-grade`}>
                          <ReLazyImage
                            url={urlNutriGrade(item, 'v')}
                            index="nutri-grade-detail-product"
                          />
                        </Box>
                      )}
                    </Box>
                  )}
              </Box>
            )}

          {getLocalState('selectedItem').description && (
            <Grid item>
              <Typography
                id={'description'}
                variant="body2"
                sx={{ whiteSpace: 'pre-wrap' }}>
                {getLocalState('selectedItem').description}
              </Typography>
            </Grid>
          )}

          {getSetting() &&
            getSetting().isOrderable &&
            getLocalState('selectedItem').use_note && (
              <Grid item sx={{ marginTop: theme.spacing(2) }}>
                <TextField
                  inputProps={{
                    maxLength: 36,
                  }}
                  className={classes.additionalRequest}
                  autoComplete="off"
                  id="additional"
                  label={t('additionalRequest')}
                  placeholder={t('exampleRequest')}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    sx: {
                      color: theme.palette.text.grey, // Default label color when not focused
                    },
                  }}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').sub_product &&
            getLocalState('selectedItem').sub_product.length > 0 && (
              <Grid
                id={'detailPackage'}
                item
                sx={{
                  overflow: 'hidden',
                  borderRadius: 1,
                  width: '100%',
                  marginTop: theme.spacing(2),
                  boxShadow: `0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)`,
                }}>
                <RePackageItem
                  subProduct={getLocalState('selectedItem').sub_product}
                  qtyProduct={getLocalState('qty')}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').variant_item &&
            getLocalState('selectedItem').variant_item.length > 0 && (
              <Grid
                id={'detailVariant'}
                item
                sx={{ width: '100%', marginTop: theme.spacing(2) }}>
                <ReVariantAccordion
                  variantOptionTitle={
                    getLocalState('selectedItem').variant_option_title
                  }
                  variantItem={getLocalState('selectedItem').variant_item}
                  checkItem={(groupIndex, itemIndex) => {
                    const localItem = handleCheckVariant(
                      groupIndex,
                      itemIndex,
                      getLocalState('selectedItem'),
                    );
                    updateLocalState({
                      indexVariantSelected: itemIndex,
                      selectedItem: localItem,
                    });
                  }}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').is_variant &&
            getLocalState('indexVariantSelected') !== -1 &&
            getLocalState('selectedItem').variant_item[
              localState.indexVariantSelected
            ].use_note && (
              <Grid item sx={{ marginTop: theme.spacing(4) }}>
                <TextField
                  inputProps={{ maxLength: 36 }}
                  className={classes.additionalRequest}
                  autoComplete="off"
                  id="additional"
                  label={t('additionalRequest')}
                  placeholder={t('exampleRequest')}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            )}

          {getLocalState('selectedItem').is_variant &&
            getLocalState('indexVariantSelected') !== -1 &&
            getLocalState('selectedItem').variant_item[
              localState.indexVariantSelected
            ]?.modifier_group?.length > 0 && (
              <Grid
                id={'variantDetailModifier'}
                item
                sx={{ width: '100%', marginTop: theme.spacing(2) }}>
                <RePrepItemAccordion
                  qtyProduct={getLocalState('qty')}
                  prepGroup={
                    getLocalState('selectedItem').variant_item[
                      localState.indexVariantSelected
                    ].modifier_group
                  }
                  checkItem={(groupIndex, itemIndex) => {
                    const localItem = handleCheckModifier(
                      groupIndex,
                      itemIndex,
                      getLocalState('selectedItem'),
                    );
                    updateLocalState({ selectedItem: localItem });
                  }}
                  isOrderable={getSetting() && getSetting().isOrderable}
                  isModifier={true}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').is_variant &&
            getLocalState('indexVariantSelected') !== -1 &&
            getLocalState('selectedItem').variant_item[
              localState.indexVariantSelected
            ]?.prep_group?.length > 0 && (
              <Grid
                id={'variantDetailPrepGrup'}
                item
                sx={{
                  width: '100%',
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}>
                <RePrepItemAccordion
                  qtyProduct={getLocalState('qty')}
                  prepGroup={
                    getLocalState('selectedItem').variant_item[
                      localState.indexVariantSelected
                    ].prep_group
                  }
                  checkItem={(groupIndex, itemIndex) => {
                    const localItem = handleCheckPrepItem(
                      groupIndex,
                      itemIndex,
                      getLocalState('selectedItem'),
                    );
                    updateLocalState({ selectedItem: localItem });
                  }}
                  changeQuantity={(groupIndex, itemIndex, prepQty) => {
                    const localItem = handleChangePrepQuantity(
                      groupIndex,
                      itemIndex,
                      prepQty,
                      getLocalState('selectedItem'),
                    );
                    updateLocalState({ selectedItem: localItem });
                  }}
                  isOrderable={getSetting() && getSetting().isOrderable}
                  isPrepFromVariat={true}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').modifier_group &&
            getLocalState('selectedItem').modifier_group.length > 0 && (
              <Grid
                id={'detailModifier'}
                item
                sx={{ width: '100%', marginTop: theme.spacing(2) }}>
                <RePrepItemAccordion
                  qtyProduct={getLocalState('qty')}
                  prepGroup={getLocalState('selectedItem').modifier_group}
                  checkItem={(groupIndex, itemIndex) => {
                    const localItem = handleCheckModifier(
                      groupIndex,
                      itemIndex,
                      getLocalState('selectedItem'),
                    );
                    updateLocalState({ selectedItem: localItem });
                  }}
                  isOrderable={getSetting() && getSetting().isOrderable}
                  isModifier={true}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').group &&
            getLocalState('selectedItem').group.length > 0 && (
              <Grid
                id={'detailPrepGrup'}
                item
                sx={{ width: '100%', marginTop: theme.spacing(2) }}>
                <RePrepItemAccordion
                  qtyProduct={getLocalState('qty')}
                  prepGroup={getLocalState('selectedItem').group}
                  checkItem={(groupIndex, itemIndex) => {
                    const localItem = handleCheckPrepItem(
                      groupIndex,
                      itemIndex,
                      getLocalState('selectedItem'),
                    );
                    updateLocalState({ selectedItem: localItem });
                  }}
                  changeQuantity={(groupIndex, itemIndex, prepQty) => {
                    const localItem = handleChangePrepQuantity(
                      groupIndex,
                      itemIndex,
                      prepQty,
                      getLocalState('selectedItem'),
                    );
                    updateLocalState({ selectedItem: localItem });
                  }}
                  isOrderable={getSetting() && getSetting().isOrderable}
                />
              </Grid>
            )}
          <Grid item>
            <Grid
              container
              direction="row"
              className={classes.priceAndQtyContainer}>
              <Grid ref={noderef} item>
                {getSetting() && getSetting().isOrderable && !isRewardsPage && (
                  <ReButtonQuantity
                    onChangeValue={(value) => handleChangeQuantity(value)}
                  />
                )}
              </Grid>
              <Grid
                item
                className={`${classes.price} ${classes.marginTopBottom}`}>
                <Box id={'formPrice'} display="flex" flexDirection="row">
                  <Typography
                    id={'title'}
                    variant="caption"
                    className={classes.marginLeftRight}>
                    {getSetting() && getSetting().isOrderable
                      ? 'Subtotal : '
                      : `${t('price')} : `}
                  </Typography>
                  {loading ? (
                    <Typography variant="caption">Please wait..</Typography>
                  ) : (
                    <Typography id={'price'} variant="caption">
                      <b>
                        &nbsp;
                        {convertCurrency(
                          sumTotalPriceWithPrepItem(
                            getLocalState('selectedItem'),
                            getLocalState('qty'),
                            getSellBandId(),
                          ),
                        )}
                      </b>
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {getSetting() && getSetting().isOrderable && (
            <Button
              onClick={() => handleAddToCart()}
              className={classes.buttonAddToCart}
              sx={{
                color: appColor.secondaryFontColor,
                backgroundColor: appColor.addButton,
                '&:hover': {
                  backgroundColor: appColor.addButton,
                },
              }}
              variant="contained"
              disabled={disabledButton(
                getLocalState('selectedItem'),
                getLocalState('buttonDisabled'),
              )}>
              {t('add')} <AddShoppingCart />
            </Button>
          )}
        </Box>
      }
    />
  );
};

export default ReAddToCartModal;
