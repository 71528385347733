import * as Realm from 'realm-web';
import URLS from '@/URLS';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreFirstCredential } from '@state/useStoreFirstCredential';
import { useStoreSettings } from '@state/useStoreSettings';
import { useHistory } from 'react-router-dom';
import { sendErrorData } from '@functions/ErrorMessagesSender';

export default function useMiniAppWatchListener() {
  const history = useHistory();
  const { getUserLogin } = useStoreFirstCredential((state) => ({
    getUserLogin: state.getUserLogin,
  }));
  const { getSetting } = useStoreSettings((state) => ({
    getSetting: state.getSetting,
  }));

  const queryCheck = async (collection, id) => {
    const queryResult = await collection.findOne({
      _id: new Realm.BSON.ObjectId(id),
      status: 'miniapp_check',
    });
    return queryResult;
  };

  const failCheckMiniapp = async (miniappId) => {
    const payload = {
      _id: miniappId,
      miniapp_check: getSetting().miniAppCheck,
    };

    try {
      const realmConn = await getUserLogin();
      const response = await realmConn.callFunction('checkMiniapp', payload);
      return response;
    } catch (error) {
      sendErrorData({
        message: `Error Fetch Fail Check Miniapp - ${error}`,
        table: getSetting().tableName,
        file: 'useMiniAppWatchListener.js',
        func: 'failCheckMiniapp()',
        level: 'info',
        payload: payload,
      });
      sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
      history.replace(URLS.CONNECTION_FAILURE);
    }
  };

  const retryMiniappWatch = async (miniappId, retryCounter = 0) => {
    if (retryCounter >= 3) return false;

    const failMiniAppCheck = await failCheckMiniapp(miniappId);
    if (!failMiniAppCheck) return false;

    return miniappWatchListener(
      failMiniAppCheck.miniapp_check,
      failMiniAppCheck.miniapp_check_id,
      retryCounter + 1,
    );
  };

  const miniappWatchListener = async (
    miniAppCheck,
    miniappId,
    retryCounter = 0,
  ) => {
    if (!miniAppCheck) return true;

    const realmConn = await getUserLogin();
    const collectionName =
      getSetting().miniAppCheck === 'table' ? 'tables' : 'store_log';
    const collection = realmConn
      .mongoClient('mongodb-atlas')
      .db('RDO')
      .collection(collectionName);
    const query = await queryCheck(collection, miniappId);
    if (!query) return true;

    return new Promise(async (resolve) => {
      let watchCollection = collection.watch({
        filter: {
          'fullDocument._id': Realm.BSON.ObjectId(miniappId),
        },
      });

      const timeout = setTimeout(() => {
        watchCollection.return();
        // const statusResponse = updateStatusMiniapp(collection, miniappId)
        // statusResponse.then(value => {
        //     console.log(value)
        // })
        resolve(retryMiniappWatch(miniappId, retryCounter));
      }, 20000);

      try {
        for await (const change of watchCollection) {
          if (
            change.operationType === 'update' &&
            change.fullDocument.status === 'miniapp_ok'
          ) {
            clearTimeout(timeout);
            watchCollection.return();
            resolve(true);
            break;
          } else if (
            change.operationType === 'update' &&
            change.fullDocument.status !== 'miniapp_ok'
          ) {
            watchCollection.return();
            resolve(false);

            break;
          }
        }
      } catch (error) {
        console.error('Error in watchCollection:', error);
        watchCollection.return();
        clearTimeout(timeout);
        resolve(retryMiniappWatch(miniappId, retryCounter));
      }

      watchCollection.return();
      clearTimeout(timeout);
      resolve(false);
    });
  };

  return retryMiniappWatch;
}
