import {
  Box,
  Grid,
} from '@mui/material';
import React from 'react';
import BillItem from './BillItem';

export default function BillContent({ props, }) {
  return (
    <>
      {Object.keys(props.billData || []).map((typeSales, index) => (
        <Box id={`typeSales-${typeSales}`} key={index}>
          <Grid item sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            <Box>{props.salesType(typeSales)}</Box>
          </Grid>
          {props.billData[typeSales].transaction_items.map((item, index) => (
            <div key={index}>
              {Array.isArray(item) ? (
                item.map((data) => <BillItem item={data} index={index} />)
              ) : (
                <BillItem item={item} index={index} />
              )}

              {Array.isArray(item) ? (
                <>
                  <hr style={{ borderTop: '1px dashed black' }} />
                </>
              ) : (
                <>
                  {!props.billData[typeSales].transaction_items[index + 1] && (
                    <hr style={{ borderTop: '1px dashed black' }} />
                  )}
                </>
              )}
            </div>
          ))}
        </Box>
      ))}
    </>
  );
};
