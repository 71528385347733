import { Box, ButtonBase, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import theme from '@styles/theme';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '@context/AuthContext';
import { useCartContext } from '@context/CartContext';
import { convertCurrency } from '@functions/convertNumber';
import { getPriceBySellBand } from '@functions/getItemPriceBySellBand';
import LoadingModal from '@components/LoadingModal';
import URLS from '@/URLS';
import ReButtonOrder from '@components/ReButtonOrder';
import { getImageThumnailGrid } from '@functions/imageOptimization';
import { AutoSizer, Grid } from 'react-virtualized';
import { useGeneralContext } from '@context/GeneralContext';
import { v4 as uuidv4 } from 'uuid';
import ReLegend from '@components/ReLegend';
import ReLazyImage from '@components/ReLazyImage';
import 'react-virtualized/styles.css';
import useWindowSize from 'use-window-size-v2';
import { getColorApp } from '@data/localStorageGetter';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreFirstCredential } from '@state/useStoreFirstCredential';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { useStoreNutriGrade } from '@state/useStoreNutriGrade';
import { useStoreSellTime } from '@state/useStoreSellTime';

const useStyles = makeStyles({
  layoutToolbar: {
    alignItems: 'center',
    textAlign: 'center',
    height: theme.spacing(13),
    width: '100%',
    // marginBottom: theme.spacing(2),
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
  },
  iconButton: {
    width: 50,
    marginLeft: theme.spacing(3),
  },
  gridRight: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  gridLeft: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
  panelItem: {
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
    textAlign: 'left',
  },
  disablePanel: {
    backgroundColor: '#F5F5F5',
    opacity: 0.7,
    filter: 'grayscale(1)',
  },
  paddingText: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  positionNutriGrade: {
    position: 'absolute',
    left: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  viewGrid: {
    '& .ReactVirtualized__Grid__innerScrollContainer': {
      overflow: 'auto !important',
      height: 'inherit !important',
      maxHeight: 'inherit !important',
      display: 'flex',
      flexWrap: 'wrap',
      padding: `0 ${theme.spacing(1)} 56px !important`,
      alignContent: 'flex-start',
    },
  },
});

const PanelComponent = ({
  classes,
  t,
  getSellBandId,
  getSetting,
  data,
  onClickItem,
}) => {
  const { urlNutriGrade } = useStoreNutriGrade((state) => ({
    urlNutriGrade: state.urlNutriGrade,
  }));

  const { width } = useWindowSize();
  let fixwidth = width > theme.containerWidth ? theme.containerWidth : width;
  return (
    <Box
      sx={{
        opacity: data.is_active ? 1 : 2,
        width: '100%',
        borderRadius: 10,
      }}>
      <ButtonBase
        disabled={!data.is_active}
        focusRipple
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: 10,
        }}>
        <Box
          id={data.plu_name}
          display="flex"
          flexDirection="column"
          disabled={!data.is_active}
          onClick={() => {
            onClickItem(data);
          }}
          className={`${classes.panelItem} ${
            !data.is_active && classes.disablePanel
          }`}
          sx={{
            background: getColorApp().backgroundProduct,
            boxShadow: 3,
          }}>
          <Box display="flex" justifyContent="center" position="relative">
            <ReLazyImage
              url={getImageThumnailGrid(
                data.url,
                fixwidth / 2 - 12,
                fixwidth / 2 - 12,
              )}
              style={{
                width: fixwidth / 2 - 12,
                height: fixwidth / 2 - 12,
              }}
              index="product"
            />
            {urlNutriGrade(data, 'v') && (
              <Box className={`${classes.positionNutriGrade} nutri-grade`}>
                <ReLazyImage
                  url={urlNutriGrade(data, 'v')}
                  style={{ width: 50 }}
                  index="nutri-grade-product"
                />
              </Box>
            )}
          </Box>
          <Box
            className={classes.paddingText}
            display="flex"
            flexDirection="column"
            width={1}>
            <Typography
              className="clampOnLine2"
              variant="body1"
              sx={{
                height: 50,
                textAlign: 'left',
                lineHeight: 1,
                marginTop: theme.spacing(2),
              }}>
              {data.plu_name}
            </Typography>
            <Box display="flex" flexDirection="row">
              <Box
                component="div"
                textOverflow="ellipsis"
                overflow="hidden"
                fontSize={12}
                whiteSpace="noWrap">
                <b>
                  {data.is_active
                    ? convertCurrency(
                        getPriceBySellBand(data, getSellBandId(), true),
                      )
                    : getSetting() && getSetting().soldOutText
                    ? getSetting().soldOutText
                    : t('outOfStock')}
                </b>
              </Box>
              <Box>
                {data.legend && <ReLegend legend={data.legend} type="grid" />}
              </Box>
            </Box>
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
};

const SuggestionPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { getOutletInfo, getOutletId } = useStoreOutlet();
  const { getSetting } = useStoreSettings();
  const { getAuthState, fetchSuggestion } = useAuthContext();
  const {
    addToCart,
    setCartModal,
    getSuggestionPage,
    setSuggestionPage,
    getBadgeCount,
    isLoggedIn,
  } = useCartContext();
  const { getSellBandId, openSuccessSnackBar } = useGeneralContext();

  let [products, setProducts] = useState({});
  let [isVisible, setIsVisible] = useState(true);

  const { credentials } = useStoreFirstCredential();
  const { sellTimeProducts } = useStoreSellTime((state) => ({
    sellTimeProducts: state.sellTimeProducts,
  }));

  const handleClickItem = (data) => {
    data.group = [];
    if (
      getSetting() &&
      getSetting().isDirectOrder &&
      !data.is_variant &&
      data.modifier_group?.length < 1 &&
      data.group.length < 1 &&
      !data.is_have_group
    ) {
      data.timestamp = new Date().getTime();
      data.quantity = 1;
      addToCart(data);
      openSuccessSnackBar(`1 x "${data.plu_name}" ${t('hasBeenAddedToCart')}`);
    } else {
      setCartModal(true, data);
    }
  };

  const CellRenderer = ({ columnIndex, rowIndex, style }) => {
    const columCount = 2;
    const totalRows = Math.ceil(products.products.length / columCount);
    const dataIndex = products.products[rowIndex * columCount + columnIndex];

    if (!dataIndex) {
      return null;
    }

    const matchingSellTimeProduct = sellTimeProducts.find(
      (prod) => prod.uuid_product === dataIndex.uuid_product,
    );
    // Determine the is_active value. You can provide a default if not found.
    const isActive = matchingSellTimeProduct
      ? matchingSellTimeProduct.is_active
      : dataIndex.is_active;

    const item = { ...dataIndex, is_active: isActive };

    return (
      item && (
        <div
          className="productItem"
          id={`item-${rowIndex * columCount + columnIndex}`}
          style={{
            width: '50%',
            borderRadius: 10,
            paddingTop: rowIndex === 0 ? '15px' : '0px',
            padding: '5px',
            marginBottom: rowIndex === totalRows - 1 ? '15px' : '0px',
            // ...style,
          }}>
          <PanelComponent
            classes={classes}
            t={t}
            getSellBandId={getSellBandId}
            getSetting={getSetting}
            data={item}
            onClickItem={(data) =>
              handleClickItem({ ...data, signature_token: uuidv4() })
            }
          />
        </div>
      )
    );
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/safari/i)) {
      window.history.replaceState(null, null, history.location.pathname);
    }

    window.history.pushState(null, null, history.location.pathname);
    window.addEventListener('popstate', () => {
      history.go(1);
    });

    const suggestion = history.location.state || [];
    const param = {
      uuid_store: getOutletId() || getOutletInfo.outletId,
      uuid_product: suggestion,
      qrcode_token: credentials.token,
      qrcode_uuid: credentials.uuid,
    };
    fetchSuggestion(param);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!getSuggestionPage()) {
      setIsVisible(false);
    }

    if (!isVisible) {
      sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
      history.replace(URLS.MENU_CATALOGUE);
    }
    // eslint-disable-next-line
  }, [getSuggestionPage, isVisible]);

  useEffect(() => {
    if (getAuthState('suggestion')) {
      setProducts(getAuthState('suggestion'));
    }
    // eslint-disable-next-line
  }, [getAuthState('suggestion')]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      overflow="hidden"
      id="SuggestionPage">
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          boxShadow={3}
          bgcolor={getColorApp().primaryThemeColor}
          color={getColorApp().secondaryFontColor}
          className={classes.layoutToolbar}>
          <ButtonBase id={'backButton'} className={classes.iconButton}>
            <ChevronLeft
              style={{ width: 32, height: 32 }}
              onClick={() => {
                setSuggestionPage(false);
              }}
            />
          </ButtonBase>
          <Typography className={classes.title} variant="h6">
            <b>{products?.category_name?.toUpperCase()}</b>
          </Typography>
          <Box className={classes.iconButton} />
        </Box>
      </Box>

      <Box sx={{ borderRadius: 10, height: '100%', marginTop: '10px' }}>
        {products &&
          Array.isArray(products.products) &&
          products.products.length > 0 && (
            <AutoSizer>
              {({ height, width }) => {
                const productLength = products.products
                  ? products.products?.length
                  : 0;
                const rowCount =
                  productLength % 2 === 0
                    ? productLength / 2
                    : Math.round(productLength / 2);
                const columnWidth = Math.floor(width / 2);
                const rowHeight = columnWidth + 74;
                return (
                  <Grid
                    columnCount={2}
                    columnWidth={columnWidth}
                    height={height}
                    width={width}
                    className={classes.viewGrid}
                    rowCount={rowCount}
                    rowHeight={rowHeight}
                    cellRenderer={CellRenderer}
                  />
                );
              }}
            </AutoSizer>
          )}
      </Box>

      {getBadgeCount() > 0 && isLoggedIn() && <ReButtonOrder />}

      {getAuthState('loadingModal') && <LoadingModal />}
    </Box>
  );
};

export default SuggestionPage;
