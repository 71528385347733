import { ThemeProvider } from '@mui/material/styles'; // Add this import
import React from 'react';
import { CssBaseline } from '@mui/material';
import theme from '@styles/theme';

// ... your other imports ...

// Create a custom hook to force update
export const useForceUpdate = () => {
  const [, setValue] = React.useState(0);
  return () => setValue((value) => value + 1);
};

// Create a wrapper component for your app
export const ThemeWrapper = ({ children }) => {
  const forceUpdate = useForceUpdate();

  React.useEffect(() => {
    const handleThemeChange = () => {
      forceUpdate();
    };

    window.addEventListener('themeChange', handleThemeChange);
    return () => window.removeEventListener('themeChange', handleThemeChange);
  }, [forceUpdate]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
