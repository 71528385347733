import React, { useState } from 'react';
import { Slider, Box } from '@mui/material';
import { useStoreFont } from '@state/useStoreFont';
import { styled } from '@mui/material/styles';
import { useStoreAppColor } from '@state/useStoreAppColor';

const ReFontSlider = () => {
  const { fontScale, updateFontScale } = useStoreFont((state) => ({
    fontScale: state.fontScale,
    updateFontScale: state.updateFontScale,
  }));
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));

  const StylizeSlider = styled(Slider)({
    height: 2,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
      height: 28,
      width: 28,
      backgroundColor: '#fff',
      boxShadow: '0 3px 8px rgba(0,0,0,0.15)',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: '0 3px 8px rgba(0,0,0,0.15)',
        '@media (hover: none)': {
          boxShadow: '0 3px 8px rgba(0,0,0,0.15)',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: -32,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '30px',
        height: '24px',
        textAlign: 'center',
        color: appColor.primaryThemeColor,
        fontSize: '14px',
      },
    },
    '& .MuiSlider-track': {
      height: 2,
      backgroundColor: appColor.primaryThemeColor,
    },
    '& .MuiSlider-rail': {
      height: 2,
      opacity: 0.2,
      backgroundColor: appColor.primaryThemeColor,
    },
    '& .MuiSlider-valueLabel': {
      fontSize: 14,
      fontWeight: 'normal',
      top: -6,
      backgroundColor: 'transparent',
      color: appColor.primaryFontColor,
      '&:before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
      },
    },
  });

  return (
    <Box mt={4}>
      <StylizeSlider
        value={fontScale}
        min={0.5}
        max={2}
        step={0.1}
        onChange={(e, newValue) => updateFontScale(newValue)}
        valueLabelDisplay="on"
        aria-labelledby="font-size-slider"
        size="small"
      />
    </Box>
  );
};

export default ReFontSlider;
