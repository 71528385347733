import URLS from '@/URLS';
import { useEffect, useState } from "react";
import { useStoreFirstCredential } from "@state/useStoreFirstCredential";
import {
    sendPostMessageToParent,
} from '@functions/trafficController';
import { useStoreNutriGrade } from "@state/useStoreNutriGrade";
import { useHistory } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow'
import { useStoreOrderType } from '@state/useStoreOrderType';
import { useTokenCredentials } from '@state/useTokenCredentials';
import useHITApi from '@hooks/useHITApi';

const useRealmFetch = () => {
    const history = useHistory();
    const { credentials } = useStoreFirstCredential(state => ({
        credentials: state.credentials,
    }))
    const { updateNutriGrade } = useStoreNutriGrade(useShallow(state => ({ updateNutriGrade: state.updateNutriGrade })))
    const { updateOrderType } = useStoreOrderType(useShallow(state => ({ updateOrderType: state.updateOrderType })))

    const [realmConfig, setRealmConfig] = useState()

    const hitAPI = useHITApi();
    const { updateTokenCredentials } = useTokenCredentials((state) => ({
        updateTokenCredentials: state.updateTokenCredentials,
    }));

    const GET_STORE = import.meta.env[
        `VITE_REACT_APP_GET_STORE_${import.meta.env.MODE}`
    ];

    const GET_TOKEN = import.meta.env[
        `VITE_REACT_APP_GET_TOKEN_${import.meta.env.MODE}`
    ];

    const AUTH_STORE = import.meta.env[
        `VITE_REACT_APP_GET_DATA_AUTH_STORE_${import.meta.env.MODE}`
    ];

    const ORDER_TYPE = import.meta.env[
        `VITE_REACT_APP_GET_ORDER_TYPE_DATA_${import.meta.env.MODE}`
    ];

    const NUTRI_GRADE = import.meta.env[
        `VITE_REACT_APP_GET_PRODUCT_NUTRI_GRADE_${import.meta.env.MODE}`
    ];

    async function fetchGetStore() {
        try {
            const data = {
                "uuid_table": credentials.uuid
            }
            const response = await hitAPI(GET_STORE, 'POST', data);
            fetchTokenCreds(response.result)
        } catch (error) {
            sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
            history.replace(URLS.CONNECTION_FAILURE);
        }
    }

    async function fetchTokenCreds(param) {
        try {
            const response = await hitAPI(GET_TOKEN, 'POST', param);
            updateTokenCredentials(response.result)
            if (!response.error) {
                fetchOrderTypeData()
            }
        } catch (error) {
            sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
            history.replace(URLS.CONNECTION_FAILURE);
        }
    }

    async function fetchRealmConfig() {
        try {
            const data = {
                token: credentials.token,
                uuid: credentials.uuid
            }
            const response = await hitAPI(AUTH_STORE, 'POST', data, true);
            
            if (!response.error) {
                fetchNutriGrade()
                setRealmConfig(response) 
            }
        } catch (error) {
            sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
            history.replace(URLS.CONNECTION_FAILURE);
        }
    }

    async function fetchOrderTypeData() {
        try {
            const data = {
                token: credentials.token,
                uuid: credentials.uuid
            }
            const response = await hitAPI(ORDER_TYPE, 'POST', data, true);
            
            if (!response.error) {
                updateOrderType(response);
                fetchRealmConfig()
            }
        } catch (error) {
            sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
            history.replace(URLS.CONNECTION_FAILURE);
        }
    }

    async function fetchNutriGrade() {
        try {
            const response = await hitAPI(NUTRI_GRADE, 'POST', '', true);
            if (!response.error) updateNutriGrade(response);
        } catch (error) {
            sendPostMessageToParent('path', URLS.CONNECTION_FAILURE);
            history.replace(URLS.CONNECTION_FAILURE);
        }
    }

    useEffect(() => {
        fetchGetStore()
    }, [credentials])

    return realmConfig
}

export default useRealmFetch