import {
  Box,
  ButtonBase,
  Fab,
  Grid,
  Typography,
  Backdrop,
  Modal,
  Fade,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChevronLeft, PhotoCameraRounded } from '@mui/icons-material';
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import theme from '@styles/theme';
import URLS from '@/URLS';
import { getColorApp } from '@data/localStorageGetter';
import ReQrReader from '@components/ReQrReader';
import { useGeneralContext } from '@context/GeneralContext';
import useWindowSize from 'use-window-size-v2';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreCategory, useStoreScannerId } from '@state/useStoreCategory';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreProduct } from '@state/useStoreProduct';
import { useStoreOrderCategory } from '@state/useStoreOrderCategory';
import { useStoreToggleDrawer } from '@state/useStoreToggleDrawer';
import ReCategoryItem from '@components/ReCategoryItem';
import { useAuthContext } from '@context/AuthContext';
import { useStoreOutlet } from '@state/useStoreOutlet';

const useStyles = makeStyles({
  innerTitle: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    height: theme.spacing(13),
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  iconButton: {
    position: 'absolute',
    width: 50,
  },
  typeContainer: {
    padding: `0 ${theme.spacing(3)}px 10vh`,
  },
  content: {
    paddingBottom: `${theme.spacing(10)}px`,
    marginTop: `${theme.spacing(2)}px`,
  },
  fabBackground: {
    position: 'fixed',
    bottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  fabButton: {
    backgroundColor: getColorApp().primaryThemeColor,
    color: getColorApp().secondaryFontColor,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: getColorApp().primaryThemeColor,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: theme.zIndex.drawer + 2,
    outline: 'none',
  },
  modalContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
    outline: 'none',
    overflowY: 'auto',
    maxWidth: 450, // Limit the maximum width
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius, // Add rounded corners
    textAlign: 'center', // Center text content
  },
});

const CategoryPage = (props, ref) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const show = (flag) => {
    setVisible(flag);
  };

  const history = useHistory();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { openErrorSnackBar, showCategoryPage } = useGeneralContext();
  const { setAuthState } = useAuthContext();
  const [showQrScanner, setShowQrScanner] = useState(false);
  const [localState, setLocalState] = useState({
    categoryList: [],
    tempCategoryList: [],
    levelCategory: 0,
  });

  useImperativeHandle(ref, () => ({ show }));

  const { settings } = useStoreSettings((state) => ({
    settings: state.settings,
  }));
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  // const { getProductList } = useStoreProduct(state => ({
  //   getProductList: state.getProductList
  // }))

  const { updateScanner } = useStoreScannerId((state) => ({
    updateScanner: state.updateScanner,
  }));

  const {
    updateCategorySectionId,
    setActiveSection,
    setTabScrolling,
    setShowCategoryPage,
    updateCategory,
  } = useStoreCategory((state) => ({
    updateCategorySectionId: state.updateCategorySectionId,
    setActiveSection: state.setActiveSection,
    setTabScrolling: state.setTabScrolling,
    setShowCategoryPage: state.setShowCategoryPage,
    updateCategory: state.updateCategory,
  }));
  const { removeOrderCategory } = useStoreOrderCategory((state) => ({
    removeOrderCategory: state.removeOrderCategory,
  }));

  const { categoryList } = useStoreToggleDrawer((state) => ({
    categoryList: state.categoryList,
  }));

  const { getSalesType } = useStoreOutlet((state) => ({
    getSalesType: state.getSalesType,
  }));

  const getLocalState = (key) => {
    return key ? localState[key] : localState;
  };

  const updateLocalState = (newData) => {
    setLocalState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const handleClickCategory = (data, index) => {
    let category = null;
    if (
      data.categories &&
      Array.isArray(data.categories) &&
      data.categories.length > 0
    ) {
      console.log('----check');
      // console.log(data.categories)
      updateLocalState({
        categoryList: data.categories,
        levelCategory: index,
      });
      category = {
        data: data.uuid_product_category,
        productIndex: index,
      };

      updateCategorySectionId(category);
    } else {
      category = {
        data: data.uuid_product_category,
        categoryIndex: data.index,
      };
      // if (history.action === "PUSH"){
      //   sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
      //   history.replace(URLS.MENU_CATALOGUE, category);
      // }
      setActiveSection(data.index);
      setTabScrolling(true);
      showCategoryPage(false);
      updateCategory(category);
    }
  };

  const handleBackButton = () => {
    if (getLocalState('levelCategory') > 1) {
      let level = getLocalState('levelCategory');
      level--;
      updateLocalState({
        categoryList: getLocalState('tempCategoryList'),
        levelCategory: level,
      });
    } else {
      removeOrderCategory();
      updateLocalState({
        categoryList: [],
        levelCategory: 0,
      });
      showCategoryPage(false);
      sendPostMessageToParent('path', URLS.ORDER_CATEGORY);
      history.replace(URLS.ORDER_CATEGORY);
    }
  };

  const setListCategoryToState = () => {
    updateLocalState({
      categoryList: categoryList,
      tempCategoryList: categoryList,
    });
  };
  useEffect(() => {
    setListCategoryToState();
  }, [categoryList, visible]);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/safari/i)) {
      window.history.replaceState(null, null, history.location.pathname);
    }

    window.history.pushState(null, null, history.location.pathname);
    window.addEventListener('popstate', () => {
      history.go(1);
    });
    // eslint-disable-next-line
  }, []);

  const onSuccessScan = (result) => {
    //TODO: Change to state
    updateScanner(getProductList()[0].uuid_product_category);
    const scannerID = { scannerID: result };
    sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
    history.replace(URLS.MENU_CATALOGUE, scannerID);
  };

  const onErrorScan = (messages) => {
    openErrorSnackBar(messages);
  };

  return (
    <Modal
      aria-labelledby="service-charge-modal"
      aria-describedby="resolving-service-charge"
      open={ref ? visible : true}
      slots={{ backdrop: Backdrop }}
      className={classes.modal}>
      <div className={classes.modalContent}>
        <Grid className={classes.header}>
          <Box
            boxShadow={3}
            className={classes.innerTitle}
            bgcolor={appColor.primaryThemeColor}
            color={appColor.secondaryFontColor}>
            {getSalesType() &&
            getSalesType().sales &&
            getSalesType().sales.length > 1 ? (
              <ButtonBase id="backButton" className={classes.iconButton}>
                <ChevronLeft
                  sx={{ width: 32, height: 32 }}
                  onClick={handleBackButton}
                />
              </ButtonBase>
            ) : (
              <ButtonBase
                id="backButton"
                className={classes.iconButton}></ButtonBase>
            )}
            <Typography
              className={classes.title}
              sx={{ fontWeight: 'bold' }}
              variant="h6">
              {t('category').toUpperCase()}
            </Typography>
            <ButtonBase
              id="backButton"
              className={classes.iconButton}></ButtonBase>
          </Box>
        </Grid>
        <Grid className={classes.content}>
          <ReCategoryItem
            dataSet={getLocalState().categoryList}
            handleClickItem={(data, index) => handleClickCategory(data, index)}
          />
        </Grid>
        {settings && settings.isShowQrScanner === true && (
          <Box
            className={classes.fabBackground}
            display="flex"
            justifyContent="flex-end"
            sx={
              width > theme.containerWidth
                ? { width: theme.containerWidth }
                : { width: width }
            }>
            <Fab
              className={classes.fabButton}
              onClick={() => {
                setShowQrScanner(true);
              }}>
              <PhotoCameraRounded />
            </Fab>
          </Box>
        )}
        {showQrScanner && (
          <ReQrReader
            onCloseModal={() => {
              setShowQrScanner(false);
            }}
            onSuccessScan={(result) => onSuccessScan(result)}
            onErrorScan={(messages) => onErrorScan(messages)}
          />
        )}
      </div>
    </Modal>
  );
};

export default forwardRef(CategoryPage);
