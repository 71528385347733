import {
    Box,
    Typography,
    Fab,
    Button,
} from '@mui/material';
import React from 'react';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { useTranslation } from 'react-i18next';
import { useStoreAppColor } from '@state/useStoreAppColor';


export default function BillNoTransaction({ onClickRefresh }) {
    const { t } = useTranslation();
    const { appColor } = useStoreAppColor((state) => ({
        appColor: state.appColor,
    }));
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90%', // Full height of the viewport
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '400px',
                    padding: '24px',
                }}>
                {/* Credit card icon */}
                <Fab
                    sx={{
                        marginBottom: '16px',
                        backgroundColor: 'gray.100',
                    }}
                    disabled>
                    <ReceiptLongIcon
                        fontSize="medium"
                        sx={{ color: appColor.primaryThemeColor }}
                    />
                </Fab>

                {/* Title */}
                <Typography
                    variant="h6"
                    sx={{ fontWeight: '600', marginBottom: '8px' }}>
                    {t('noTransaction')}
                </Typography>

                {/* Subtitle */}
                <Typography
                    sx={{
                        color: appColor.primaryFontColor,
                        textAlign: 'center',
                        marginBottom: '24px',
                    }}>
                    {t('noTransactionDescription')}
                </Typography>

                {/* Refresh button */}
                <Button
                    onClick={onClickRefresh}
                    variant="outlined"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: appColor.primaryThemeColor,
                        borderColor: appColor.primaryThemeColor,
                    }}>
                    <RefreshRoundedIcon
                        sx={{
                            color: 'inherit',
                        }}
                    />
                    {t('refresh')}
                </Button>
            </Box>
        </Box>
    );
};
