import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import theme from '@styles/theme';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { useAuthContext } from '@context/AuthContext';
import { useCartContext } from '@context/CartContext';
import ReGridviewPromo from '@components/ReGridviewPromo';
import LoadingModal from '@components/LoadingModal';
import { useTranslation } from 'react-i18next';
import ReAlertDialog from '@components/ReAlertDialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ReButtonOrder from '@components/ReButtonOrder';
import URLS from '@/URLS';
import { getColorApp } from '@data/localStorageGetter';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreFirstCredential } from '@state/useStoreFirstCredential';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { useStoreCartItems } from '@state/useStoreCartItems';
import { useStoreAppColor } from '@state/useStoreAppColor';

const useStyles = makeStyles({
  innerTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
  },
  iconButton: {
    width: 50,
    marginLeft: theme.spacing(3),
  },
  content: {
    overflow: 'auto',
    paddingBottom: `${theme.spacing(11)}px`,
  },
  menus: {
    borderRadius: 10,
    margin: `${theme.spacing(2)}px 0`,
  },
  panelItem: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    overflow: 'hidden',
    textAlign: 'left',
    maxHeight: 90,
  },
  disablePanel: {
    backgroundColor: '#F5F5F5',
  },
  itemImage: {
    display: 'inline-block',
    float: 'left',
    width: 120,
  },
  itemInformationWrapper: {
    display: 'inline-block',
    float: 'left',
    width: `calc(100% - 120px)`,
    height: '100%',
  },
  itemInformationWithImage: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(
      2,
    )}px`,
  },
  itemInformationNoImage: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(
      2,
    )}px`,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  productName: {
    maxHeight: '3rem',
    width: '55%',
    float: 'left',
  },
  price: {
    width: '45%',
    float: 'left',
    textAlign: 'right',
  },
  disabledMenus: {
    opacity: 0.7,
    filter: 'grayscale(1)',
  },
  editButton: {
    position: 'fixed',
    bottom: theme.spacing(16),
    paddingBottom: 0,
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: theme.containerWidth,
  },
});

const RewardsPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { getOutletInfo, getOutletId } = useStoreOutlet((state) => ({
    getOutletId: state.getOutletId,
    getOutletInfo: state.getOutletInfo,
  }));
  const { getAuthState, fetchPromo } = useAuthContext();
  const {
    getContextState,
    setContextState,
    setCheckoutPage,
    removeItemInCart,
    getBadgeCount,
    isLoggedIn,
    setSuggestionPage,
  } = useCartContext();
  const [products, setProducts] = useState('');
  const [isVisible, setIsVisible] = useState(true);
  const [count, setCount] = useState(0);
  const [getStampProduct, setStampProduct] = useState('');
  const [isBackToHome, setBackToHome] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [isSuggestion, setIsSuggestion] = useState(false);
  const [uuidSuggestion, setUuidSuggestion] = useState('');
  const countMax = history.location.state ? history.location.state.count : 0;
  const { credentials } = useStoreFirstCredential((state) => ({
    credentials: state.credentials,
  }));

  const { cartItems } = useStoreCartItems((state) => ({
    cartItems: state.cartItems,
  }));
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));

  const showAlertClosePage = (status) => {
    setContextState({ confirmExitRewards: status });
  };

  const exitRewardsPage = () => {
    setContextState({ isRewardsPage: false });
  };

  const rewardsCount = (items) => {
    let thisCount = 0;
    let thisRewards = items.filter(
      (item) => item.promoFrom === getStampProduct,
    );
    thisCount = thisRewards.length;
    setRewards(thisRewards);
    return countMax - thisCount;
  };

  const productName = (item) => {
    let name = item.plu_name;
    if (Array.isArray(item.variant_item) && item.variant_item.length > 0) {
      let variantSelected = item.variant_item.filter((n) => n.checked);
      if (Array.isArray(variantSelected) && variantSelected.length > 0) {
        name = variantSelected[0].plu_name;
      }
    }
    return name;
  };

  const ItemRewards = ({ index, item, isShowBorder }) => {
    return (
      <Box
        className="itemRewardList"
        display="flex"
        alignItems="center"
        style={{
          borderBottomStyle: isShowBorder ? 'ridge' : 'none',
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }}>
        <Box display="flex" flexGrow={1} sx={{ fontSize: 14 }}>
          {productName(item)}
        </Box>
        <Box
          id={`removeItemCartReward-${index}`}
          display="flex"
          onClick={() => {
            removeItemInCart(item);
          }}>
          <DeleteRoundedIcon />
        </Box>
      </Box>
    );
  };

  const directSuggestionPage = () => {
    setContextState({ isRewardsPage: false });
    setCheckoutPage(false);
    setSuggestionPage(true);
    sendPostMessageToParent('path', URLS.SUGGESTION);
    history.replace(URLS.SUGGESTION, uuidSuggestion);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/safari/i)) {
      window.history.replaceState(null, null, history.location.pathname);
    }

    window.history.pushState(null, null, history.location.pathname);
    window.addEventListener('popstate', () => {
      history.go(1);
    });

    const data = history.location.state || {};
    setStampProduct(data.stamp);
    const param = {
      uuid_store: getOutletId() || getOutletInfo.outletId,
      uuid_product: data.uuid,
      qrcode_token: credentials.token,
      qrcode_uuid: credentials.uuid,
    };
    fetchPromo(param);
    setIsSuggestion(data.suggestion);
    setUuidSuggestion(data.uuid);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (getAuthState('promo')) {
      setProducts(getAuthState('promo'));
    }
    // eslint-disable-next-line
  }, [getAuthState('promo')]);
  useEffect(() => {
    if (!getContextState('isRewardsPage')) {
      setIsVisible(false);
    }

    if (!isVisible) {
      sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
      history.replace(URLS.MENU_CATALOGUE);
    }
    // eslint-disable-next-line
  }, [getContextState('isRewardsPage'), isVisible]);

  const handleClick = () => {
    setBackToHome(true);
    if (count > 0) {
      showAlertClosePage(true);
    } else {
      if (isSuggestion) {
        directSuggestionPage();
      } else {
        exitRewardsPage();
      }
    }
  };

  useEffect(() => {
    setCount(rewardsCount(cartItems));
    // eslint-disable-next-line
  }, [cartItems]);

  return (
    <Box display="flex" flexDirection="column" id="rewardPage">
      <Box className={classes.innerTitle}>
        <Box
          display="flex"
          flexDirection="row"
          boxShadow={3}
          bgcolor={appColor.primaryThemeColor}
          color={appColor.secondaryFontColor}
          style={{
            alignItems: 'center',
            textAlign: 'center',
            height: theme.spacing(13),
            width: '100%',
          }}>
          <ButtonBase id={'backButton'} className={classes.iconButton}>
            <ChevronLeft
              style={{ width: 32, height: 32 }}
              onClick={handleClick}
            />
          </ButtonBase>
          <Typography className={classes.title} variant="h6">
            <b>
              {products
                ? products.promotion_name.toUpperCase()
                : t('promoTitle').toUpperCase()}
            </b>
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.iconButton}
            style={{ paddingLeft: 0, paddingRight: theme.spacing(3) }}>
            <Box fontWeight="fontWeightBold" fontSize={18}>
              {count}
            </Box>
            <Box fontSize={10}>{t('remaining')}</Box>
          </Box>
        </Box>
      </Box>

      <Grid
        cellHeight={'auto'}
        style={{
          margin: '8px 0 0 0',
          paddingBottom:
            rewards.length > 0 ? theme.spacing(30) : theme.spacing(16),
        }}>
          {
            products &&
            <ReGridviewPromo
              dataSet={products}
              count={count}
              stamp={getStampProduct}
            />
          }
      </Grid>

      {Array.isArray(rewards) && rewards.length > 0 && (
        <Box
          className={classes.editButton}
          sx={{ backgroundColor: 'white' }}
          id="rewardList">
          <Accordion style={{ maxWidth: theme.containerWidth }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: theme.palette.primary.contrastText }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{
                backgroundColor: appColor.primaryThemeColor,
                color: theme.palette.primary.contrastText,
                borderRadius: theme.spacing(1),
              }}>
              <Typography className={classes.heading}>
                {t('rewardsList')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '4px 16px' }}>
              <Box display="flex" flexDirection="column" width="100%">
                {rewards.map((item, index) => (
                  <ItemRewards
                    index={index}
                    item={item}
                    isShowBorder={index !== rewards.length - 1}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}

      {getAuthState('loadingModal') && <LoadingModal />}

      {getContextState('confirmExitRewards') && (
        <ReAlertDialog
          open={getContextState('confirmExitRewards')}
          titleDialog={t('promoAlertTitle')}
          messageDialog={t('promoAlertMessage')}
          closeDialog={() => {
            showAlertClosePage(false);
          }}
          confirmDialog={() => {
            showAlertClosePage(false);
            exitRewardsPage();
            !isBackToHome && setCheckoutPage(true);
            if (isSuggestion) {
              directSuggestionPage();
            }
          }}
        />
      )}

      {getBadgeCount() > 0 && isLoggedIn() && <ReButtonOrder />}
    </Box>
  );
};

export default RewardsPage;
