import React from 'react';
import ReDialog from '@components/ReDialog';
import theme from '@styles/theme';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Typography, Button } from '@mui/material';
import { ChevronLeft, LocationOn, AccessTime } from '@mui/icons-material';
import InputRating from '@pages/feedback/InputRating';
import { useAuthContext } from '@context/AuthContext';
import { useHistory } from 'react-router-dom';
import LoadingModal from '@components/LoadingModal';
import { getColorApp } from '@data/localStorageGetter';
import URLS from '@/URLS';
import { sendPostMessageToParent } from '@functions/trafficController';
import useFeedback from '@hooks/useFeedback';
import { styled } from '@mui/system';
import { useStoreAppColor } from '@state/useStoreAppColor';
import MapIframe from './MapIframe';
import { useStoreLogo } from '@state/useStoreLogo';
import { getDefaultLogo } from '@functions/getImageUrlPath';

const ModalOverlay = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1300,
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflowY: 'auto',
}));
const useStyles = makeStyles({
  innerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.spacing(13),
  },
  iconButton: {
    padding: theme.spacing(1),
    width: 65,
    justifyContent: 'flex-start',
  },
  removeButton: {
    display: 'flex',
    flexDirection: 'column',
  },

  formFilledCover: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
});

const ReviewPage = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { getAuthState } = useAuthContext();
  const history = useHistory();
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const { logoUrl } = useStoreLogo((state) => ({
    logoUrl: state.logoUrl,
  }));

  const getLogoImage = () => {
    if (logoUrl) return logoUrl;
    return getDefaultLogo();
  };
  const mapUrl =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.0948660299537!2d110.34791681146206!3d-7.779765577158032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a5814416280b9%3A0x6d88eaa3aab8301a!2sRaptor%20POS%20Yogyakarta!5e0!3m2!1sid!2sid!4v1734591313006!5m2!1sid!2sid';

  const [listDataFeedback, isFilledFeedback, handleRating, handleBtnSend] =
    useFeedback();

  const handleButtonClick = () => {
    const url =
      'https://www.google.com/search?hl=id-ID&gl=id&q=Raptor+POS+Yogyakarta,+Jl.Tambak+Perum+Griya+Indah+VI+No.A4,+Kembang,+Ngestiharjo,+Kasihan,+Bantul+Regency,+Special+Region+of+Yogyakarta+55182&ludocid=7892816335631560730&lsig=AB86z5VHRdeR-nyJb0xDcFFOTrjW&hl=in&gl=ID#lrd=0x2e7a5814416280b9:0x6d88eaa3aab8301a,3';
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <ModalOverlay>
      <ModalContainer>
        <Box
          className={classes.innerTitle}
          sx={{
            backgroundColor: appColor.primaryThemeColor,
            color: appColor.secondaryFontColor,
          }}>
          <ButtonBase
            id="backToHome"
            className={classes.iconButton}
            onClick={() => {
              sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
              history.replace(URLS.MENU_CATALOGUE);
            }}>
            <ChevronLeft style={{ width: 32, height: 32 }} />
          </ButtonBase>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              display: 'flex',
              flexGrow: 1,
              padding: 10,
            }}>
            {t('review_page').toUpperCase()}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          }}>
          {/* Logo */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center', // Center horizontally
              alignItems: 'center', // Center vertically
              flexDirection: 'column',
            }}>
            <img alt="logo" src={getLogoImage()} style={{ width: 120 }} />
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                padding: 2,
              }}>
              Raptor POS Yogyakarta
            </Typography>
          </Box>

          {/* Location */}
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex' }}>
              <LocationOn className="text-gray-500 mt-1" />
              <Box>
                <Typography sx={{ fontWeight: 'bold' }}>Location</Typography>
                <Typography variant="subtitle">
                  Jl.Tambak Perum Griya Indah VI No.A4, Kembang, Ngestiharjo,
                  Kec. Kasihan, Kabupaten Bantul, DIY 55182
                </Typography>
              </Box>
            </Box>

            {/* Operating Hours */}
            <Box sx={{ display: 'flex', marginBottom: 5 }}>
              <AccessTime className="text-gray-500 mt-1" />
              <Box>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Operating Hours
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Typography>
                    <Box
                      component="span"
                      sx={{ color: 'green', fontWeight: 'bold' }}>
                      Open
                    </Box>{' '}
                    - Closes at 17:00
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ padding: 2 }}>
            <MapIframe src={mapUrl} width="inherit" />
            <Button
              variant="contained"
              fullWidth
              sx={{
                marginTop: 5,
                backgroundColor: appColor.primaryThemeColor,
                color: appColor.secondaryFontColor,
              }}
              onClick={handleButtonClick}>
              Reviews
            </Button>
          </Box>
          {getAuthState('loadingModal') && <LoadingModal />}
        </Box>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ReviewPage;
