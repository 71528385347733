
import theme from '@styles/theme';
import { useCartContext } from '@context/CartContext';
import { useGeneralContext } from '@context/GeneralContext';

export default function useHomePage() {
    const { setCartModal } = useCartContext();
    const { openErrorSnackBar } = useGeneralContext();

    const buttonPosisition = (satu, dua) => {
        let tempStyle = {};
        if (satu === true) {
            tempStyle['bottom'] = theme.spacing(16.5);
        } else {
            tempStyle['bottom'] = theme.spacing(2);
        }
        if (dua === true) {
            tempStyle['width'] = theme.containerWidth;
        } else {
            tempStyle['width'] = width;
        }
        return tempStyle;
    };

    const getCategoryIndex = (categories, uuidCategory) => {
        let index = -1;
        for (let i = 0; i < categories.length; i++) {
            if (uuidCategory === categories[i].uuid_product_category) {
                index = i;
                break;
            }
        }
        return index;
    };

    const getSelectedProduct = (productList, scannerID) => {
        const isUUID = scannerID.includes('-');
        let productSelected;
        if (Array.isArray(productList) && productList.length > 0) {
            for (let y = 0; y < productList.length; y++) {
                const productTemp = productList[y];
                if (isUUID) {
                    if (scannerID.includes(productTemp.uuid_product)) {
                        productSelected = productTemp;
                        break;
                    }
                } else {
                    if (scannerID.includes(productTemp.plu_number)) {
                        productSelected = productTemp;
                        break;
                    }
                }
            }
        }
        return productSelected;
    };

    const getProductVariant = (productList) => {
        let productVariantTemp = [];
        if (Array.isArray(productList) && productList.length > 0) {
            for (let y = 0; y < productList.length; y++) {
                const productTemp = productList[y];
                if (
                    productTemp.is_variant === true &&
                    Array.isArray(productTemp.variant_item) &&
                    productTemp.variant_item.length > 0
                ) {
                    for (let z = 0; z < productTemp.variant_item.length; z++) {
                        productVariantTemp.push(productTemp.variant_item[z]);
                    }
                }
            }
        }
        return productVariantTemp;
    };

    const checkProductVariant = (scannerID) => {
        const isUUID = scannerID.includes('-');
        let productScanned;
        let productVariantTemp = [];
        let productVariantArray = [];
        const product = JSON.parse(products);
        for (let i = 0; i < product.length; i++) {
            const categoriesTemp = product[i];
            if (
                Array.isArray(categoriesTemp.categories) &&
                categoriesTemp.categories.length > 0
            ) {
                for (let j = 0; j < categoriesTemp.categories.length; j++) {
                    const categoriesTemp2 = categoriesTemp.categories[j];
                    productVariantTemp = getProductVariant(categoriesTemp2.products);
                    if (
                        Array.isArray(productVariantTemp) &&
                        productVariantTemp.length > 0
                    ) {
                        productVariantArray = [
                            ...productVariantArray,
                            ...productVariantTemp,
                        ];
                    }
                }
            } else {
                productVariantTemp = getProductVariant(categoriesTemp.products);
                if (
                    Array.isArray(productVariantTemp) &&
                    productVariantTemp.length > 0
                ) {
                    productVariantArray = [...productVariantArray, ...productVariantTemp];
                }
            }
        }
        if (Array.isArray(productVariantArray) && productVariantArray.length > 0) {
            for (let z = 0; z < productVariantArray.length; z++) {
                if (isUUID) {
                    if (scannerID.includes(productVariantArray[z].uuid_product)) {
                        productScanned = productVariantArray[z];
                        break;
                    }
                } else {
                    if (scannerID.includes(productVariantArray[z].plu_number)) {
                        productScanned = productVariantArray[z];
                        break;
                    }
                }
            }
        }
        if (productScanned) {
            openProductDetail(productScanned);
        } else {
            openErrorSnackBar(t('searchMessage'));
        }
    };

    const openProductDetail = (data) => {
        setCartModal(true, data);
    };

    const onSuccessScan = (result) => {
        let productScanned;
        const product = JSON.parse(products);
        for (let i = 0; i < product.length; i++) {
            const categoriesTemp = product[i];
            if (
                Array.isArray(categoriesTemp.categories) &&
                categoriesTemp.categories.length > 0
            ) {
                for (let j = 0; j < categoriesTemp.categories.length; j++) {
                    const categoriesTemp2 = categoriesTemp.categories[j];
                    productScanned = getSelectedProduct(categoriesTemp2.products, result);
                    if (productScanned) {
                        break;
                    }
                }
            } else {
                productScanned = getSelectedProduct(categoriesTemp.products, result);
                if (productScanned) {
                    break;
                }
            }
            if (productScanned) {
                break;
            }
        }
        if (productScanned) {
            openProductDetail(productScanned);
        } else {
            checkProductVariant(result);
        }
    };
    return [
        buttonPosisition,
        getCategoryIndex,
        onSuccessScan
    ]
}
