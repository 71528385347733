import React, {
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
  lazy,
  Suspense,
  memo,
  forwardRef,
} from 'react';
// import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { useStoreProduct } from '@state/useStoreProduct';
import { Box, Typography } from '@mui/material';
import { useStoreCategory } from '@state/useStoreCategory';
import useIntersectionObserver from '@hooks/useIntersectingApi';
import useScrollDetection from '@hooks/useScrollDetection';

const Catalogue = lazy(() => import('./Catalogue'));

const Product = forwardRef((props, ref) => {
  const { category, isScrolling, isProgrammaticScrolling } = props;
  const productRef = useRef(null);
  const { setActiveSection } = useStoreCategory((state) => ({
    setActiveSection: state.setActiveSection,
  }));

  useImperativeHandle(ref, () => ({
    getTopPosition: (containerRef) => {
      if (productRef.current && containerRef.current) {
        return (
          productRef.current.getBoundingClientRect().top -
          containerRef.current.getBoundingClientRect().top +
          containerRef.current.scrollTop
        );
      }
      return 0;
    },
    domElement: productRef.current,
  }));
  //Note: Hook Untuk mendeteksi category title yang beririsan dg observer
  const { isIntersecting, boundingClientRect } = useIntersectionObserver(
    productRef,
    {
      threshold: [0, 0.5],
      rootMargin: '100px',
    },
  );

  useEffect(() => {
    if (isScrolling && isIntersecting && !isProgrammaticScrolling) {
      const isTopMostVisible =
        boundingClientRect &&
        boundingClientRect.top >= 0 &&
        boundingClientRect.top < window.innerHeight / 4;

      if (isTopMostVisible) {
        setActiveSection(category.index);
      }
    }
  }, [
    isScrolling,
    isIntersecting,
    boundingClientRect,
    category.index,
    setActiveSection,
    isProgrammaticScrolling,
  ]);

  return (
    <Box
      ref={productRef}
      key={category.uuid_product_category}
      id={category.category_name.split(' ').join('')}
      data-index={category.index}>
      <Box
        className="category-name"
        id={category.index}
        sx={{ padding: '.5rem' }}>
        <Typography
          style={{ fontWeight: 'bold', marginTop: '10px' }}
          variant="h6">
          &#8226; {category.category_name}
        </Typography>
      </Box>
      <Suspense fallback={<p>Loading...</p>}>
        <Catalogue
          category_uuid={category.uuid_product_category}
          index={category.index}
          menu_type={category.menu_type}
        />
      </Suspense>
    </Box>
  );
});

function Category() {
  //NOTE: Ambil data product yang sdh d flating dari state
  const { flatProducts } = useStoreProduct((state) => ({
    flatProducts: state.flatProducts,
  }));

  //Note: Ambil method dan state applikasi dari state category
  const { setTabScrolling, tabScrolling, activeSection } = useStoreCategory(
    (state) => ({
      setTabScrolling: state.setTabScrolling,
      tabScrolling: state.tabScrolling,
      activeSection: state.activeSection,
    }),
  );

  const [isProgrammaticScrolling, setIsProgrammaticScrolling] = useState(false);

  const categoryRef = useRef(null);
  const productRefs = useRef({});

  //NOTE: Hook untuk mendeteksi apakah user scrolling dan mendapatkan posisi scrolling
  //Digunakan untuk scrolling user
  const { isScrolling, lastScrollTop } = useScrollDetection(categoryRef);

  //NOTE: Fungsi untuk melakukan programmatical scrolling, ketika active section berubah
  // Digunakan pada Tab menu, Category page, dan Drawer
  const scrollToSection = () => {
    if (tabScrolling) {
      const targetRef = productRefs.current[activeSection];

      if (targetRef && categoryRef.current) {
        const y = targetRef.getTopPosition(categoryRef);

        setIsProgrammaticScrolling(true);
        categoryRef.current.scrollTo({
          top: y,
        });

        setTimeout(() => {
          setTabScrolling(false);
          setIsProgrammaticScrolling(false);
        }, 1000);
      } else {
        console.log('Either targetRef or categoryRef is not available');
      }
    } else {
      console.log('Tab scrolling is false, not scrolling');
    }
  };

  useEffect(() => {
    if (tabScrolling) {
      scrollToSection();
    }
  }, [tabScrolling, activeSection]);

  return (
    <Box
      sx={{ height: '100vh', overflowY: 'auto' }}
      id="productTarget"
      ref={categoryRef}>
      {flatProducts.map((category) => (
        <div
          key={category.uuid_product_category}
          id={category.uuid_product_category}>
          <Product
            category={category}
            ref={(el) => (productRefs.current[category.index] = el)}
            isScrolling={isScrolling}
            lastScrollTop={lastScrollTop}
            isProgrammaticScrolling={isProgrammaticScrolling}
          />
        </div>
      ))}
    </Box>
  );
}

export default Category;
