import React, { useState } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '@styles/theme';
import { convertCurrency } from '@functions/convertNumber';
import { getPriceBySellBand } from '@functions/getItemPriceBySellBand';
import { useGeneralContext } from '@context/GeneralContext';
import { useTranslation } from 'react-i18next';
import { getColorApp } from '@data/localStorageGetter';
import { useAuthContext } from '@context/AuthContext';
import ReLazyImage from './ReLazyImage';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreNutriGrade } from '@state/useStoreNutriGrade';
import { styled } from '@mui/material/styles';
import { useStoreSellTime } from '@state/useStoreSellTime';

const useStyles = makeStyles(() => ({
  accordionIdle: {
    color: getColorApp().primaryFontColor,
  },
  accordionActive: {
    color: getColorApp().primaryFontColor,
  },
  accordionSuccess: {
    color: getColorApp().secondaryFontColor,
  },
  expandIcon: {
    color: 'inherit',
  },
  headingTitle: {
    height: 48,
    minHeight: `48px !important`,
  },
  headingContent: {
    width: '80%',
  },
  textWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  accordionDetail: {
    backgroundColor: getColorApp().backgroundAccordion,
    color: getColorApp().primaryFontColor,
    padding: '8px 8px 8px 12px !important',
  },
  itemBody: {
    margin: `${theme.spacing(1)}px 0`,
  },
  nominal: {
    maxWidth: 80,
    width: 80,
    marginLeft: theme.spacing(2),
  },
  formControl: {
    paddingBottom: theme.spacing(2),
    width: 'inherit',
  },
  formLabel: {
    marginRight: 0,
  },
}));

const OptionalItem = ({ variantItem, checkItem, t }) => {
  const [value, setValue] = useState('');
  const { getSellBandId } = useGeneralContext();
  const { getSetting } = useAuthContext();
  const classes = useStyles();

  const { sellTimeProducts } = useStoreSellTime((state) => ({
    sellTimeProducts: state.sellTimeProducts,
  }));

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    checkItem(newValue);
  };
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const { urlNutriGrade } = useStoreNutriGrade((state) => ({
    urlNutriGrade: state.urlNutriGrade,
  }));

  const CustomRadio = styled(Radio)(() => ({
    color: theme.palette.text.grey,
    padding: '0 5px !important',
    '&.Mui-checked': {
      color: appColor.successAccordion,
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  }));

  // const CustomRadio = withStyles({
  //   root: {
  //     color: appColor.successAccordion,
  //     padding: '0 5px !important',
  //     '&$checked': {
  //       color: appColor.successAccordion,
  //     },
  //     '&:hover': {
  //       backgroundColor: 'transparent !important',
  //     },
  //   },
  //   checked: {},
  // })((props) => <Radio color="default" {...props} />);

  return (
    <Box display="flex" width={1}>
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={handleChange}>
          {Array.isArray(variantItem) &&
            variantItem.map((item, index) => {
              // Try to find a matching product in sellTimeProducts.
              const matchingSellTimeProduct = sellTimeProducts.find((prod) => {
                if (prod.is_variant && Array.isArray(prod.variant_item)) {
                  // For variant products, check if any variant's uuid matches.
                  // Get the matching sellTimeProduct from the array.
                  return prod.variant_item.find(
                    (variant) => variant.uuid_product === item.uuid_product,
                  );
                }
                // For non-variant products, match by product uuid.
                return prod.uuid_product === item.uuid_product;
              });

              // Determine if the radio should be disabled.
              // If a matching product is found and its is_active is false, disable the radio.
              const disableRadio = matchingSellTimeProduct
                ? !matchingSellTimeProduct.is_active
                : !item.is_active;

              return (
                <Box
                  key={item.uuid_product}
                  id={`radio-${index}`}
                  sx={{ margin: '4px 0' }}
                  display="flex"
                  width={1}
                  flexDirection="row"
                  alignItems="center"
                  className={classes.itemBody}>
                  <Box display="flex" id="box2" alignItems="center">
                    <FormControlLabel
                      className={classes.formLabel}
                      sx={{ marginRight: 0 }}
                      id={'input'}
                      value={index.toString()}
                      control={<CustomRadio disabled={disableRadio} />}
                    />
                  </Box>
                  <Box
                    id={'title'}
                    display="flex"
                    flexGrow={1}
                    whiteSpace="normal"
                    alignSelf="center"
                    sx={{ marginRight: theme.spacing(1), width: '40%' }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: disableRadio
                          ? theme.palette.text.grey
                          : 'inherit',
                      }}>
                      {item.plu_name}
                    </Typography>
                  </Box>
                  {urlNutriGrade(item, 'm') && (
                    <Box className={`nutri-grade`} sx={{ width: '10%' }}>
                      <ReLazyImage
                        url={urlNutriGrade(item, 'm')}
                        style={{ width: 40, verticalAlign: 'middle' }}
                        index="nutri-grade-product"
                      />
                    </Box>
                  )}
                  {(getPriceBySellBand(item, getSellBandId()) !== 0 ||
                    !item.is_active) && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      style={{ width: urlNutriGrade(item, 'm') && '25%' }}
                      className={classes.nominal}>
                      <Typography
                        id={'nominal'}
                        variant="caption"
                        className={classes.textWrapper}>
                        <b>
                          {item.is_active
                            ? convertCurrency(
                                getPriceBySellBand(item, getSellBandId()),
                              )
                            : getSetting() && getSetting().soldOutText
                            ? getSetting().soldOutText
                            : t('outOfStock')}
                        </b>
                      </Typography>
                    </Box>
                  )}
                </Box>
              );
            })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

const ReVariantAccordion = ({ variantOptionTitle, variantItem, checkItem }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expandedPanel, setExpandedPanel] = useState(0);

  const handleExpand = (event, index) => {
    setExpandedPanel(index === expandedPanel ? null : index);
  };

  const accordionStyle = (index, prepItem = [], min) => {
    let checkedItem = prepItem.filter((x) => x.checked);
    let qtySelected = 0;
    for (let j = 0; j < checkedItem.length; j++) {
      qtySelected += checkedItem[j].quantity ? checkedItem[j].quantity : 1;
    }
    if (index === expandedPanel) {
      if (qtySelected >= min) {
        return {
          backgroundColor: getColorApp().successAccordion,
          color: getColorApp().secondaryFontColor,
        };
      } else {
        return {
          backgroundColor: getColorApp().headerAccordion,
          color: getColorApp().primaryFontColor,
        };
      }
    } else if (index !== expandedPanel && qtySelected >= min) {
      return {
        backgroundColor: getColorApp().successAccordion,
        color: getColorApp().secondaryFontColor,
      };
    }
    return {
      backgroundColor: getColorApp().headerAccordion,
      color: getColorApp().primaryFontColor,
    };
  };

  return (
    <Grid>
      <Accordion
        key={'test id'}
        sx={{ ...accordionStyle(0, variantItem, 1) }}
        expanded={expandedPanel === 0}
        onChange={(event) => {
          handleExpand(event, 0);
        }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            expandIcon: classes.expandIcon,
            root: classes.headingTitle,
            content: classes.headingContent,
          }}>
          <Typography
            id={'titleAccordion'}
            variant="subtitle2"
            className={classes.textWrapper}>
            {variantOptionTitle ? variantOptionTitle : t('variantName')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className={classes.accordionDetail}
          sx={{ padding: 2 }}>
          <Grid container direction="column">
            <OptionalItem
              variantItem={variantItem}
              checkItem={(itemIndex) => checkItem(0, itemIndex)}
              t={t}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default ReVariantAccordion;
