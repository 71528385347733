import {
    Box,
    Grid,
} from '@mui/material';
import { convertCurrency } from '@functions/convertNumber';
import React from 'react';
import { useStoreOutlet } from '@state/useStoreOutlet';
import PackageItem from './PackageItem';
import theme from '@styles/theme';

export default function BillItem({ item, index }) {
    const { outlet: outletInfo } = useStoreOutlet();

    return (
        <>
            <Grid
                item
                key={index}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                }}
                className="list-order"
                id={`list-${index}`}>
                <Box sx={{ width: '60%' }}>
                    {`${item.quantity} x ${item.plu_name}`}
                </Box>
                <Box sx={{ width: '8%', margin: '0 8px' }}>
                    {outletInfo.currencySymbol}
                </Box>
                <Box sx={{ width: '32%', textAlign: 'right' }}>
                    {item.amount === 0
                        ? '0'
                        : convertCurrency(item.quantity * item.amount, false)}
                </Box>
            </Grid>

            {/* PREP ITEM */}
            {Array.isArray(item.prep_items) &&
                item.prep_items.map((prep) => (
                    <Grid
                        item
                        key={prep.plu_number}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                        }}>
                        <Box sx={{ width: '60%' }}>
                            <Box sx={{ marginLeft: theme.spacing(3) }}>
                                {`+ ${prep.quantity} x ${prep.plu_name}`}
                            </Box>
                        </Box>
                        <Box sx={{ width: '8%', margin: '0 8px' }}>
                            {(prep.amount || 0) * (prep.quantity || 0) !== 0
                                ? outletInfo.currencySymbol
                                : ''}
                        </Box>
                        <Box sx={{ width: '32%', textAlign: 'right' }}>
                            {`${(prep.amount || 0) * (prep.quantity || 0) !== 0
                                ? convertCurrency(prep.amount * prep.quantity, false)
                                : ''
                                }`}
                        </Box>
                    </Grid>
                ))}

            {/* PACKAGE */}
            {item.type === 'package' && <PackageItem item={item} />}

            {/* MODIFIER */}
            {Array.isArray(item.note) &&
                item.note.map((note, index) => (
                    <Grid
                        item
                        key={index}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                        }}>
                        <Box sx={{ width: '60%' }}>
                            <Box sx={{ marginLeft: theme.spacing(3) }}>{`* ${note}`}</Box>
                        </Box>
                        <Box sx={{ display: 'flex', flex: 0.3 }} />
                    </Grid>
                ))}

            {/* DISCOUNT */}
            {Array.isArray(item.discounts) &&
                item.discounts.map((discount) => (
                    <Grid
                        item
                        key={item.plu_number}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                        }}>
                        <Box sx={{ width: '60%' }}>
                            <Box sx={{ marginLeft: theme.spacing(3) }}>
                                {`- ${discount.discount_name}`}
                            </Box>
                        </Box>
                        <Box sx={{ width: '8%', margin: '0 8px' }}>
                            {outletInfo.currencySymbol}
                        </Box>
                        <Box sx={{ width: '32%', textAlign: 'right' }}>
                            &#40;
                            {convertCurrency(discount.amount, false) === '0'
                                ? '0'
                                : convertCurrency(discount.amount, false)}
                            &#41;
                        </Box>
                    </Grid>
                ))}
        </>
    );
};
