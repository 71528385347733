import { useTokenCredentials } from '@state/useTokenCredentials';

const useHITApi = () => {
    const { getTokenCredentials, updateTokenCredentials } = useTokenCredentials((state) => ({
        getTokenCredentials: state.getTokenCredentials,
        updateTokenCredentials: state.updateTokenCredentials,
    }));

    const REFRESH_TOKEN = import.meta.env[
        `VITE_REACT_APP_REFRESH_TOKEN_${import.meta.env.MODE}`
    ];
    const hitAPI = async (url, method, data, token) => {
        const newUrl = url.replace(/\s/g, '');
        let feedback;
        try {
            const header = {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            };

            if (token && getTokenCredentials().access_token) {
                header['Authorization'] = getTokenCredentials().access_token;
            }

            feedback = await fetch(newUrl, {
                method: method || 'GET',
                headers: header,
                body: data ? JSON.stringify(data) : '',
            });

            let response = await feedback.json();
            if (feedback.status === 401) {
                const data = {
                    "refresh_token": getTokenCredentials().refresh_token
                }
                const refresh = await hitAPI(REFRESH_TOKEN, 'POST', data);
                updateTokenCredentials(refresh.result)
                return response;
            } else {
                return response;
            }
        } catch (error) {
            console.log(error);
        }
    };

    return hitAPI;
};

export default useHITApi;
