import React from 'react';
import { ChevronLeft, Refresh, HistoryRounded } from '@mui/icons-material';
import { makeStyles, useTheme } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Box,
  ButtonBase,
  Typography,
  Fab,
} from '@mui/material';
import Alert from '@mui/lab/Alert';
import { useTranslation } from 'react-i18next';
import URLS from '@/URLS';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { styled } from '@mui/system';
import BillNoTransaction from './BillNoTransaction';
import BillData from './BillData';


const ModalOverlay = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1300,
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflowY: 'hidden',
  fontSize: 14,
  lineHeight: 1.5,
}));

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute !important',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }, 
}));

// EXPECTED PROPS 30/09/2022
// onBack*
// title*
// billData*
// table*
// outlet*
// salesType*
// disableRefresh*
// onClickRefresh*
// onConfirm*
// loading*
// billDiscount
// billSubTotal
// billGrandTotal
// billTaxes
// warningMessage
// showHistory

const ReBillLayout = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));

  const BillInformation = () =>
    props.showViewBill ? (
      <Grid
        container
        key={props.outlet}
        sx={{
          display: 'block',
          position: 'relative',
          padding: '1rem 8px 0 8px',
          overflowY: 'auto',
          height: '92vh'
        }}>
        {props.loadingUpdate && (
          <div style={{ textAlign: 'center' }}>Loading...</div>
        )}
        {props.warningMessage && (
          <div
            style={{
              width: '100%',
              '& > * + *': { marginTop: theme.spacing(2) },
            }}>
            <Alert severity="warning">{t('transaction_split_info')}</Alert>
          </div>
        )}
        {props.billData && Object.keys(props.billData).length !== 0 ? (
          <BillData props={props} />
        ) : (
          <BillNoTransaction onClickRefresh={props.onClickRefresh} />
        )}
      </Grid>
    ) : null;


  return (
    <ModalOverlay>
      <ModalContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            padding: `10px ${theme.spacing(4)}`,
            height: theme.spacing(13),
          }}>
          <ButtonBase
            onClick={() => {
              if (props.onBack) {
                sendPostMessageToParent('path', props.onBack);
                history.replace(props.onBack);
              } else {
                sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
                history.replace(URLS.MENU_CATALOGUE);
              }
            }}>
            <ChevronLeft style={{ width: 32, height: 32 }} />
          </ButtonBase>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {props.title}
          </Typography>
          {/* Note: Jika  */}
          <ButtonBase
            sx={{
              color: props.disableRefresh
                ? theme.palette.text.disabled
                : 'inherit',
            }}
            onClick={() => {
              props.onClickRefresh();
            }}
            disabled={props.disableRefresh}>
            <Refresh sx={{ width: 32, height: 32 }} />
          </ButtonBase>
        </Box>
        {props.showHistory ? (
          <Box>
            <Fab
              id="gotoHistoryBill"
              sx={{
                backgroundColor: appColor.secondaryThemeColor,
                color: appColor.secondaryFontColor,
              }}
              className={classes.fab}
              onClick={() => {
                sendPostMessageToParent('path', URLS.HISTORY_BILL);
                history.replace(URLS.HISTORY_BILL);
              }}>
              <HistoryRounded />
            </Fab>
          </Box>
        ) : (
          <>
            {props &&
              <BillInformation />
            }
          </>
        )}
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ReBillLayout;
