import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Fab, ButtonBase, useMediaQuery } from '@mui/material';
import ReTabList from '@components/ReTabList';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import { Menu, PhotoCameraRounded, SearchRounded } from '@mui/icons-material';
import { useAuthContext } from '@context/AuthContext';
import { useCartContext } from '@context/CartContext';
import { useHistory } from 'react-router-dom';
import { useGeneralContext } from '@context/GeneralContext';
import ItemProductTab from './ItemProductTab';
import ReButtonOrder from '@components/ReButtonOrder';
import URLS from '@/URLS';
import { getColorApp } from '@data/localStorageGetter';
import ReQrReader from '@components/ReQrReader';
import useWindowSize from 'use-window-size-v2';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreProduct } from '@state/useStoreProduct';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { useStoreCategory } from '@state/useStoreCategory';
import { useStoreCartItems } from '@state/useStoreCartItems';
import { useStoreFirstCredential } from '@state/useStoreFirstCredential';
import ToggleDrawer from './ToggleDrawer';
import useCheckProducts from '@hooks/useCheckProducts';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreSpreadProduct } from '@state/useStoreSpreadProduct';
import ReScrollableList from '@components/ReScrollableList';
import Category from './Category';
import { useStoreOrderCategory } from '@state/useStoreOrderCategory';
import useCheckOpenCloseStore from '@hooks/useCheckOpenCloseStore';
import useHomePage from '@hooks/useHomePage';

const useStyles = makeStyles({
  drawerButton: {
    backgroundColor: getColorApp().primaryThemeColor,
    paddingLeft: `${theme.spacing(2)} !important`,
    cursor: 'pointer',
  },
  headerTitle: {
    paddingLeft: theme.spacing(2),
  },
  textWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  buttonSearch: {
    padding: theme.spacing(2),
  },
  headerColor: {
    backgroundColor: getColorApp().primaryThemeColor,
    color: getColorApp().secondaryFontColor,
  },
  content: {
    height: '100%',
    overflow: 'auto',
  },
  fabBackground: {
    position: 'fixed',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  fabButton: {
    backgroundColor: getColorApp().primaryThemeColor,
    color: getColorApp().secondaryFontColor,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: getColorApp().primaryThemeColor,
    },
  },
});

const HomePage = () => {
  const bigSizeScreen = useMediaQuery('(min-width:600px)');
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const { width } = useWindowSize();
  const { openDrawer, openErrorSnackBar, showCategoryPage } =
    useGeneralContext();
  const { forceReset, setCheckoutPage } = useCartContext();

  const { spreadCategories } = useStoreSpreadProduct((state) => ({
    spreadCategories: state.spreadCategories,
  }));

  const { onClickTabCategory, setAuthState } = useAuthContext();

  const history = useHistory();
  const [categories, setCategories] = useState([]);

  const [showQrScanner, setShowQrScanner] = useState(false);
  const [isOrder, setIsOrder] = useState(false);

  useCheckProducts();

  const { products, getProductList } = useStoreProduct((state) => {
    return {
      products: state.products,
      getProductList: state.getProductList,
    };
  });

  const { outlet, getTableName } = useStoreOutlet((state) => ({
    outlet: state.outlet,
    getTableName: state.getTableName,
  }));

  const { getSetting, getLanguages } = useStoreSettings((state) => ({
    getSetting: state.getSetting,
    getLanguages: state.getLanguages,
  }));
  const { category } = useStoreCategory((state) => ({
    category: state.category,
  }));

  const { getBadgeCount } = useStoreCartItems((state) => ({
    getBadgeCount: state.getBadgeCount,
  }));

  const { isLoggedIn } = useStoreFirstCredential((state) => ({
    isLoggedIn: state.isLoggedIn,
  }));

  const { getCurrentSalesType } = useStoreOrderCategory((state) => ({
    getCurrentSalesType: state.getCurrentSalesType,
  }));

  const [, checkOpenCLose] = useCheckOpenCloseStore();

  const [buttonPosisition,
    getCategoryIndex,
    onSuccessScan] = useHomePage();

  //Note: Untuk merubah language default,
  //Tanpa ini language tidak berubah ketika di render oleh web
  useEffect(() => {
    if (getLanguages() && Object.keys(getLanguages()).length !== 0) {
      i18n.changeLanguage(getLanguages()[0].toLowerCase());
    }

    if (!products) {
      history.replace(URLS.CONNECTION_FAILURE, {
        message: 'Failed to read stored data!',
      });
    }
  }, []);

  const onErrorScan = (messages) => {
    openErrorSnackBar(messages);
  };

  useEffect(() => {
    getBadgeCount() > 0 && isLoggedIn() ? setIsOrder(true) : setIsOrder(false);
  }, [getBadgeCount, isLoggedIn]);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/safari/i)) {
      window.history.replaceState(null, null, history.location.pathname);
    }

    window.history.pushState(null, null, history.location.pathname);
    window.addEventListener('popstate', () => {
      history.go(1);
    });

    //Note: Untuk menampilkan category page, jika konfigurasinya dinyalakan
    if (Object.keys(category).length === 0) {
      if (getSetting() && getSetting().isDisplayCategory) {
        showCategoryPage(true);
      } else {
        showCategoryPage(false);
      }
    } else {
      showCategoryPage(false);
    }

    if (getSetting() && !getSetting().isInfinityScroll) {
      if (Array.isArray(spreadCategories) && spreadCategories.length > 0) {
        setCategories(spreadCategories);
        if (history.location.state) {
          if (history.location.state.scannerID) {
            onSuccessScan(history.location.state.scannerID);
            onClickTabCategory(spreadCategories[0], 0);
          }
          if (history.location.state.data) {
            const index = getCategoryIndex(
              spreadCategories,
              history.location.state.data,
            );
            onClickTabCategory(spreadCategories[index], index);
          }
        } else {
          onClickTabCategory(spreadCategories[0], 0);
        }
      }
    }

    if (history.location.state) {
      if (history.location.state.checkoutpage) {
        setCheckoutPage(false);
      }
    }
    if (getCurrentSalesType()) {
      const store = checkOpenCLose(getCurrentSalesType().currentOpen);

      if (!store) {
        history.replace(URLS.OUTLET_CLOSE);
      }
    }

    return () => {
      // CLEAR SEMUA STATE KETIKA PERTAMA KALI BUKA HOME PAGE
      if (
        ![
          URLS.FEEDBACK,
          URLS.SEARCH_PRODUCT,
          URLS.VIEW_BILL,
          URLS.VIEW_TRANSACTION,
          URLS.SUGGESTION,
          URLS.REWARDS,
          URLS.PAYMENT_BILL,
        ].includes(history.location.pathname)
      ) {
        forceReset();
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ToggleDrawer
        isBigScreen={bigSizeScreen}
        tableName={getTableName()}
        setSendEmailVisible={() => setAuthState({ sendEmailVisible: true })}
      />
      <Box display="flex" flexDirection="column" height="100vh">
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            sx={{
              minHeight: theme.spacing(13),
              backgroundColor: appColor.primaryThemeColor,
            }}>
            <ButtonBase
              id={'drawer'}
              display="flex"
              onClick={openDrawer}
              className={`${classes.drawerButton} ${classes.headerColor}`}>
              <Menu
                sx={{ color: getColorApp().secondaryFontColor }}
                fontSize={'large'}
              />
            </ButtonBase>
            <Box
              display="flex"
              flexGrow={1}
              alignItems="center"
              sx={{
                backgroundColor: appColor.primaryThemeColor,
              }}
              className={`${classes.headerTitle} ${classes.headerColor}`}>
              <Typography
                id={'home-title'}
                variant="h6"
                className={classes.textWrapper}
                sx={{ fontWeight: 'bold' }}>
                {outlet.outletName}
              </Typography>
            </Box>
            {getSetting() && getSetting().isDisplaySearch && (
              <Box
                id="buttonSearch"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  backgroundColor: appColor.primaryThemeColor,
                }}
                className={`${classes.buttonSearch} ${classes.headerColor}`}
                onClick={() => {
                  sendPostMessageToParent('path', URLS.SEARCH_PRODUCT);
                  history.push(URLS.SEARCH_PRODUCT);
                }}>
                <SearchRounded
                  sx={{ color: getColorApp().secondaryFontColor }}
                  fontSize="large"
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          {getSetting() && getSetting().isInfinityScroll ? (
            <ReScrollableList
              dataSet={getProductList() || []}
              categoryUuId={
                history.location.state && history.location.state.data
              }
            />
          ) : (
            <ReTabList
              dataSet={categories || []}
              handleClickList={(category, index) =>
                onClickTabCategory(category, index)
              }
            />
          )}
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          sx={{ paddingBottom: ((getBadgeCount() && getBadgeCount() > 0 && isLoggedIn()) || getSetting().isDisplayCategory) && theme.spacing(15) }}
          className={classes.content}>
          {/* <Category /> */}

          {getSetting() && getSetting().isInfinityScroll ? (
            <Category />
          ) : (
            <ItemProductTab />
          )}
        </Box>

        {getSetting() && getSetting().isShowQrScanner === true && (
          <Box
            className={classes.fabBackground}
            display="flex"
            justifyContent="flex-end"
            sx={buttonPosisition(isOrder, width > theme.containerWidth)}>
            <Fab
              className={classes.fabButton}
              onClick={() => {
                setShowQrScanner(true);
              }}>
              <PhotoCameraRounded />
            </Fab>
          </Box>
        )}
        {showQrScanner && (
          <ReQrReader
            onCloseModal={() => {
              setShowQrScanner(false);
            }}
            onSuccessScan={(result) => onSuccessScan(result)}
            onErrorScan={(messages) => onErrorScan(messages)}
          />
        )}
        {(getBadgeCount() && getBadgeCount() > 0 && isLoggedIn()) ||
          getSetting().isDisplayCategory ? (
          <ReButtonOrder />
        ) : (
          <Box display="flex" sx={{ paddingTop: theme.spacing(2) }} />
        )}
      </Box>
    </>
  );
};

export default HomePage;
