import { create } from 'zustand';
import { persist } from 'zustand/middleware';
// import { detectItemPrice } from "@functions/convertNumber";
// import { getItemsDetailStorage } from "@data/localStorageGetter"

const serializeMap = (map) => {
  return JSON.stringify(Array.from(map.entries()));
};

const deserializeMap = (str) => {
  return new Map(JSON.parse(str));
};
export const useStoreItemsDetails = create(
  persist(
    (set, get) => ({
      itemsDetails: new Map(),
      setItemsDetail: (products) => {
        set((state) => {
          //NOTE:Buat object Map baru dari itemsDetails
          const newItems = new Map(state.itemsDetails);
          //NOTE:Membangung data products untuk variant items
          // NOTE: melakukan flatting, untuk menghilangkan nested items
          // NOTE:menghilangkan attribute yang tidak digunakan
          //NOTE: Rebuild Item dengan attribute yang digunakan saja
          const itemsDetail = products.flatMap((category) => {
            //NOTE: Pengecekan pakah category memiliki categories didalamanya
            if (
              Array.isArray(category.categories) &&
              category.categories.length > 0
            ) {
              //NOTE: Jika categorories memiliki categories lagi, ambil variant item dari dalam categories-nya
              return category.categories.map((subCategory) => ({
                products: subCategory.products.map((subCategoryProduct) => {
                  return {
                    uuid_product: subCategoryProduct.uuid_product,
                    is_variant: subCategoryProduct.is_variant,
                    is_active: subCategoryProduct.is_active,
                    is_have_group: subCategoryProduct.is_have_group,
                    is_have_promo: subCategoryProduct.is_have_promo,
                    is_have_suggestion: subCategoryProduct.is_have_suggestion,
                    is_package: subCategoryProduct.is_package,
                    description: subCategoryProduct.description,
                    sell_end_at: subCategoryProduct.sell_end_at,
                    sell_bands: subCategoryProduct.sell_bands,
                    variant_option_title:
                      subCategoryProduct.variant_option_title,
                    variantItem: subCategoryProduct.is_variant
                      ? subCategoryProduct.variant_item.map((varItem) => {
                          return {
                            description: varItem.description,
                            uuid_product: varItem.uuid_product,
                            is_active: varItem.is_active,
                            legend: varItem.legend,
                            plu_name: varItem.plu_name,
                            plu_number: varItem.plu_number,
                            end_at: varItem.end_at,
                            sell_end_at: varItem.sell_end_at,
                            // sell_end_at: '07:00:00',
                            sell_bands: varItem.sell_bands,
                            variant_option_title: varItem.variant_option_title,
                            modifier_group: varItem.modifier_group,
                            sub_product: varItem.sub_product,
                            prep_group: varItem.prep_group,
                            use_note: varItem.use_note,
                          };
                        })
                      : [],
                    modifier_group:
                      subCategoryProduct.modifier_group.length > 0
                        ? subCategoryProduct.modifier_group
                        : [],
                    sub_product: subCategoryProduct.sub_product,
                    use_note: subCategoryProduct.use_note,
                  };
                }),
              }));
            } else {
              return {
                products: category.products.map((categoryProduct) => {
                  return {
                    uuid_product: categoryProduct.uuid_product,
                    is_active: categoryProduct.is_active,
                    is_variant: categoryProduct.is_variant,
                    is_have_group: categoryProduct.is_have_group,
                    is_have_promo: categoryProduct.is_have_promo,
                    is_have_suggestion: categoryProduct.is_have_suggestion,
                    is_package: categoryProduct.is_package,
                    description: categoryProduct.description,
                    sell_end_at: categoryProduct.sell_end_at,
                    sell_bands: categoryProduct.sell_bands,
                    variant_option_title: categoryProduct.variant_option_title,
                    variantItem: categoryProduct.is_variant
                      ? categoryProduct.variant_item.map((varItem) => {
                          return {
                            description: varItem.description,
                            uuid_product: varItem.uuid_product,
                            is_active: varItem.is_active,
                            legend: varItem.legend,
                            plu_name: varItem.plu_name,
                            plu_number: varItem.plu_number,
                            end_at: varItem.end_at,
                            // sell_end_at: '07:00:00',
                            sell_end_at: varItem.sell_end_at,
                            sell_bands: varItem.sell_bands,
                            variant_option_title: varItem.variant_option_title,
                            modifier_group: varItem.modifier_group,
                            sub_product: varItem.sub_product,
                            prep_group: varItem.prep_group,
                            use_note: varItem.use_note,
                          };
                        })
                      : [],
                    modifier_group:
                      categoryProduct.modifier_group.length > 0
                        ? categoryProduct.modifier_group
                        : [],
                    sub_product: categoryProduct.sub_product,
                    use_note: categoryProduct.use_note,
                  };
                }),
              };
            }
          });

          //Note: Looping untuk membuat MAP dari array itemsDetail
          itemsDetail.forEach((item) => {
            item.products.forEach((prodDetail) => {
              const productDetail = {
                variant_item: prodDetail.variantItem,
                is_active: prodDetail.is_active,
                is_variant: prodDetail.is_variant,
                is_have_group: prodDetail.is_have_group,
                is_have_promo: prodDetail.is_have_promo,
                is_have_suggestion: prodDetail.is_have_suggestion,
                is_package: prodDetail.is_package,
                modifier_group: prodDetail.modifier_group,
                end_at: prodDetail.end_at,
                // sell_end_at: '07:00:00',
                sell_end_at: prodDetail.sell_end_at,
                sub_product: prodDetail.sub_product,
                use_note: prodDetail.use_note,
                description: prodDetail.description,
                variant_option_title: prodDetail.variant_option_title,
              };
              // Use uuid_product as the key
              newItems.set(prodDetail.uuid_product, productDetail);
            });
          });
          return { itemsDetails: newItems };
        });
      },
    }),
    {
      name: 'itemsDetails',
      storage: {
        getItem: (name) => {
          const serializedState = localStorage.getItem(name);
          if (!serializedState) return undefined;
          const parsedState = JSON.parse(serializedState);
          parsedState.state.itemsDetails = deserializeMap(
            parsedState.state.itemsDetails,
          );
          return parsedState;
        },
        setItem: (name, state) => {
          const serializedState = { ...state };
          serializedState.state.itemsDetails = serializeMap(
            serializedState.state.itemsDetails,
          );
          localStorage.setItem(name, JSON.stringify(serializedState));
        },
        removeItem: (name) => {
          localStorage.removeItem(name);
        },
      },
    },
  ),
);
