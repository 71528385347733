import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import {
  Backdrop,
  Modal,
  Typography,
  Fade,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useStoreSellTime } from '@state/useStoreSellTime';
import { useStoreAppColor } from '@state/useStoreAppColor';

// Styled container for the modal content
const ModalContent = styled('div')(({ theme }) => ({
  width: '95%',
  maxWidth: 500,
  padding: theme.spacing(6),
  backgroundColor: 'white',
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
  outline: 'none',
  margin: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

// Styles for the modal container
const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: theme.zIndex.drawer + 2,
    outline: 'none',
  },
});

const SellTimeModal = (_props, ref) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));

  // Store a callback function to run when the user clicks Continue.
  const [onContinueCallback, setOnContinueCallback] = useState(null);

  const { invalidTimeProducts } = useStoreSellTime((state) => ({
    invalidTimeProducts: state.invalidTimeProducts,
  }));

  // Expose the "show" method to the parent using ref.
  const show = (onContinue) => {
    setOnContinueCallback(() => onContinue);
    setVisible(true);
  };

  // When user clicks Continue, close the modal and run the callback.
  const handleContinue = () => {
    setVisible(false);
    if (onContinueCallback) {
      onContinueCallback();
    }
  };

  useImperativeHandle(ref, () => ({ show }));

  return (
    <Modal
      aria-labelledby="sell-time-modal-title"
      aria-describedby="sell-time-modal-description"
      open={visible}
      onClose={() => setVisible(false)}
      closeAfterTransition
      className={classes.modal}
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}>
      <Fade in={visible}>
        <ModalContent>
          {/* Icon with pink circle */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 60,
              height: 60,
              margin: '0 auto',
              marginBottom: 2,
              borderRadius: '50%',
              backgroundColor: '#FDEAEA', // Pinkish circle
            }}>
            <WarningAmberOutlinedIcon
              fontSize="large"
              sx={{ color: '#E53935' }} // Red-ish icon color
            />
          </Box>

          {/* Title */}
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', marginBottom: 1 }}
            id="sell-time-modal-title">
            {t('sellTimeAlertTitle')}
          </Typography>

          {/* Description */}
          <Typography
            variant="body2"
            sx={{ color: 'grey', marginBottom: 3 }}
            id="sell-time-modal-description">
            {t('sellTimeAlertDescription')}
          </Typography>

          {/* Render the invalidTimeProducts as an accordion */}
          {invalidTimeProducts && invalidTimeProducts.length > 0 && (
            <Accordion
              sx={{
                textAlign: 'left',
                backgroundColor: '#F7F7F7',
                borderRadius: 1,
                marginBottom: 2,
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="invalid-products-content"
                id="invalid-products-header"
                sx={{
                  minHeight: 'unset', // Remove the default minHeight
                  py: 2, // Set consistent top and bottom padding
                  '& .MuiAccordionSummary-content': {
                    marginY: 0, // Remove extra vertical margin from the content
                  },
                  '&.Mui-expanded': {
                    py: 2, // Smaller top/bottom padding when open
                    minHeight: 'unset', // Optional: remove default minHeight
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: 0, // Remove extra margin in content when open (optional)
                  },
                }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                  {t('sellTimeAlertProductDetails')} - x
                  {invalidTimeProducts.length}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  {invalidTimeProducts.map((item, index) => {
                    // If the product is a variant with a non-empty variant_item array,
                    // render only the variant items that have checked = true.
                    if (
                      item.is_variant &&
                      Array.isArray(item.variant_item) &&
                      item.variant_item.length > 0
                    ) {
                      const checkedVariants = item.variant_item.filter(
                        (variant) => variant.checked,
                      );
                      if (checkedVariants.length > 0) {
                        return (
                          <div key={`${item.uuid_product}-${index}`}>
                            <Typography
                              variant="subtitle2"
                              sx={{ mb: 0.5, color: 'grey' }}>
                              {item.plu_name} (Variant)
                            </Typography>
                            {checkedVariants.map((variant) => (
                              <div key={variant.uuid_product}>
                                <Typography
                                  variant="body2"
                                  sx={{ color: 'grey' }}>
                                  {variant.plu_name}
                                </Typography>
                              </div>
                            ))}
                          </div>
                        );
                      }
                      // If no variants are checked, you might choose not to render anything.
                      return null;
                    }
                    // Otherwise, render the product normally.
                    return (
                      <div
                        key={`${item.uuid_product}-${index}`}
                        style={{ marginBottom: '0.75rem' }}>
                        <Typography variant="subtitle2">
                          {item.plu_name}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          )}

          {/* Optional question or additional text */}
          <Typography
            variant="body2"
            sx={{ textAlign: 'left', marginBottom: 3, marginTop: 3 }}>
            {t('sellTimeAlertQuestion')}
          </Typography>

          {/* Continue Button */}
          <Button
            variant="contained"
            sx={{
              color: appColor.secondaryFontColor,
              backgroundColor: appColor.addButton,
              '&:hover': {
                backgroundColor: appColor.addButton,
              },
            }}
            onClick={handleContinue}
            fullWidth>
            {t('sellTimeAlertButton')}
          </Button>
        </ModalContent>
      </Fade>
    </Modal>
  );
};

export default forwardRef(SellTimeModal);
