
export default function useAddToCartModal() {

    const mergeModifierWithAdditionalRequest = (
        additionalRequest,
        modifierGroup = [],
    ) => {
        let newAdditionalRequest = [];
        newAdditionalRequest = modifierGroup.reduce((acc, value) => {
            const modifiers = value.modifiers.filter((v) => v.checked);
            return [...acc, ...modifiers.map((v) => v.message)];
        }, []);
        if (additionalRequest) {
            newAdditionalRequest.push(additionalRequest);
        }
        return newAdditionalRequest;
    };

    //removing true condition on selected items after confirm
    const removeSelectedItemFromCart = (localItem) => {
        if (!localItem) {
            return;
        }

        // Check if 'group' exists and is an array
        if (Array.isArray(localItem.group) && localItem.group.length !== 0) {
            localItem.group.forEach((items) => {
                // Check if 'items.item' exists and is an array
                if (Array.isArray(items.item)) {
                    items.item.forEach((checkedItem) => {
                        checkedItem.checked = false;
                    });
                }
            });
        } else if (localItem.is_variant && Array.isArray(localItem.variant_item)) {
            localItem.variant_item.forEach((items) => {
                // Check if 'group' exists inside 'items' and is an array
                if (Array.isArray(items.prep_group) && items.prep_group.length !== 0) {
                    items.prep_group.forEach((preps) => {
                        // Check if 'prep_item' exists inside 'preps' and is an array
                        if (Array.isArray(preps.prep_item)) {
                            preps.prep_item.forEach((item) => {
                                item.checked = false;
                            });
                        }
                    });
                }
                items.checked = false;
            });
        }
    };

    const getSpecificItem = (localItem) => {
        return localItem.is_variant
            ? localItem.variant_item.find((item) => item.checked)
            : localItem;
    };

    const disabledButton = (selectedItem, buttonDisabled) => {
        let item = getSpecificItem(selectedItem); // check dulu apakah variant, lalu get item nya
        let notFullFilled = buttonDisabled;

        if (item) {
            let hasPrepGroup = item.group && item.group.length > 0;

            //Cek jika item yang dipilih memiliki prep group & prep item
            if (hasPrepGroup) {
                for (let i = 0; i < item.group.length; i++) {
                    let choiceGroup = item.group[i].item
                        ? item.group[i].item
                        : item.group[i].prep_item;
                    let hasPrepItem = choiceGroup.length > 0;
                    if (hasPrepItem) {
                        let countPrepItem = choiceGroup.filter((x) => x.checked);
                        let qtySelected = 0;
                        for (let j = 0; j < countPrepItem.length; j++) {
                            qtySelected += countPrepItem[j].quantity
                                ? countPrepItem[j].quantity
                                : 1;
                        }
                        if (qtySelected < item.group[i].choose.min) {
                            notFullFilled = true;
                            break;
                        }
                    }
                }
            }

            let hasModifierGroup =
                item.modifier_group && item.modifier_group.length > 0;

            //cek jika ada modifier
            if (hasModifierGroup) {
                for (let i = 0; i < item.modifier_group.length; i++) {
                    let hasPrepItem = item.modifier_group[i].modifiers.length > 0;
                    if (hasPrepItem) {
                        let countPrepItem = item.modifier_group[i].modifiers.filter(
                            (x) => x.checked,
                        );
                        let qtySelected = 0;
                        for (let j = 0; j < countPrepItem.length; j++) {
                            qtySelected += countPrepItem[j].quantity
                                ? countPrepItem[j].quantity
                                : 1;
                        }
                        if (qtySelected < item.modifier_group[i].choose.min) {
                            notFullFilled = true;
                            break;
                        }
                    }
                }
            }
        } else {
            notFullFilled = true;
        }

        return notFullFilled;
    };

    const handleCheckVariant = (groupIndex, itemIndex, selectedItem) => {
        let localItem = { ...selectedItem };
        // Ensure itemIndex is a number, for accurate comparison
        const itemIndexNumber = parseInt(itemIndex);

        // Update checked property for the variant_item
        localItem.variant_item = localItem.variant_item.map((item, index) => {
            const updatedItem = {
                ...item,
                checked: itemIndexNumber === index,
            };

            return updatedItem;
        });

        return localItem;
    };

    const handleCheckModifier = (groupIndex, itemIndex, selectedItem) => {
        let localItem = { ...selectedItem };
        let item = getSpecificItem(localItem); // check dulu apakah variant, lalu get item nya

        if (item) {
            let currentCheckValue =
                item.modifier_group[groupIndex].modifiers[itemIndex].checked;

            item.modifier_group[groupIndex].modifiers[itemIndex].checked =
                !currentCheckValue ? true : !currentCheckValue;
        }
        return localItem;
    };

    const handleCheckPrepItem = (groupIndex, itemIndex, selectedItem) => {
        let localItem = { ...selectedItem };
        let item = getSpecificItem(localItem); // check dulu apakah variant, lalu get item nya
        let choiceGroup = localItem.is_variant
          ? item.prep_group[groupIndex].prep_item
          : item.group[groupIndex].item;
    
        if (item) {
          let currentCheckValue = choiceGroup[itemIndex].checked;
    
          choiceGroup[itemIndex].checked = !currentCheckValue
            ? true
            : !currentCheckValue;
        }
        return localItem;
      };

      const handleChangePrepQuantity = (groupIndex, itemIndex, qty, selectedItem) => {
        let localItem = { ...selectedItem };
        let item = getSpecificItem(localItem); // check dulu apakah variant, lalu get item nya
        let choiceGroup = localItem.is_variant
          ? item.prep_group[groupIndex].prep_item
          : item.group[groupIndex].item;
    
        if (item) {
          choiceGroup[itemIndex].quantity = qty;
        }
        return localItem;
      };

    return [
        mergeModifierWithAdditionalRequest, 
        removeSelectedItemFromCart, 
        disabledButton, 
        handleCheckVariant,
        handleCheckModifier,
        handleCheckPrepItem,
        handleChangePrepQuantity
    ]
}
