import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { updateFontFamily } from '@styles/theme';

export const useStoreFont = create(
  persist(
    (set, get) => ({
      font: '"Helvetica Neue", sans-serif',
      fontScale: 1,
      updateFont: (fontFamily) => {
        updateFontFamily(fontFamily);
        set({ font: fontFamily });
      },
      updateFontScale: (newValue) => {
        updateFontFamily(get().font, newValue);
        set({ fontScale: newValue });
      },
    }),
    {
      name: 'default_font',
    },
  ),
);
