import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Typography,
  Badge,
  Box,
  Grid,
  ButtonBase,
} from '@mui/material';
import { ShoppingCart, GridView } from '@mui/icons-material';
import { convertCurrency } from '@functions/convertNumber';
import theme from '@styles/theme';
import { getColorApp } from '@data/localStorageGetter';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useGeneralContext } from '@context/GeneralContext';
import { useStoreCartItems } from '@state/useStoreCartItems';
import { useStoreFirstCredential } from '@state/useStoreFirstCredential';
import { useStoreSettings } from '@state/useStoreSettings';

const useStyles = makeStyles({
  buttonContainer: {
    position: 'fixed',
    bottom: '-1px',
    width: '100%',
    maxWidth: theme.containerWidth,
  },
  buttonBigScreenPosition: {
    left: `calc(50% - (${theme.containerWidth}px / 2))`,
  },
  button: {
    height: 50,
    width: '100%',
    paddingRight: '22px !important',
  },
  badge: {
    height: 15,
    minWidth: 15,
    fontSize: 10,
    lineHeight: 0.3,
    padding: '0 4px',
    backgroundColor: theme.palette.success.main,
    color: 'white',
  },
  typeSales: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem !important',
    paddingRight: theme.spacing(2),
    color: getColorApp().secondaryFontColor,
  },
  innerButton: {
    justifyContent: 'space-between',
  },
  endIconStyle: {
    alignItems: 'center',
  },
});

const ReFloatingStaticButton = ({ totalPrice, onClick }) => {
  const classes = useStyles();
  const { openDrawer, showCategoryPage } = useGeneralContext();
  const { getBadgeCount } = useStoreCartItems((state) => ({
    getBadgeCount: state.getBadgeCount,
  }));

  const { isLoggedIn } = useStoreFirstCredential((state) => ({
    isLoggedIn: state.isLoggedIn,
  }));
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const { getSetting } = useStoreSettings((state) => ({
    getSetting: state.getSetting,
  }));

  return (
    <Grid
      container
      className={classes.buttonContainer}
      style={{
        backgroundColor: appColor.backgroundColor,
        padding: theme.spacing(2),
      }}>
      <Grid
        style={{
          backgroundColor: appColor.primaryThemeColor,
          width: '100%',
          display: 'flex',
          borderRadius: theme.spacing(1),
        }}>
        {getSetting().isDisplayCategory && (
          <Grid item xs={2} style={{}}>
            <ButtonBase
              id={'drawer'}
              display="flex"
              onClick={() => showCategoryPage(true)}
              style={{
                padding: '6px 0',
                width: '100%',
                borderRight: `1px solid ${appColor.secondaryThemeColor}`,
              }}>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <GridView
                  sx={{ color: getColorApp().secondaryFontColor, fontSize: 20 }}
                />
                <Typography
                  style={{ fontSize: 12, color: appColor.secondaryFontColor }}>
                  Category
                </Typography>
              </Grid>
            </ButtonBase>
          </Grid>
        )}
        <Grid item xs={getSetting().isDisplayCategory ? 10 : 12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
            sx={{}}>
            <Button
              id={'openCheckoutButton'}
              onClick={onClick}
              disabled={getBadgeCount() === 0}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              size="large"
              endIcon={
                <>
                  <Typography variant="caption" className={classes.typeSales}>
                    VIEW CART
                  </Typography>
                  <Badge
                    badgeContent={getBadgeCount()}
                    classes={{ badge: classes.badge }}>
                    <ShoppingCart
                      sx={{
                        fontSize: 24,
                        color: appColor.secondaryFontColor,
                      }}
                    />
                  </Badge>
                </>
              }
              classes={{
                label: classes.innerButton,
                endIcon: classes.endIconsx,
              }}
              className={classes.button}>
              <Typography sx={{ color: appColor.secondaryFontColor }}>
                {getBadgeCount() && getBadgeCount() > 0 && isLoggedIn()
                  ? convertCurrency(totalPrice)
                  : ''}
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReFloatingStaticButton;
