import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Paper,
  ButtonBase,
  Box,
  TextField,
  Button,
  Menu,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import { convertCurrency } from '@functions/convertNumber';
import theme from '@styles/theme';
import { ChevronLeft, Delete } from '@mui/icons-material';
import { useGeneralContext } from '@context/GeneralContext';
import { useTranslation } from 'react-i18next';
import CartItem from './CartItem';
import useStyles from './checkout.styles';
import { useRealmContext } from '@context/RealmContext';
import { useCartContext } from '@context/CartContext';
import { sendPostMessageToParent } from '@functions/trafficController';
import URLS from '@/URLS';
import { useHistory } from 'react-router-dom';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreSiccMember } from '@state/useStoreSiccMember';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { useStoreFirstCredential } from '@state/useStoreFirstCredential';
import { useStoreOrderCategory } from '@state/useStoreOrderCategory';
import { useStoreCartItems } from '@state/useStoreCartItems';
import { useStoreViewBill } from '@state/useStoreViewBill';
import { useCheckoutState } from '@state/checkout/useCheckoutState';
import { useShallow } from 'zustand/react/shallow';
import usePrepareBill from '@hooks/checkout/usePrepareBill';
import { getTimepicker } from '@libs/Checkout/getTimePicker';
import useOnlineStatus from '@hooks/useOnlineStatus';
import { styled } from '@mui/system';
import useCheckOpenCloseStore from '@hooks/useCheckOpenCloseStore';
import { getTableNames } from '@libs/Checkout/getTableNames';
import { useStoreSellTime } from '@state/useStoreSellTime';
import moment from 'moment-timezone';
import { getTimezoneName } from '@libs/getTimeZone';
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.secondary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        overflow: 'none',
        border: 'none', // Disable all borders
        borderBottom: '2px solid gray', // Gray bottom border by default
        borderRadius: '0',
      },
      '&:hover fieldset': {
        borderBottom: '2px solid gray', // Keep the bottom border gray on hover
        borderRadius: '0',
      },
      '&.Mui-focused fieldset': {
        borderBottom: '2px solid black', // Black bottom border when focused
        borderRadius: '0',
      },
    },
    '&:disabled': {
      backgroundColor: 'gray', // Gray background when disabled
      color: 'black', // Black text when disabled
      cursor: 'not-allowed', // Show a "not-allowed" cursor
    },
  },
})(TextField);

const ModalOverlay = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed', // Use fixed positioning
  top: '50%', // Center vertically
  left: '50%', // Center horizontally
  transform: 'translate(-50%, -50%)', // Center both axes
  width: '100%',
  maxWidth: 450,
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1300,
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflowY: 'auto',
}));

const CheckoutPage = ({ onViewBill }) => {
  // const { setCheckoutPage, setContextState } = useCartContext()
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const { outlet } = useStoreOutlet((state) => ({
    outlet: state.outlet,
  }));

  const classes = useStyles(appColor);
  const { updateTableName } = useCheckoutState(
    useShallow((state) => ({
      updateTableName: state.updateTableName,
    })),
  );
  const { removeSelectTime } = useCheckoutState(
    useShallow((state) => ({
      removeSelectTime: state.removeSelectTime,
    })),
  );
  const { resetCheckOut } = useCheckoutState((state) => ({
    resetCheckOut: state.resetCheckOut,
  }));

  usePrepareBill();

  useEffect(() => {
    removeSelectTime();
    resetCheckOut();
  }, []);

  useEffect(() => {
    updateTableName(outlet.tableName);
  }, [outlet]);

  return (
    <ModalOverlay>
      <ModalContainer>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.modalTitle}
          // Add custom classes if needed
        >
          <CheckoutTitle />
        </Box>
        <Box padding={`${theme.spacing(2)}px 0`}>
          <CheckoutContent onViewBill={onViewBill} />
        </Box>
      </ModalContainer>
    </ModalOverlay>
  );
};

const CheckoutClearButton = () => {
  const { getContextState, setContextState } = useCartContext();
  const paymentResId = getContextState('paymentResId');
  const { t } = useTranslation();
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const { removeIsViewBill } = useStoreViewBill((state) => ({
    removeIsViewBill: state.removeIsViewBill,
  }));
  const { clearCart } = useStoreCartItems((state) => ({
    clearCart: state.clearCart,
  }));
  const { removeSelectTime } = useCheckoutState(
    useShallow((state) => ({
      removeSelectTime: state.removeSelectTime,
    })),
  );
  const classes = useStyles(appColor);

  const handleClearCart = () => {
    removeSelectTime();
    removeIsViewBill();
    // localStorage.removeItem(localStorageNames.IS_VIEW_BILL);
    clearCart();
    setContextState({
      paymentResId: {
        visible: false,
      },
    });
  };

  return !paymentResId.visible ? (
    <ButtonBase
      id={'clearButton'}
      onClick={() => {
        handleClearCart();
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 2,
      }}>
      <Delete sx={{ fontSize: 18 }} />
      <Typography sx={{ textAlign: 'center', fontSize: 8 }}>
        {t('voidAll')}
      </Typography>
    </ButtonBase>
  ) : (
    <ButtonBase
      className={`${classes.iconButton} ${classes.removeButton}`}></ButtonBase>
  );
};

const CheckoutTitle = () => {
  const { setCheckoutPage, setContextState } = useCartContext();
  const { t } = useTranslation();
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const classes = useStyles(appColor);
  const { removeSelectTime, setExpandModifier } = useCheckoutState(
    useShallow((state) => ({
      removeSelectTime: state.removeSelectTime,
      setExpandModifier: state.setExpandModifier,
    })),
  );

  const closeModal = () => {
    removeSelectTime();
    setExpandModifier(false);
    setCheckoutPage(false);
    setContextState({
      paymentResId: {
        visible: false,
      },
    });
  };

  return (
    <Box
      id={'titlePage'}
      sx={{
        backgroundColor: appColor.primaryThemeColor,
        color: appColor.secondaryFontColor,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '100%',
        alignItems: 'center',
      }}>
      <ButtonBase id={'backButton'} onClick={closeModal} sx={{ padding: 0 }}>
        <ChevronLeft sx={{ fontSize: 32, marginLeft: 2, padding: 0 }} />
      </ButtonBase>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        {t('orderList').toUpperCase()}
      </Typography>
      <CheckoutClearButton />
    </Box>
  );
};

const CheckoutTaxesGrandTotal = ({ billGrandTotal }) => {
  const { getContextState } = useCartContext();
  const paymentResId = getContextState('paymentResId');
  const { visible } = paymentResId;
  const { billTaxes } = useCheckoutState(
    useShallow((state) => ({
      billTaxes: state.billTaxes,
    })),
  );

  const { getOutletId } = useStoreOutlet((state) => ({
    getOutletId: state.getOutletId,
  }));

  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const classes = useStyles(appColor);

  return (
    visible && (
      <>
        {billTaxes.map(
          (item, index) =>
            item.value !== 0 && (
              <Grid
                key={getOutletId() + index}
                container
                direction="row"
                className={classes.footer}
                spacing={0}
                sx={{ margin: 0, padding: 0 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  {item.title}
                </Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  <b>{convertCurrency(item.value)}</b>
                </Typography>
              </Grid>
            ),
        )}
        {billGrandTotal !== 0 && (
          <>
            <hr sx={{ borderTop: '2px dashed black' }} />
            <Grid
              container
              direction="row"
              className={`${classes.borderingGrid} ${classes.footer}`}
              spacing={0}>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                TOTAL
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                <b>{convertCurrency(billGrandTotal)}</b>
              </Typography>
            </Grid>
          </>
        )}
      </>
    )
  );
};

const CheckoutTablesNames = () => {
  const { getContextState } = useCartContext();
  const paymentResId = getContextState('paymentResId');
  const { t } = useTranslation();
  const { settings } = useStoreSettings((state) => ({
    settings: state.settings,
  }));
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const classes = useStyles(appColor);
  const { siccMember } = useStoreSiccMember((state) => ({
    siccMember: state.siccMember,
  }));

  const {
    parameter,
    updateParameter,
    isErrorTextField,
    tableName,
    updateIsErrorTextField,
  } = useCheckoutState(
    useShallow((state) => ({
      parameter: state.parameter,
      updateParameter: state.updateParameter,
      isErrorTextField: state.isErrorTextField,
      tableName: state.tableName,
      updateIsErrorTextField: state.updateIsErrorTextField,
    })),
  );

  const handleInput = (event) => {
    updateIsErrorTextField(false);
    // Paksa parameter custom table menjadi huruf besar semua
    // untuk mencegah kasus nama sama cuma beda huruf kapital saja
    let parameter = event.target.value.toUpperCase();
    updateParameter(parameter);
  };

  return (
    <Grid id={'parameter'} className={`${classes.typeSales} `}>
      {settings || settings.customTable === 'custom' ? (
        <>
          {settings.isQRStoreParameter || settings.customTable === 'custom' ? (
            <CssTextField
              id="standard-basic"
              borderColor="transparent"
              placeholder={
                settings.placeholderParameter
                  ? settings.placeholderParameter
                  : t('placeholderParameter')
              }
              inputProps={{
                maxLength: settings.orderMethod !== 'quick_order' ? 10 : 20,
              }}
              sx={{
                minWidth: '60%',
                min: 0,
                '& .MuiInputBase-root': {
                  height: 35,
                  padding: 0,
                  display: 'flex',
                  alignItems: 'center',
                },
                '& .MuiInputBase-input': {
                  textAlign: 'center', // Center the placeholder text
                  color: paymentResId.visible ? 'black  !important' : 'inherit',
                },
                '& .MuiFormHelperText-root': {
                  textAlign: 'left',
                  width: '100%',
                  margin: 0,
                },
                '& .Mui-disabled': {
                  color: 'rgba(0, 0, 0, 1)', // Ensure black color when disabled
                  '-webkit-text-fill-color': 'rgba(0, 0, 0, 1)',
                },
              }}
              helperText={isErrorTextField ? t('helperParameter') : ''}
              error={isErrorTextField}
              value={paymentResId.visible ? tableName : parameter}
              onChange={(event) => handleInput(event)}
              autoComplete="off"
              disabled={paymentResId.visible}
            />
          ) : (
            <>
              {tableName ? (
                <Typography
                  component="span"
                  variant="body1"
                  sx={{
                    backgroundColor: appColor.backgroundColor,
                    display: 'inline-block',
                    minWidth: '60%',
                    margin: '.5rem',
                    padding: '.3rem',
                    boxShadow:
                      '0 1px 3px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                    '& .MuiSelect-select.MuiSelect-select': {
                      paddingRight: '0',
                    },
                  }}
                  className={classes.typeSalesTitle}>
                  {
                    //siccMember, settings, tableName, t
                    t('table').toUpperCase() +
                      ' : ' +
                      getTableNames(siccMember, settings, tableName)
                  }
                </Typography>
              ) : (
                siccMember && (
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{
                      backgroundColor: appColor.backgroundColor,
                      display: 'inline-block',
                      minWidth: '60%',
                      margin: '.5rem',
                      padding: '.3rem',
                      boxShadow:
                        '0 1px 3px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                      '& .MuiSelect-select.MuiSelect-select': {
                        paddingRight: '0',
                      },
                    }}
                    className={classes.typeSalesTitle}>
                    {`TA-${siccMember.substring(0, siccMember.length - 2)}`}
                  </Typography>
                )
              )}
            </>
          )}
        </>
      ) : (
        <>
          {tableName ? (
            <Typography
              component="span"
              variant="body1"
              sx={{
                backgroundColor: appColor.backgroundColor,
                display: 'inline-block',
                minWidth: '60%',
                margin: '.5rem',
                padding: '.3rem',
                boxShadow:
                  '0 1px 3px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                '& .MuiSelect-select.MuiSelect-select': {
                  paddingRight: '0',
                },
              }}
              className={classes.typeSalesTitle}>
              {t('table').toUpperCase() +
                ' : ' +
                getTableNames(siccMember, settings, tableName)}
            </Typography>
          ) : (
            <Typography
              component="span"
              variant="body1"
              sx={{
                backgroundColor: appColor.backgroundColor,
                display: 'inline-block',
                minWidth: '60%',
                margin: '.5rem',
                padding: '.3rem',
                boxShadow:
                  '0 1px 3px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                '& .MuiSelect-select.MuiSelect-select': {
                  paddingRight: '0',
                },
              }}
              className={classes.typeSalesTitle}>
              {`TA-${siccMember.substring(0, siccMember.length - 2)}`}
            </Typography>
          )}
        </>
      )}
    </Grid>
  );
};

const CheckoutContent = ({ onViewBill }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const [, checkOnlineStatus] = useOnlineStatus();
  const { openErrorSnackBar } = useGeneralContext();
  const { t } = useTranslation();
  const { settings } = useStoreSettings((state) => ({
    settings: state.settings,
  }));
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const classes = useStyles(appColor);

  const { orderCategory, getCurrentSalesType } = useStoreOrderCategory(
    (state) => ({
      orderCategory: state.orderCategory,
      getCurrentSalesType: state.getCurrentSalesType,
    }),
  );
  const { setConfirmPaymentAdyen } = useCartContext();
  const { getSetting } = useStoreSettings((state) => ({
    getSetting: state.getSetting,
  }));

  const { updateSellTimeProducts, setInvalidTimeProducts } = useStoreSellTime(
    (state) => ({
      updateSellTimeProducts: state.updateSellTimeProducts,
      setInvalidTimeProducts: state.setInvalidTimeProducts,
    }),
  );

  const {
    totalQty,
    totalPrice,
    totalPriceWithCharge,
    cartItems,
    setCartItems,
    removeItemInCart,
    removePromoItem,
  } = useStoreCartItems((state) => ({
    totalQty: state.totalQty,
    totalPrice: state.totalPrice,
    totalPriceWithCharge: state.totalPriceWithCharge,
    cartItems: state.cartItems,
    setCartItems: state.setCartItems,
    removeItemInCart: state.removeItemInCart,
    removePromoItem: state.removePromoItem,
  }));

  const { siccMember } = useStoreSiccMember((state) => ({
    siccMember: state.siccMember,
  }));

  const { showLostConnectionModal, showSellTimeAlert } = useGeneralContext();
  const { getContextState, setContextState } = useCartContext();
  const paymentResId = getContextState('paymentResId');
  const history = useHistory();
  const { checkTokenIsExpired } = useStoreFirstCredential();
  const { getSessionAdyen } = useRealmContext();
  const { getTableId, getOutletId } = useStoreOutlet((state) => ({
    getTableId: state.getTableId,
    getOutletId: state.getOutletId,
  }));
  const { outlet } = useStoreOutlet((state) => ({
    outlet: state.outlet,
  }));

  const {
    serviceCharge,
    billGrandTotal,
    parameter,
    amountCurrency,
    updateIsErrorTextField,
    selectTime,
    updateSelectTime,
  } = useCheckoutState(
    useShallow((state) => ({
      billGrandTotal: state.billGrandTotal,
      serviceCharge: state.serviceCharge,
      parameter: state.parameter,
      amountCurrency: state.amountCurrency,
      updateIsErrorTextField: state.updateIsErrorTextField,
      selectTime: state.selectTime,
      updateSelectTime: state.updateSelectTime,
    })),
  );

  const [, checkOpenCLose] = useCheckOpenCloseStore();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleModalWeather = (orderMethod, parameter) => {
    setContextState({
      weatherWarning: {
        visible: true,
      },
      confirmModal: {
        orderMethod: orderMethod,
        parameter: parameter,
      },
    });
  };

  const isButtonDisabled = paymentResId.visible && billGrandTotal === 0;

  const handleChangeTime = (data) => {
    updateSelectTime(data);
    setAnchorEl(null);
  };

  const setConfirmModalVisibility = (isVisible, orderMethod, parameter) => {
    setContextState({
      confirmModal: {
        visible: isVisible,
        orderMethod: orderMethod,
        parameter: parameter,
      },
    });
  };

  const handleConfirmation = (orderMethod, parameter) => {
    if (checkTokenIsExpired()) {
      sendPostMessageToParent('path', URLS.SESSION_EXPIRED);
      history.replace(URLS.SESSION_EXPIRED);
    } else {
      setConfirmModalVisibility(true, orderMethod, parameter);
    }
  };

  const confirmQuickOrder = (orderMethod) => {
    if (settings.isQRStoreParameter && !parameter) {
      updateIsErrorTextField(true);
      return;
    }
    handleConfirmation(orderMethod, parameter);
  };

  function removeItemsWithZeroQuantity(items) {
    return items.filter((item) => item.quantity !== 0);
  }

  const processOrderConfirmation = () => {
    // Step 1: Remove items with zero quantity.
    const filteredCartItems = removeItemsWithZeroQuantity(cartItems);

    const timezoneName = getTimezoneName(settings.timezone); //convert +8 to Asia/Jakarta
    const currentMoment = moment.tz(timezoneName); // Get current time in specified timezone

    // Step 2: Compute invalidItems based on the current filtered cart.
    const invalidItems = filteredCartItems.filter((filteredItem) => {
      // Parse the sell_end_at time using the formatted offset.
      // Check if the current time is after the sell time.
      let sellEndMoment;
      let endAtMoment;
      if (filteredItem.is_variant && filteredItem.variant_item.length > 0) {
        // Check if variant_item exists
        filteredItem.variant_item.forEach((variant) => {
          // Iterate through variant items
          if (
            (variant.sell_end_at !== undefined ||
              variant.end_at !== undefined) &&
            variant.checked
          ) {
            if (variant.sell_end_at) {
              sellEndMoment = moment.tz(
                variant.sell_end_at,
                'HH:mm:ss',
                timezoneName,
              );
            }
            if (variant.end_at) {
              endAtMoment = moment.tz(
                variant.end_at,
                'YYYY-MM-DD HH:mm:ss',
                timezoneName,
              );
            }
          }
        });
      } else {
        if (filteredItem.sell_end_at) {
          sellEndMoment = moment.tz(
            filteredItem.sell_end_at,
            'HH:mm:ss',
            timezoneName,
          );
        }
        if (filteredItem.end_at) {
          endAtMoment = moment.tz(
            filteredItem.end_at,
            'YYYY-MM-DD HH:mm:ss',
            timezoneName,
          );
        }
      }

      return (
        currentMoment.isAfter(sellEndMoment) ||
        currentMoment.isAfter(endAtMoment)
      );
    });
    // Now update the sellTimeProducts array:
    // Update the sellTime products based on the invalid
    updateSellTimeProducts(invalidItems);
    // Set The Invalid Products
    // For Show what products are invalids
    setInvalidTimeProducts(invalidItems);

    // Step 3: If there are invalid items, trigger the modal alert.
    if (invalidItems.length > 0) {
      showSellTimeAlert(() => {
        // Remove invalid items from the cart.
        const validItems = filteredCartItems.filter((filteredItem) => {
          let isExpired = false;

          if (filteredItem.is_variant && filteredItem.variant_item.length > 0) {
            // For variant items, check each variant
            for (const variant of filteredItem.variant_item) {
              let sellEndMoment, endAtMoment;

              if (variant.sell_end_at || variant.end_at) {
                sellEndMoment = moment.tz(
                  variant.sell_end_at,
                  'HH:mm:ss',
                  timezoneName,
                );
                endAtMoment = moment.tz(
                  variant.end_at,
                  'YYYY-MM-DD HH:mm:ss',
                  timezoneName,
                );
                if (
                  currentMoment.isAfter(sellEndMoment) ||
                  currentMoment.isAfter(endAtMoment)
                ) {
                  isExpired = true;
                  break; // No need to check further if one variant is expired.
                }
              }
            }
          } else {
            let sellEndMoment, endAtMoment;

            if (filteredItem.sell_end_at) {
              sellEndMoment = moment.tz(
                filteredItem.sell_end_at,
                'HH:mm:ss',
                timezoneName,
              );
              if (currentMoment.isAfter(sellEndMoment)) {
                isExpired = true;
              }
            }
            if (filteredItem.end_at) {
              endAtMoment = moment.tz(
                filteredItem.end_at,
                'YYYY-MM-DD HH:mm:ss',
                timezoneName,
              );
              if (currentMoment.isAfter(endAtMoment)) {
                isExpired = true;
              }
            }
          }

          if (isExpired) {
            removeItemInCart(filteredItem); // Optionally remove the expired item.
            return false; // Exclude this item from the validItems array.
          }

          //  remove orphan child items (child items without a valid parent).
          if (filteredItem.parentGroupId) {
            // Check if the parent Item exist.
            const parentExists = filteredCartItems.some(
              (parent) =>
                !parent.groupId &&
                parent.groupId === filteredItem.parentGroupId,
            );

            if (!parentExists) {
              // Optionally, remove the orphan child from the cart.
              removeItemInCart(filteredItem);
              return false;
            }
          }

          return true; // Include valid item.
        });

        // Second pass: remove any promo that doesnt have parent item
        const finalValidItems = validItems.filter((item) => {
          // If the item is a promo child (it has a 'promoFrom' reference)
          if (item.promoFrom) {
            // Check that there is a valid parent whose identifier matches the child's promoFrom.
            const parentExists = validItems.some(
              (parent) =>
                // A valid parent should not have a promoFrom (i.e. it isn’t itself a child)
                !parent.promoFrom &&
                // And its unique identifier (e.g. timestamp) matches the child’s promoFrom
                parent.timestamp === item.promoFrom,
            );
            if (!parentExists) {
              // If no valid parent exists, trigger removal and filter this item out.
              removePromoItem(item);
              return false;
            }
          }
          // Keep non-promo items or promo items with a valid parent.
          return true;
        });

        if (paymentResId.visible) {
          confirmAdyenPayment();
        } else {
          continueOrderConfirmation(finalValidItems);
        }
        // Continue with the rest of the order confirmation process.
      });

      // Stop the process until the user makes a decision in the modal.
      return;
    }
    if (paymentResId.visible) {
      confirmAdyenPayment();
    } else {
      continueOrderConfirmation(filteredCartItems);
    }
    // If no invalid items are found, continue normally.
  };

  const continueOrderConfirmation = (validItems) => {
    setIsProcessing(false);
    // Update the cart items.
    // Prevent SellTime Get Update with empty array when setCartItems run again so set the updateSellTime to true
    setCartItems(validItems);
    if (settings.customTable === 'custom' && !parameter.trim()) {
      updateIsErrorTextField(true);
      return;
    }

    if (
      !getTableId() &&
      selectTime === '' &&
      settings.orderMethod !== 'quick_order' &&
      siccMember
    ) {
      openErrorSnackBar('Select Pickup Time');
      return;
    }

    if (settings && settings.orderMethod) {
      switch (settings.orderMethod) {
        case 'normal_order':
        case 'waiter_order':
          if (validItems.length > 0) {
            handleConfirmation(settings.orderMethod, parameter.trim());
          }
          break;
        case 'payment_order':
          if (totalPrice() > 0 && validItems.length > 0) {
            onViewBill(settings.orderMethod, parameter.trim());
          } else {
            openErrorSnackBar('Invalid Transaction Amount, Minimum $0.1');
          }
          break;
        case 'quick_order':
          if (validItems.length > 0) {
            confirmQuickOrder(settings.orderMethod);
          }
          break;
        default:
          if (validItems.length > 0) {
            handleConfirmation(settings.orderMethod, null);
          }
          break;
      }
    }
  };

  const confirmAdyenPayment = async () => {
    const timestamp = +new Date();
    const params = {
      _partition: getOutletId(),
      amountValue: billGrandTotal * 100, // Multiply by 100
      amountCurrency: amountCurrency,
      transId: `${paymentResId.trans_id}_${timestamp}`,
    };
    const session = await getSessionAdyen(params);
    //cek session apakah berhasil di create atau tidak
    setConfirmPaymentAdyen(true, session);
    setIsProcessing(false);
    // if (!session.error) {
    // } else {
    //   openErrorSnackBar(session.message);
    //   //muncul modal payment failed atau sejenisnya
    // }
  };

  const handleButtonClick = async () => {
    if (isProcessing) return;

    setIsProcessing(true);
    const store = checkOpenCLose(getCurrentSalesType().currentOpen);
    if (store) {
      if (await checkOnlineStatus()) {
        if (checkTokenIsExpired()) {
          sendPostMessageToParent('path', URLS.SESSION_EXPIRED);
          history.replace(URLS.SESSION_EXPIRED);
        } else {
          if (getSetting().weatherWarning && getSetting().useSiccMember) {
            //muncul modal
            if (settings.customTable === 'custom' && !parameter.trim()) {
              updateIsErrorTextField(true);
              return;
            }
            if (
              !getTableId() &&
              selectTime === '' &&
              settings.orderMethod !== 'quick_order'
            ) {
              openErrorSnackBar('Select Pickup Time');
              return;
            }
            handleModalWeather(settings.orderMethod, parameter.trim());
          } else {
            processOrderConfirmation();
          }
        }
      } else {
        showLostConnectionModal(true);
      }
    } else {
      history.replace(URLS.OUTLET_CLOSE);
    }
  };

  return (
    <Paper
      className={classes.contentContainer}
      elevation={3}
      sx={{ padding: '1rem' }}>
      <Box
        id={'bodyPage'}
        className={classes.innerContentContainer}
        key={getOutletId()}>
        <CheckoutTablesNames />

        <Grid id={'salesType'} className={`${classes.typeSales}`}>
          {!getTableId() &&
          settings.orderMethod !== 'quick_order' &&
          siccMember ? (
            <>
              <Button
                className={classes.typeSalesTitle}
                sx={{
                  color: appColor.primaryFontColor,
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  display: 'inline-block',
                  minWidth: '60%',
                  margin: '.5rem',
                  padding: '.3rem',
                  boxShadow:
                    '0 1px 3px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                  '& .MuiSelect-select.MuiSelect-select': {
                    paddingRight: '0',
                  },
                }}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}>
                {selectTime !== '' ? selectTime : `Please Choose Pickup Time`}
              </Button>
              {Boolean(anchorEl) && (
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  classes={{ paper: classes.inputCustom }}>
                  {getTimepicker(
                    getCurrentSalesType().currentOpen,
                    settings.timezone,
                  ).map((item) => (
                    <MenuItem
                      value={item}
                      onClick={() => {
                        handleChangeTime(item);
                      }}>
                      {item}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </>
          ) : (
            <Typography
              component="span"
              variant="body1"
              sx={{
                backgroundColor: appColor.backgroundColor,
                display: 'inline-block',
                minWidth: '60%',
                margin: '.5rem',
                padding: '.3rem',
                boxShadow:
                  '0 1px 3px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                '& .MuiSelect-select.MuiSelect-select': {
                  paddingRight: '0',
                },
              }}
              className={classes.typeSalesTitle}>
              {orderCategory.title}
            </Typography>
          )}
        </Grid>

        <CartItem />

        <Grid
          id={'totalQty'}
          container
          direction="row"
          className={`${classes.footer}`}
          sx={{ margin: 0, padding: 0 }}>
          <Grid item>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              TOTAL QTY
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              <b>{totalQty()}</b>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          id={'totalNominal'}
          container
          direction="row"
          className={`${classes.borderingGrid} ${classes.footer}`}
          sx={{ margin: 0, padding: 0 }}>
          <Grid item>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              {settings.orderMethod === 'payment_order' ? 'SUBTOTAL' : 'TOTAL'}
            </Typography>
          </Grid>
          <Grid item>
            {serviceCharge ? (
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                <b>{convertCurrency(totalPriceWithCharge(serviceCharge))}</b>
              </Typography>
            ) : (
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                <b>{convertCurrency(totalPrice())}</b>
              </Typography>
            )}
          </Grid>
        </Grid>

        <CheckoutTaxesGrandTotal billGrandTotal={billGrandTotal} />

        <Grid item sx={{ width: '100%', textAlign: 'center' }}>
          <Button
            classes={{ label: 'class-btn-confirm' }}
            id={'confirmButton'}
            onClick={handleButtonClick}
            className={classes.buttonConfirm}
            sx={{
              width: '100%',
              color: isButtonDisabled ? 'black' : appColor.secondaryFontColor,
              backgroundColor: isButtonDisabled
                ? 'gray'
                : appColor.primaryThemeColor,
              cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
              marginTop: '.5rem',
              textWeight: 'bold',
              '&:hover': {
                backgroundColor: appColor.primaryThemeColor,
              },
            }}
            variant="contained"
            disabled={isButtonDisabled || isProcessing}>
            {paymentResId.visible
              ? 'Proceed to payment'
              : settings.orderMethod === 'payment_order'
              ? 'Proceed to Checkout'
              : t('confirmOrder')}
          </Button>
        </Grid>
      </Box>
    </Paper>
  );
};

export default CheckoutPage;
