import {
    Box,
    Grid,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOutlet } from '@state/useStoreOutlet';
import { convertCurrency } from '@functions/convertNumber';
import BillContent from './BillContent';
import theme from '@styles/theme';

const useStyles = makeStyles(() => ({
  footerBill: {
    textAlign: 'center',
    position: 'relative',
    bottom: 0,
    left: 0,
    width: '100%',
  },
}));


export default function BillData({ props }) {
    const { t } = useTranslation();
    const { getSetting } = useStoreSettings();
    const { outlet: outletInfo } = useStoreOutlet();
    const classes = useStyles();
    return (
        <>
            <Grid item sx={{ textAlign: 'center' }}>
                <Typography>{props.outlet}</Typography>
            </Grid>
            <Grid item sx={{ textAlign: 'center' }}>
                <Typography>{props.addressStore}</Typography>
            </Grid>
            <hr style={{ borderTop: '1px dashed black' }} />
            <Grid item>
                <Typography>
                    {/* TODO:table digannti ordr nnumber custom raptor/rdo*/}
                    {getSetting().customTable === 'raptor' ||
                        getSetting().customTable === 'rdo'
                        ? `${t('orderNumber')} : ${props.table}`
                        : `${t('table')} : ${props.table}`}
                </Typography>
            </Grid>
            <Grid item sx={{ display: 'flex' }}>
                <Typography sx={{ width: '50%' }}>
                    Rcpt# : {props.rcptNo}
                </Typography>
                <Typography sx={{ width: '50%', textAlign: 'right' }}>
                    Sales No : {props.salesNo}
                </Typography>
            </Grid>
            <hr style={{ borderTop: '1px dashed black' }} />
            <Grid item>
                <Box>Your orders :</Box>
            </Grid>
            <hr style={{ borderTop: '1px dashed black' }} />

            <BillContent props={props} />

            {props.billSurcharge &&
                props.billSurcharge.map((surcharge) => (
                    <>
                        <Grid
                            item
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                            }}>
                            <Box sx={{ width: '60%' }}>{surcharge.plu_name}</Box>
                            <Box sx={{ width: '8%', margin: '0 8px' }}>
                                {outletInfo.currencySymbol}
                            </Box>
                            <Box sx={{ width: '32%', textAlign: 'right' }}>
                                {convertCurrency(surcharge.sub_total, false) === '0'
                                    ? '0'
                                    : convertCurrency(surcharge.sub_total, false)}
                            </Box>
                        </Grid>
                    </>
                ))}

            {props.billDiscount &&
                props.billDiscount.map((discount) => (
                    <>
                        <Grid
                            item
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                            }}>
                            <Box sx={{ width: '60%' }}>{discount.discount_name}</Box>
                            <Box sx={{ width: '8%', margin: '0 8px' }}>
                                {outletInfo.currencySymbol}
                            </Box>
                            <Box sx={{ width: '32%', textAlign: 'right' }}>
                                &#40;
                                {convertCurrency(discount.amount, false) === '0'
                                    ? '0'
                                    : convertCurrency(discount.amount, false)}
                                &#41;
                            </Box>
                        </Grid>
                    </>
                ))}

            {(props.billSurcharge.length !== 0 ||
                props.billDiscount.length !== 0) && (
                    <hr style={{ borderTop: '1px dashed black' }} />
                )}

            <Grid
                item
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                }}>
                <Box sx={{ width: '60%' }}>SUBTOTAL</Box>
                <Box sx={{ width: '8%', margin: '0 8px' }}>
                    {outletInfo.currencySymbol}
                </Box>
                <Box sx={{ width: '32%', textAlign: 'right' }}>
                    {convertCurrency(props.billSubTotal, false) === '0'
                        ? '0'
                        : convertCurrency(props.billSubTotal, false)}
                </Box>
            </Grid>

            {props.billTaxes &&
                props.billTaxes.map(
                    (item) =>
                        item.value !== 0 && (
                            <Grid
                                item
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'nowrap',
                                }}>
                                <Box sx={{ width: '60%' }}>{item.title}</Box>
                                <Box sx={{ width: '8%', margin: '0 8px' }}>
                                    {outletInfo.currencySymbol}
                                </Box>
                                <Box sx={{ width: '32%', textAlign: 'right' }}>
                                    {convertCurrency(item.value, false) === '0'
                                        ? '0'
                                        : convertCurrency(item.value, false)}
                                </Box>
                            </Grid>
                        ),
                )}

            {
                <>
                    <hr style={{ borderTop: '1px dashed black' }} />
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            fontWeight: 'bold',
                            marginBottom: '10px',
                        }}>
                        <Box sx={{ width: '60%' }}>TOTAL</Box>
                        <Box sx={{ width: '8%', margin: '0 8px' }}>
                            {outletInfo.currencySymbol}
                        </Box>
                        <Box sx={{ width: '32%', textAlign: 'right' }}>
                            {convertCurrency(props.billGrandTotal, false) === '0'
                                ? '0'
                                : convertCurrency(props.billGrandTotal, false)}
                        </Box>
                    </Grid>
                </>
            }

            {props.paymentMethod && (
                <>
                    <Grid
                        item
                        sx={{ textAlign: 'center', paddingTop: theme.spacing(4) }}>
                        <Typography variant="h6">{props.paymentMethod}</Typography>
                    </Grid>
                    <Box
                        sx={{ borderTop: '1px dashed black', marginBottom: '2px' }}
                    />
                    <Box sx={{ borderTop: '1px dashed black' }} />
                </>
            )}

            {props.dateTime && (
                <Grid item sx={{ textAlign: 'center' }}>
                    <Typography>{props.dateTime}</Typography>
                </Grid>
            )}

            {getSetting()?.orderMethod === 'payment_order' && (
                <Grid
                    className={classes.footerBill}
                    sx={{
                        padding: '20px 0',
                    }}>
                    <Typography>This is the Official Receipt</Typography>
                    <Typography>Generated by RDO</Typography>
                </Grid>
            )}
        </>
    );
};
