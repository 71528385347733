import React, { lazy, Suspense, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import AuthProvider from '@context/AuthContext';
import theme from '@styles/theme';
import RealmProvider from '@context/RealmContext';
import MiniAppFailurePage from '@pages/MiniAppFailurePage';
import SplashScreen from '@pages/SplashScreen';
import CannotSicc from '@pages/CannotSicc';

import HomePage from '@pages/home/HomePage';
import OrderType from '@pages/OrderCategory';
import CategoryPage from '@pages/CategoryPage';
import SearchProductPage from '@pages/SearchProductPage';
import RewardsPage from '@pages/RewardsPage';
import SuggestionPage from '@pages/SuggestionPage';
import ViewBillPage from '@pages/ViewBillPage';
import ViewTransactionPage from '@pages/ViewTransactionPage';
import FeedbackPage from '@pages/feedback/FeedbackPage';
import ReviewPage from '@pages/review/ReviewPage';

// const HomePage = lazy(() => import(`./pages/home/HomePage`));
const LoginPage = lazy(() => import(`./pages/LoginPage`));
// const OrderCategory = lazy(() => import(`./pages/OrderCategory`));
const MissingTokenPage = lazy(() => import(`./pages/MissingTokenPage`));
const RealmLoginFailurePage = lazy(() =>
  import(`./pages/RealmLoginFailurePage`),
);
const StripePayment = lazy(() => import(`./pages/checkout/StripePayment`));
const DetailTransactionPage = lazy(() =>
  import(`./pages/DetailTransactionPage`),
);
const BrowserNotSupported = lazy(() => import(`./pages/BrowserNotSupported`));
// const SearchProductPage = lazy(() => import(`./pages/SearchProductPage`));

// const FeedbackPage = lazy(() => import(`./pages/feedback/FeedbackPage`));
// const CategoryPage = lazy(() => import(`./pages/CategoryPage`));
// const ViewBillPage = lazy(() => import(`./pages/ViewBillPage`));
const HistoryPage = lazy(() => import(`./pages/HistoryPage`));
// const ViewTransactionPage = lazy(() => import(`./pages/ViewTransactionPage`));
const PaymentPage = lazy(() => import(`./pages/checkout/PaymentPage`));
const SuccessPaymentPage = lazy(() => import(`./pages/SuccessPaymentPage`));
// const SuggestionPage = lazy(() => import(`./pages/SuggestionPage`));
// const RewardsPage = lazy(() => import(`./pages/RewardsPage`));
const RequestTimeOutPage = lazy(() => import(`./pages/RequestTimeout`));
const EmptyOrderCategoryPage = lazy(() => import(`./pages/EmptyOrderCategory`));
const OutletClose = lazy(() => import(`./pages/OutletClosePage`));
const PaymentResult = lazy(() => import(`./pages/checkout/PaymentResult`));
const ViewBillPaymentPage = lazy(() => import(`./pages/ViewBillPaymentPage`));
const CannotPayment = lazy(() => import(`./pages/CannotPayment`));

const CheckoutPage = lazy(() => import(`./pages/checkout/CheckoutPage`));

const useStyles = makeStyles({
  container: {
    margin: '0 auto',
    minHeight: '100vh',
  },
});

const Routes = () => {
  const bigSizeScreen = useMediaQuery('(min-width:600px)');
  const classes = useStyles();
  const [themes, setThemeColor] = useState('');

  const setTheme = (data) => {
    setThemeColor(data);
  };

  const getBackgroundColor = () => {
    return themes?.background_color || theme.siteBackgroundColor;
  };

  const getFontColor = () => {
    return themes?.primary_font_color || theme.palette.text.primary;
  };

  const getStyles = () => {
    return bigSizeScreen
      ? {
          maxWidth: theme.containerWidth,
          backgroundColor: getBackgroundColor(),
          color: getFontColor(),
        }
      : {
          width: '100%',
          minWidth: 300,
          backgroundColor: getBackgroundColor(),
          color: getFontColor(),
        };
  };

  return (
    <div className={classes.container} style={getStyles()}>
      <Suspense fallback={''}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <SplashScreen {...props} setTheme={setTheme} />}
          />
          <Route
            exact
            path="/login"
            render={(props) => (
              <AuthProvider>
                <LoginPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/menu-catalogue"
            render={(props) => (
              <AuthProvider>
                <HomePage {...props} key={props.location.key} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/order-category"
            render={(props) => (
              <AuthProvider>
                <OrderType {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/search-product"
            render={(props) => (
              <AuthProvider>
                <SearchProductPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/feedback"
            render={(props) => (
              <AuthProvider>
                <FeedbackPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/review"
            render={(props) => (
              <AuthProvider>
                <ReviewPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/category"
            render={(props) => (
              <AuthProvider>
                <CategoryPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/view-bill"
            render={(props) => (
              <AuthProvider>
                <ViewBillPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/history-bill"
            render={(props) => (
              <AuthProvider>
                <HistoryPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/suggestion"
            render={(props) => (
              <AuthProvider>
                <SuggestionPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/rewards"
            render={(props) => (
              <AuthProvider>
                <RewardsPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/view-transaction"
            render={(props) => (
              <AuthProvider>
                <ViewTransactionPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/detail-transaction"
            render={(props) => (
              <AuthProvider>
                <DetailTransactionPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/payment"
            render={(props) => (
              <AuthProvider>
                <PaymentPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/payment-stripe"
            render={(props) => (
              <AuthProvider>
                <StripePayment {...props} />
              </AuthProvider>
            )}
          />

          <Route
            exact
            path="/payment-bill"
            render={(props) => (
              <AuthProvider>
                <ViewBillPaymentPage {...props} />
              </AuthProvider>
            )}
          />
          <Route
            exact
            path="/payment-result"
            render={(props) => (
              <AuthProvider>
                <PaymentResult {...props} />
              </AuthProvider>
            )}
          />
          <Route
            path="/success-payment/:type"
            render={(props) => (
              <RealmProvider>
                <SuccessPaymentPage {...props} />
              </RealmProvider>
            )}
          />
          <Route
            exact
            path="/checkout-page"
            render={(props) => <CheckoutPage {...props} />}
          />
          <Route
            exact
            path="/invalid-token"
            render={(props) => <MissingTokenPage type="invalid" {...props} />}
          />
          <Route
            exact
            path="/session-expired"
            render={(props) => <MissingTokenPage type="expired" {...props} />}
          />
          <Route
            exact
            path="/connection-failure"
            render={(props) => <RealmLoginFailurePage {...props} />}
          />
          <Route
            exact
            path="/browser-not-supported"
            render={(props) => <BrowserNotSupported {...props} />}
          />
          <Route
            exact
            path="/request-timeout"
            render={(props) => <RequestTimeOutPage {...props} />}
          />
          <Route
            exact
            path="/miniapp-failure"
            render={(props) => <MiniAppFailurePage {...props} />}
          />
          <Route
            exact
            path="/empty-order-category"
            render={(props) => <EmptyOrderCategoryPage {...props} />}
          />
          <Route
            exact
            path="/outlet-close"
            render={(props) => <OutletClose {...props} />}
          />
          <Route
            exact
            path="/cannot-payment"
            render={(props) => <CannotPayment {...props} />}
          />
          <Route
            exact
            path="/cannot-sicc"
            render={(props) => (
              <>
                <CannotSicc {...props} />
              </>
            )}
          />
        </Switch>
      </Suspense>
    </div>
  );
};

export default Routes;
