import { create } from 'zustand';
import { getProductData, getFlatProductsData } from '@data/localStorageGetter';
// import { detectItemPrice } from "@functions/convertNumber";
import { saveFlatProductData } from '@data/localStorageSetter';

export const useStoreProduct = create((set, get) => ({
  products: getProductData() || '',
  flatProducts: getFlatProductsData() || {},
  kodeProd: '001',
  updateProducts: (newCategories) => {
    let index = 0;
    const categorizedProducts = newCategories.map((category) => {
      if (
        Array.isArray(category.categories) &&
        category.categories.length > 0
      ) {
        return {
          ...category,
          categories: category.categories.map((subCategory) => ({
            ...subCategory,
            index: index++,
          })),
        };
      } else {
        return {
          ...category,
          index: index++,
        };
      }
    });
    set({ products: JSON.stringify(categorizedProducts) });
  },
  setFlatProducts: (products) => {
    let index = 0;
    const flatProducts = products.flatMap((category) => {
      if (
        Array.isArray(category.categories) &&
        category.categories.length > 0
      ) {
        return category.categories.map((subCategory) => ({
          index: index++,
          menu_type: subCategory.menu_type,
          category_name: subCategory.category_name,
          uuid_product_category: subCategory.uuid_product_category,
          isSubCategory: true,
        }));
      } else {
        return {
          menu_type: category.menu_type,
          index: index++,
          category_name: category.category_name,
          uuid_product_category: category.uuid_product_category,
          isSubCategory: false,
        };
      }
    });
    saveFlatProductData(flatProducts);
    set({ flatProducts: flatProducts });
  },
  getProductList: () => {
    return JSON.parse(get().products);
  },
  getCategoryList: () => {
    const products = JSON.parse(get().products);
    let data =
      Array.isArray(products) &&
      products.map((newData) => {
        return { ...newData, isShow: false };
      });

    return data;
  },
}));
