import React, { useEffect, useState } from 'react';
import {
  Drawer,
  List,
  Divider,
  Typography,
  Grid,
  Box,
  ListItemButton,
  ListItem,
} from '@mui/material';
import { GTranslate } from '@mui/icons-material';
import ReDropdownLang from '@components/ReDropdownLang';
import { useGeneralContext } from '@context/GeneralContext';
import { useAuthContext } from '@context/AuthContext';
import ReFooter from '@components/ReFooter';
import { makeStyles } from '@mui/styles';
import localStorageNames from '@data/localStorageNames';
import theme from '@styles/theme';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import packageJson from '~/package.json';
import URLS from '@/URLS';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreViewBill } from '@state/useStoreViewBill';
import CategoryList from './CategoryList';
import useDrawer from '@hooks/useDrawer';
import { useStoreFeedback } from '@state/useStoreFeedback';
import { useStoreFont } from '@state/useStoreFont';

// import { scroller } from 'react-scroll';

const useStyles = makeStyles({
  paper: {
    backgroundColor: (props) => props.backgroundColor + '!important',
    maxWidth: '80%',
    overflow: 'hidden',
  },
  paperBigScreen: {
    backgroundColor: (props) => props.backgroundColor + '!important',
    left: `calc((100% - ${theme.containerWidth}px) / 2) !important`,
    maxWidth: '50%',
    overflow: 'hidden',
  },
  logo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  keterangan: {
    margin: `${theme.spacing(2)}px 0`,
    textAlign: 'center',
  },
});

const ToggleDrawer = ({ isBigScreen, setSendEmailVisible }) => {
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const { font } = useStoreFont((state) => ({
    font: state.font,
  }));
  const classes = useStyles(appColor);
  const { t } = useTranslation();
  const { getContextState, closeDrawer } = useGeneralContext();
  const { getSetting, toggleDevPanel } = useAuthContext();
  const history = useHistory();
  const [transid, setTransId] = useState({});
  const { viewBillId: transID } = useStoreViewBill((state) => ({
    viewBillId: state.viewBillId,
  }));

  const { getLanguages } = useStoreSettings((state) => ({
    getLanguages: state.getLanguages,
  }));

  const [tableNames, getLogoImage, getCredentialUser] = useDrawer();

  const { feedback } = useStoreFeedback((state) => ({
    feedback: state.feedback,
  }));

  useEffect(() => {
    setTransId(transID);
    // eslint-disable-next-line
  }, [transID]);

  return (
    <Drawer
      id="slideDrawer"
      open={getContextState('drawer')}
      anchor="left"
      onClose={() => closeDrawer()}
      transitionDuration={200}
      // className={{ paper: isBigScreen ? classes.paperBigScreen : classes.paper }}
      classes={{ paper: isBigScreen ? classes.paperBigScreen : classes.paper }}>
      <Box
        style={{
          paddingBottom: theme.spacing(10),
          overflowX: 'scroll',
          background: appColor.backgroundColor,
          marginBottom: '30px',
        }}>
        <Grid sx={{ width: '100%' }}>
          <List
            sx={{
              width: '100%',
              padding: 0,
            }}>
            <Grid item className={classes.logo}>
              <img alt="logo" src={getLogoImage()} style={{ height: 100 }} />
            </Grid>
            <Box className={classes.keterangan}>
              <Typography
                variant="body1"
                id="loggedIn"
                sx={{ fontFamily: font }}>
                {t('loggedin')}
              </Typography>
              <Typography variant="body1">{getCredentialUser()}</Typography>
            </Box>
            {!getSetting().isQRStoreParameter ? (
              <>
                <Box className={classes.keterangan}>
                  {getSetting().customTable !== 'custom' && (
                    <>
                      <Divider />
                      <Typography
                        variant="body1"
                        sx={{ margin: `${theme.spacing(2)} 0` }}>
                        {t('table')}
                      </Typography>
                      <Typography variant="body1" className={'textwrapper'}>
                        {tableNames()}
                      </Typography>
                    </>
                  )}
                </Box>
              </>
            ) : null}
            <Divider />
            {getSetting() &&
              getSetting().isShowViewBill &&
              (getSetting().orderMethod === 'normal_order' ||
                getSetting().orderMethod === 'waiter_order') && (
                <>
                  <ListItemButton
                    id="viewBill"
                    onClick={() => {
                      closeDrawer();
                      sendPostMessageToParent('path', URLS.VIEW_BILL);
                      history.push(URLS.VIEW_BILL);
                    }}>
                    {t('viewBill').toUpperCase()}
                  </ListItemButton>
                  <Divider />
                </>
              )}
            {getSetting() && getSetting().orderMethod === 'payment_order' && (
              <>
                <ListItemButton
                  onClick={() => {
                    closeDrawer();
                    sendPostMessageToParent('path', URLS.PAYMENT_BILL);
                    history.push(URLS.PAYMENT_BILL, false);
                  }}>
                  {t('viewTransaction').toUpperCase()}
                </ListItemButton>
                <Divider />
                {transid && (
                  <>
                    <ListItemButton
                      onClick={() => {
                        closeDrawer();
                        setSendEmailVisible();
                      }}>
                      SEND E-RECEIPT
                    </ListItemButton>
                    <Divider />
                  </>
                )}
              </>
            )}

            {import.meta.env.MODE !== 'PRODUCTION' && (
              <ListItemButton onClick={toggleDevPanel}>
                Open Dev Panel
              </ListItemButton>
            )}

            <CategoryList />
            <Divider />
            {Object.keys(feedback).length !== 0 ? (
              <>
                <ListItemButton
                  onClick={() => {
                    closeDrawer();
                    sendPostMessageToParent('path', URLS.FEEDBACK);
                    history.push(URLS.FEEDBACK);
                  }}>
                  {getSetting() && getSetting().feedbackText
                    ? getSetting().feedbackText
                    : t('feedback').toUpperCase()}
                </ListItemButton>
                <Divider />
              </>
            ) : null}

            <ListItem>
              {getLanguages() && getLanguages().length > 1 && (
                <Grid sx={{ display: 'flex', width: '100%' }}>
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '50%',
                    }}>
                    <GTranslate
                      style={{ marginRight: theme.spacing(1) }}
                      fontSize="small"
                    />{' '}
                    {t('language')}
                  </Grid>
                  <Grid item style={{ width: '50%', textAlign: 'right' }}>
                    <ReDropdownLang />
                  </Grid>
                </Grid>
              )}
            </ListItem>

            {/* <ListItem button onClick={() => logout()}>
              <ExitToApp style={{ marginRight: theme.spacing(1) }} /> {t('logout')}
            </ListItem> */}
          </List>
        </Grid>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          background: appColor.backgroundColor,
          width: '100%',
        }}>
        <ReFooter textColor={appColor.primaryFontColor} />
        <Box textAlign="center" fontSize={10} sx={{ width: '100%' }}>
          V {packageJson.version}{' '}
          {localStorage.getItem(localStorageNames.DOMAIN_SERVER) &&
            `[ ${localStorage.getItem(localStorageNames.DOMAIN_SERVER)} ]`}
        </Box>
      </Box>
    </Drawer>
  );
};

export default ToggleDrawer;
