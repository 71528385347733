import React, { useEffect, useState } from 'react';
import { Box, Grid, ButtonBase, Typography, Icon } from '@mui/material';
import {
  ChevronLeft,
  LocalDining,
  ShoppingBasket,
  LocalMall,
  Restaurant,
} from '@mui/icons-material';
import ReFooter from '@components/ReFooter';
import { makeStyles } from '@mui/styles';
import theme from '@styles/theme';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import localStorageNames from '@data/localStorageNames';
import URLS from '@/URLS';
import { getColorApp } from '@data/localStorageGetter';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreSettings } from '@state/useStoreSettings';
import { useStoreOrderCategory } from '@state/useStoreOrderCategory';
import useCheckOpenCloseStore from '@hooks/useCheckOpenCloseStore';
import { useStoreOrderType } from '@state/useStoreOrderType';
import ReDialog from '@components/ReDialog';
import { ScheduleOutlined } from '@mui/icons-material';
import moment from 'moment-timezone';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  innerTitle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    height: theme.spacing(13),
    position: 'relative',
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
  },
  iconButton: {
    position: 'absolute',
    left: theme.spacing(1),
    width: 50,
  },
  typeContainer: {
    marginLeft: '0 !important',
  },
  typeButton: {
    display: 'block',
    width: '70%',
    height: '25vh',
    maxHeight: 200,
    margin: `${theme.spacing(4)} 0`,
    backgroundColor: getColorApp()?.primaryThemeColor,
    color: getColorApp()?.secondaryFontColor,
    borderRadius: 4,
  },
  backIcon: {
    width: 32,
    height: 32,
    color: getColorApp()?.secondaryFontColor,
  },
  disablePanel: {
    opacity: 0.7,
  },
  orderType: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    paddingLeft: '0 !important',
  },
  btnDetails: {
    position: 'absolute !important',
    bottom: 0,
    background: '#FF8A37 !important',
    padding: '10px !important',
    color: 'white !important',
    width: '70%',
    borderRadius: '0 0 8px 8px !important',
  },
});

const OrderType = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  // manggil global state
  const { settings } = useStoreSettings((state) => ({
    settings: state.settings,
  }));
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));

  const iconMaster = {
    'dine-in': (
      <LocalDining
        style={{ fontSize: 70, color: appColor.secondaryFontColor }}
      />
    ),
    'take-away': (
      <ShoppingBasket
        style={{ fontSize: 70, color: appColor.secondaryFontColor }}
      />
    ),
    delivery: (
      <Icon style={{ fontSize: 70, color: appColor.secondaryFontColor }}>
        moped
      </Icon>
    ),
    'pick-up-later': (
      <LocalMall style={{ fontSize: 70, color: appColor.secondaryFontColor }} />
    ),
    'go-food': (
      <Restaurant
        style={{ fontSize: 70, color: appColor.secondaryFontColor }}
      />
    ),
    'grab-food': (
      <Restaurant
        style={{ fontSize: 70, color: appColor.secondaryFontColor }}
      />
    ),
  };

  const { updateOrderCategory } = useStoreOrderCategory((state) => ({
    updateOrderCategory: state.updateOrderCategory,
  }));

  const [OrderTypeNewData, checkOpenCLose] = useCheckOpenCloseStore();
  const [visible, setDetailsVisible] = useState(false);
  const [details, setDetails] = useState('');

  const { order_type } = useStoreOrderType((state) => ({
    order_type: state.order_type,
  }));

  const handleClickType = (type) => {
    const store = checkOpenCLose(type.currentOpen);
    if (store) {
      let localType = { ...type };
      // KARENA IKON BELUM PERLU UNTUK DISIMPAN
      delete localType.icon;
      updateOrderCategory(localType);

      sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
      history.replace(URLS.MENU_CATALOGUE);
    } else {
      sendPostMessageToParent('path', URLS.OUTLET_CLOSE);
      history.replace(URLS.OUTLET_CLOSE);
    }
  };

  const handleClickDetails = (data) => {
    setDetailsVisible(true);
    setDetails(data);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/safari/i)) {
      window.history.replaceState(null, null, history.location.pathname);
    }

    window.history.pushState(null, null, history.location.pathname);
    window.addEventListener('popstate', () => {
      history.go(1);
    });

    //Handle jika refresh page, maka data credential di clear supaya token expired
    if (
      order_type.error ||
      Object.keys(order_type).length === 0 ||
      !Array.isArray(order_type.result.order_types)
    ) {
      sendPostMessageToParent('path', URLS.EMPTY_ORDER_CATEGORY);
      history.replace(URLS.EMPTY_ORDER_CATEGORY);
    }

    const disabledButtons = document.querySelectorAll('.btn-disabled-type');
    if (order_type.result.order_types.length === disabledButtons.length) {
      sendPostMessageToParent('path', URLS.OUTLET_CLOSE);
      history.replace(URLS.OUTLET_CLOSE);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const type = OrderTypeNewData().filter((e) => !e.disabled);
    if (type.length === 1) {
      let localType = { ...type[0] };
      // KARENA IKON BELUM PERLU UNTUK DISIMPAN
      delete localType.icon;
      updateOrderCategory(localType);
      sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
      history.replace(URLS.MENU_CATALOGUE);
    }
    // eslint-disable-next-line
  }, [OrderTypeNewData()]);

  return (
    <Box className={classes.container}>
      <Box
        boxShadow={3}
        className={classes.innerTitle}
        backgroundColor={appColor.primaryThemeColor}
        color={appColor.secondaryFontColor}>
        {settings && settings.isDisplayLogin ? (
          <ButtonBase className={classes.iconButton}>
            <ChevronLeft
              className={classes.backIcon}
              onClick={() => {
                localStorage.removeItem(localStorageNames.USER);
                sendPostMessageToParent('path', URLS.LOGIN);
                history.replace(URLS.LOGIN);
              }}
            />
          </ButtonBase>
        ) : (
          <ButtonBase className={classes.iconButton}></ButtonBase>
        )}
        <Typography
          className={classes.title}
          variant="h6"
          sx={{ fontWeight: 'bold' }}>
          {t('orderCategory').toUpperCase()}
        </Typography>
        <ButtonBase className={classes.iconButton}></ButtonBase>
      </Box>

      <Grid
        id="orderCategories"
        container
        direction="column"
        sx={{
          overflowY: 'auto',
          display: 'block',
          height: '87vh',
          marginTop: '.5rem',
          paddingBottom: '70px',
        }}
        spacing={2}
        className={classes.typeContainer}>
        {Object.keys(OrderTypeNewData()).length !== 0 &&
          Array.isArray(OrderTypeNewData()) &&
          OrderTypeNewData().length > 0 &&
          OrderTypeNewData().map((type, index) => (
            <Grid
              item
              key={type.id}
              className={classes.orderType}
              style={{ paddingBottom: type.disabled ? theme.spacing(6) : 0 }}>
              <ButtonBase
                sx={{
                  display: 'flex',
                  gap: '1rem',
                  flexDirection: 'column',
                  backgroundColor: appColor.primaryThemeColor,
                  borderRadius: 2,
                  color: appColor.secondaryFontColor,
                }}
                id={index}
                className={`${classes.typeButton} ${
                  type.disabled && classes.disablePanel
                } ${type.disabled && 'btn-disabled-type'}`}
                disabled={type.disabled}
                onClick={() => handleClickType(type)}>
                <Box>{iconMaster[type.icon]}</Box>
                <Box>
                  <Typography variant="h5">{type.title}</Typography>
                </Box>
              </ButtonBase>
              {type.disabled && (
                <ButtonBase
                  className={classes.btnDetails}
                  onClick={() => handleClickDetails(type)}>
                  Sorry, we're closed for this order type. Click here for
                  details
                </ButtonBase>
              )}
            </Grid>
          ))}
      </Grid>

      {visible && (
        <ReDialog
          closeModal={() => setDetailsVisible(false)}
          useCloseButton={true}
          title={
            <div>
              <ScheduleOutlined style={{ fontSize: '3rem' }} />
              <h5 style={{ margin: 0 }}>Operational Hours {details.title}</h5>
            </div>
          }
          content={
            <Grid style={{ textAlign: 'center' }}>
              <p>
                {details.days === 'everyday'
                  ? moment().format('dddd')
                  : moment(details.days, 'ddd').format('dddd')}
              </p>
              {details.currentOpen.map((data) => (
                <div>
                  <p>
                    Open: {moment(data.open, 'HH:mm:ss').format('HH:mm')} -
                    Close {moment(data.close, 'HH:mm:ss').format('HH:mm')}
                  </p>
                </div>
              ))}
            </Grid>
          }
        />
      )}
      <Box
        sx={{
          width: '100%',
          maxWidth: '450px',
          position: 'fixed',
          bottom: 0,
          margin: '0 auto',
          paddingBottom: '0',
          display: 'block',
          background: 'white',
          padding: 2,
          textAlign: 'center',
        }}>
        <ReFooter textColor={appColor.primaryFontColor} />
      </Box>
    </Box>
  );
};

export default OrderType;
