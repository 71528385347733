import {
    Box,
    Grid,
} from '@mui/material';
import React from 'react';
import { useStoreProduct } from '@state/useStoreProduct';
import theme from '@styles/theme';

export default function PackageItem({ item }) {
    let subProduct = [];
    const { getProductList } = useStoreProduct();

    getProductList().forEach((productList, index) => {
        if (
            Array.isArray(productList.categories) &&
            productList.categories.length > 0
        ) {
            productList.categories.forEach((categories, index) => {
                let productIndex = categories.products.findIndex(
                    (product) => product.plu_number === item.plu_number,
                );

                if (productIndex !== -1) {
                    subProduct = categories.products[productIndex].sub_product || [];
                }
            });
        } else {
            let productIndex = productList.products.findIndex(
                (product) => product.plu_number === item.plu_number,
            );

            if (productIndex !== -1) {
                const selectedProduct =
                    getProductList()[index].products[productIndex];
                subProduct = selectedProduct.sub_product || [];
            }
        }
    });
    if (subProduct.length > 0) {
        return (
            <>
                {subProduct.map((packageItem) => (
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                        }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', flex: 0.7 }}>
                            <Box sx={{ marginLeft: theme.spacing(3) }}>
                                {`* ${packageItem.quantity * item.quantity} x ${packageItem.plu_name
                                    }`}
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flex: 0.3 }} />
                    </Grid>
                ))}
            </>
        );
    } else {
        return null;
    }
};
