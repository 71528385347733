// Helper function to get all child items of a specific parent group
// Filters items based on the `parentGroupId` matching the given `groupId`
function getChildGroupItems(items, groupId) {
  return items.filter((item) => item.parentGroupId === groupId);
}

// Helper function to update child items' maximum allowed quantity
// when the parent's quantity changes
export const updateChildItemsForParent = (
  items, // List of all items
  parentItem, // The parent item whose quantity changed
  newParentQuantity, // The new quantity of the parent item
) => {
  // Count how many items are in each uuid_group
  const groupCounts = items.reduce((acc, item) => {
    if (item.uuid_group) {
      acc[item.uuid_group] = (acc[item.uuid_group] || 0) + item.quantity;
    }
    return acc;
  }, {});
  // Get all child items of the given parent group
  const childItems = getChildGroupItems(items, parentItem.groupId);
  // Map through all items and update child items where applicable
  return items.map((item) => {
    if (!childItems.some((child) => child.uuid_product === item.uuid_product)) {
      return item;
    }

    if (items.parentGroupId !== parentItem.groupId) {
      return item;
    }

    // Store the base maximum value for the child item if not already stored
    if (!item.choose.baseMax) {
      item.choose.baseMax = item.choose?.max || 0;
    }
    const baseMaxQuantity = item.choose.baseMax; // Original max value for the child item
    const newMaxQuantity =
      newParentQuantity === 1
        ? baseMaxQuantity
        : baseMaxQuantity * newParentQuantity;
    let newQuantity;

    const groupQuantity = groupCounts[item.uuid_group] || 0;
    // Adjust the quantity if the group quantity exceeds the new max quantity
    if (groupQuantity > newMaxQuantity) {
      newQuantity = Math.max(item.quantity - 1, 0); // Ensure quantity doesn't go below 0
    }
    newQuantity = Math.min(item.quantity, newMaxQuantity); // Ensure quantity doesn't exceed new max

    // Return the updated item with the new max and quantity adjustments
    // Parent item modifier is added to the child item quantity to ensure it reflects the parent's quantity and indicate user selection that adding the parent item will also add the child item
    return {
      ...item,
      choose: { ...item.choose, max: newMaxQuantity },
      quantity: newQuantity,
    };
  });
};

// Helper function to adjust quantities of items in the same group
// Ensures total quantity does not exceed the group's max allowed quantity
export const adjustGroupItems = (
  items, // List of all items
  currentItem, // The item being updated
  newQuantity, // The new quantity for the current item
  groupMax, // Maximum allowed quantity for the group
) => {
  // Get all items in the same group as the current item
  const groupItems = items.filter(
    (x) =>
      x.uuid_group === currentItem.uuid_group &&
      x.parentGroupId === currentItem.parentGroupId,
  );

  const remainingQuantity = groupMax - newQuantity; // Remaining quantity after updating current item
  // Map through all items and update group items where applicable
  return items.map((item) => {
    // Update the quantity of the current item
    if (
      item.uuid_product === currentItem.uuid_product &&
      item.parentGroupId === currentItem.parentGroupId
    ) {
      return { ...item, quantity: Math.max(0, newQuantity) };
    }

    // Skip items that are not part of the current group
    if (
      !groupItems.some(
        (groupItem) =>
          groupItem.uuid_product === item.uuid_product &&
          groupItem.parentGroupId === item.parentGroupId,
      )
    ) {
      return item;
    }

    // Adjust the quantity for other items in the group
    const newItemQuantity =
      remainingQuantity <= 0 ? 0 : Math.min(item.quantity, remainingQuantity);

    return { ...item, quantity: newItemQuantity };
  });
};
