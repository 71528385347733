import { create } from "zustand"
import { persist } from "zustand/middleware"
import localStorageNames from "@data/localStorageNames";

export const useTokenCredentials = create(persist((set, get) => ({
    token:  '',
    updateTokenCredentials: (newCreds) => {
        set({ token: newCreds })
    },
    getTokenCredentials: () => {
        return get().token
    }, 
    getUserLogin: async () => {
        ///check payload kalo ada isinya login, kalo {} berhenti 
    }, 
}), {
    name: localStorageNames.TOKEN, 
}))
