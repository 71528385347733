import moment from "moment-timezone";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import URLS from "@/URLS";
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreSettings } from "@state/useStoreSettings";
import { useStoreOrderType } from "@state/useStoreOrderType";

const useCheckOpenCloseStore = () => { 
    const history = useHistory();

    const { order_type } = useStoreOrderType(state => ({
        order_type: state.order_type
    })) 

    const { settings } = useStoreSettings(state => ({
        settings: state.settings
    }))

    const OrderTypeNewData = () => {
        if (order_type && order_type.result.order_types) {
            const order = order_type.result.order_types;
            let time = moment().utcOffset(+settings.timezone);
            let currentTime = moment(time.format('HH:mm:ss'), 'HH:mm:ss');
            const compareDay = moment(time.format('ddd'), 'ddd');
            const closestDay = (data) => {
                let x = data.find((time) => {
                    const diffday = moment(time.days, 'ddd').diff(compareDay, 'days');
                    return diffday >= 0;
                });
                if (x) return x;
                if (data.length > 0) return data[0];
                return []
            } 
            for (let i = 0; i < order.length; i++) {
                order[i].disabled = true;
                let closestdays= closestDay(order[i].work_hours);
                
                order[i].work_hours.filter((e) => {
                    if (e.days === "everyday" || time.format('ddd').toLocaleLowerCase() === e.days) {
                        let open = moment(e.open, 'HH:mm:ss');
                        let close = moment(e.close, 'HH:mm:ss');
                        if (currentTime.isBetween(open, close)) {
                            order[i].disabled = false;
                            order[i].currentOpen = e;
                        }
                    } 
                })
                if(order[i].disabled) {
                    let y = order[i].work_hours.filter((e) => e.days === "everyday" || time.format('ddd').toLocaleLowerCase() === e.days)
                    order[i].currentOpen = y;
                    if(y.length === 0) {
                        order[i].days = closestdays ? closestdays.days : '';
                        order[i].currentOpen = closestdays ? [closestdays] : [{open: '', close: ''}];
                    } else {
                        order[i].days = y[0].days;
                    }
                }
            }
            return order;
        } else {
            sendPostMessageToParent('path', URLS.OUTLET_CLOSE);
            history.replace(URLS.OUTLET_CLOSE);
        }
    }
    
    const checkOpenCLose = (data) => {
        let result = false;
        const time = moment().utcOffset(+settings.timezone);
        const currentTime = moment(time.format('HH:mm:ss'), 'HH:mm:ss');
        const open = moment(data.open, 'HH:mm:ss');
        const close = moment(data.close, 'HH:mm:ss');
        if (currentTime.isBetween(open, close)) {
            result= true;
        }
        return result
    };
    
    return [OrderTypeNewData, checkOpenCLose];
};

export default useCheckOpenCloseStore;