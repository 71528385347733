import React, { useState } from 'react';
import { makeStyles, withStyles } from '@mui/styles';

import {
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Checkbox,
  Box,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '@styles/theme';
import { convertCurrency } from '@functions/convertNumber';
import { useTranslation } from 'react-i18next';
import ReButtonQuantityCart from './ReButtonQuantityCart';
import { getPriceBySellBand } from '@functions/getItemPriceBySellBand';
import { useGeneralContext } from '@context/GeneralContext';
import { getColorApp } from '@data/localStorageGetter';
import { useAuthContext } from '@context/AuthContext';
import ReLazyImage from './ReLazyImage';
import { useStoreNutriGrade } from '@state/useStoreNutriGrade';
import { useMediaQuery } from '@mui/system';
import { useStoreAppColor } from '@state/useStoreAppColor';
const useStyles = makeStyles(() => ({
  accordionIdle: {
    color: getColorApp().primaryFontColor,
  },
  accordionActive: {
    color: getColorApp().primaryFontColor,
  },
  accordionSuccess: {
    color: getColorApp().secondaryFontColor,
  },
  expandIcon: {
    color: 'inherit',
  },
  headingTitle: {
    height: 48,
    minHeight: `48px !important`,
  },
  headingContent: {
    width: '80%',
  },
  textWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  accordionDetail: {
    backgroundColor: getColorApp().backgroundAccordion,
    color: getColorApp().primaryFontColor,
  },
  widthButtonQty: {
    marginTop: '.5rem',
    width: 125,
    alignSelf: 'center',
  },
  widthCurrency: {
    width: 160,
    marginLeft: theme.spacing(2),
  },
  borderCheckbox: {
    padding: '0px 5px 0px 0 !important',
    marginRight: theme.spacing(1),
  },
}));

const Accordion = withStyles({
  root: {
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const OptionalItem = ({
  id,
  qtyProduct,
  prepItem,
  checkItem,
  changeQuantity,
  max,
  isOrderable,
  isModifier,
  getSetting,
  t,
}) => {
  const bigSizeScreen = useMediaQuery('(min-width:450px)');
  const classes = useStyles();
  const { getSellBandId } = useGeneralContext();
  const { urlNutriGrade } = useStoreNutriGrade((state) => ({
    urlNutriGrade: state.urlNutriGrade,
  }));

  const disableLogic = (prepItems, item) => {
    // Filter out items that are not checked and do not match the given uuid_product
    const checkedItem = prepItems.filter(
      (item) => item.hasOwnProperty('checked') && item.checked === true,
    );
    // Calculate the total quantity selected
    const totalQtySelected = checkedItem.reduce((total, item) => {
      // Default quantity to 1 if not explicitly set
      return total + (item.quantity || 1);
    }, 0);

    if (item.checked) {
      return false;
    } else if (!item.checked && totalQtySelected >= max * qtyProduct) {
      return true;
    }
  };

  const actionQtyButton = (index, value) => {
    changeQuantity(index, value);
  };

  const actionChecboxClick = (index) => {
    checkItem(index);
    if (!isModifier) {
      changeQuantity(index, 1);
    }
  };

  const countCurrentQty = (prepItems) => {
    // Filter out items that are not checked and do not match the given uuid_product
    const checkedItem = prepItems.filter(
      (item) => item.hasOwnProperty('checked') && item.checked === true,
    );

    // Calculate the total quantity selected
    const totalQtySelected = checkedItem.reduce((total, item) => {
      // Default quantity to 1 if not explicitly set
      return total + (item.quantity || 1);
    }, 0);

    return totalQtySelected;
  };

  return (
    <>
      {Array.isArray(prepItem) &&
        prepItem.map((item, index) => (
          <Box
            id={`optional-${index}`}
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
            sx={{ margin: '4px 0' }}
            key={index}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              sx={{
                width:
                  item.checked && item.use_quantity
                    ? '35%'
                    : bigSizeScreen
                    ? '80%'
                    : '65%',
              }}>
              <Box
                display="flex"
                flexDirection="row"
                onClick={() =>
                  isOrderable &&
                  (!item.uuid_product_modifier
                    ? item.is_active
                    : item.uuid_product_modifier) &&
                  !disableLogic(prepItem, item) &&
                  actionChecboxClick(index, item.quantity)
                }
                style={{ justifyContent: 'start' }}>
                {isOrderable && (
                  <Checkbox
                    id={`customCheckBox-${index}`}
                    checked={item.checked || false}
                    disabled={disableLogic(prepItem, item)}
                    className={classes.borderCheckbox}
                    sx={{
                      color: (
                        !item.uuid_product_modifier
                          ? item.is_active
                          : item.uuid_product_modifier
                      )
                        ? getColorApp().successAccordion
                        : '#00000042',
                    }}
                  />
                )}
                <Box
                  id={`titleCheckBox-${index}`}
                  component="div"
                  whiteSpace="normal"
                  alignSelf="center"
                  sx={{ marginRight: theme.spacing(1) }}>
                  <Typography variant="body2">
                    {isModifier ? item.message : item.plu_name}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {item.checked && item.use_quantity && (
              <Box className={classes.widthButtonQty} style={{ width: '30%' }}>
                <ReButtonQuantityCart
                  onChangeValue={(value) => actionQtyButton(index, value)}
                  max={max * qtyProduct}
                  qtyProduct={item.quantity}
                  isDisableMax={
                    countCurrentQty(prepItem) > max * qtyProduct - 1
                  }
                  small={!bigSizeScreen}
                />
              </Box>
            )}
            {urlNutriGrade(item, 'm') && (
              <Box className={`nutri-grade`} sx={{ width: '10%' }}>
                <ReLazyImage
                  url={urlNutriGrade(item, 'm')}
                  style={{ width: 40, verticalAlign: 'middle' }}
                  index="nutri-grade-product"
                />
              </Box>
            )}
            <Box
              id={`nominalCheckBox-${index}`}
              display="flex"
              alignSelf="center"
              justifyContent="flex-end"
              sx={{
                width: urlNutriGrade(item, 'm')
                  ? '25%'
                  : item.checked && item.use_quantity
                  ? '35%'
                  : '50%',
              }}
              className={classes.widthCurrency}>
              {(getPriceBySellBand(item, getSellBandId()) !== 0 ||
                (!item.is_active && !item.uuid_product_modifier)) && (
                <Typography variant="caption" className={classes.textWrapper}>
                  {item.is_active
                    ? convertCurrency(getPriceBySellBand(item, getSellBandId()))
                    : getSetting() && getSetting().soldOutText
                    ? getSetting().soldOutText
                    : t('outOfStock')}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
    </>
  );
};

const RePrepItemAccordion = ({
  maxQuantity,
  qtyProduct,
  prepGroup,
  checkItem,
  changeQuantity,
  isOrderable,
  isModifier,
  isPrepFromVariat,
}) => {
  const classes = useStyles();
  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));
  const { getSetting } = useAuthContext();
  const [expandedPanel, setExpandedPanel] = useState(0);
  const { t } = useTranslation();

  const handleExpand = (event, index) => {
    setExpandedPanel(index === expandedPanel ? null : index);
  };

  const accordionStyle = (index, prepItem = [], min) => {
    let checkedItem = prepItem.filter((x) => x.checked);
    let qtySelected = 0;
    for (let j = 0; j < checkedItem.length; j++) {
      qtySelected += checkedItem[j].quantity ? checkedItem[j].quantity : 1;
    }

    let background = {
      backgroundColor: getColorApp().headerAccordion,
      color: getColorApp().primaryFontColor,
    };

    if (qtySelected >= min) {
      background = {
        backgroundColor: getColorApp().successAccordion,
        color: getColorApp().secondaryFontColor,
      };
    }

    return background;
  };

  const checkActivePrep = (group) => {
    if (isPrepFromVariat) return group.prep_item.length > 0;
    const choiceGroup = isModifier
      ? group.modifiers.length > 0
      : group.item.length > 0;
    return choiceGroup;
  };

  return (
    <Grid>
      {Array.isArray(prepGroup) &&
        prepGroup.map(
          (group, index) =>
            checkActivePrep(group) && (
              <Box style={{ marginBottom: theme.spacing(2) }} key={index}>
                {getSetting().isProductOptionFold ? (
                  <Accordion
                    id={`accordion-${index}`}
                    key={group.uuid_group}
                    style={{
                      ...accordionStyle(
                        index,
                        isModifier ? group.modifiers : group.item,
                        group.choose.min,
                      ),
                    }}
                    defaultExpanded={true}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      classes={{
                        expandIcon: classes.expandIcon,
                        root: classes.headingTitle,
                        content: classes.headingContent,
                      }}>
                      <Typography
                        id={`accordionTitle-${index}`}
                        variant="subtitle2"
                        className={classes.textWrapper}>
                        {group.group_name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      className={classes.accordionDetail}
                      sx={{
                        backgroundColor: appColor.backgroundAccordion,
                        color: appColor.primaryFontColor,
                        padding: 1,
                      }}>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography
                            variant="caption"
                            id={`accordionCondition-${index}`}>
                            {`${t('minChoose')} = ${group.choose.min}, ${t(
                              'maxChoose',
                            )} = ${group.choose.max} ${
                              !isModifier ? `(${t('perproduct')})` : ``
                            }`}
                          </Typography>
                        </Grid>
                        {isModifier &&
                          group.modifiers &&
                          group.modifiers.length > 0 && (
                            <OptionalItem
                              id={index}
                              qtyProduct={qtyProduct}
                              prepItem={group.modifiers}
                              checkItem={(itemIndex) =>
                                checkItem(index, itemIndex)
                              }
                              max={group.choose.max}
                              isOrderable={isOrderable}
                              isModifier={isModifier}
                              getColorApp={getColorApp}
                              getSetting={getSetting}
                              t={t}
                            />
                          )}
                        {!isModifier &&
                          group.prep_item &&
                          group.prep_item.length > 0 && (
                            <OptionalItem
                              id={index}
                              qtyProduct={qtyProduct}
                              prepItem={group.prep_item}
                              checkItem={(itemIndex) =>
                                checkItem(index, itemIndex)
                              }
                              changeQuantity={(itemIndex, qty) =>
                                changeQuantity(index, itemIndex, qty)
                              }
                              max={isModifier ? 0 : group.choose.max}
                              isOrderable={isOrderable}
                              getColorApp={getColorApp}
                              getSetting={getSetting}
                              t={t}
                            />
                          )}
                        {!isModifier && group.item && group.item.length > 0 && (
                          <OptionalItem
                            id={index}
                            qtyProduct={qtyProduct}
                            prepItem={group.item}
                            checkItem={(itemIndex) =>
                              checkItem(index, itemIndex)
                            }
                            changeQuantity={(itemIndex, qty) =>
                              changeQuantity(index, itemIndex, qty)
                            }
                            max={isModifier ? 0 : group.choose.max}
                            isOrderable={isOrderable}
                            getColorApp={getColorApp}
                            getSetting={getSetting}
                            t={t}
                          />
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <Accordion
                    id={`accordion-${index}`}
                    key={group.uuid_group}
                    style={{
                      ...accordionStyle(
                        index,
                        isModifier ? group.modifiers : group.item,
                        group.choose.min,
                      ),
                    }}
                    expanded={expandedPanel === index}
                    onChange={(event) => {
                      handleExpand(event, index);
                    }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      classes={{
                        expandIcon: classes.expandIcon,
                        root: classes.headingTitle,
                        content: classes.headingContent,
                      }}>
                      <Typography
                        id={`accordionTitle-${index}`}
                        variant="subtitle2"
                        className={classes.textWrapper}>
                        {group.group_name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetail}>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography
                            variant="caption"
                            id={`accordionCondition-${index}`}>
                            {`${t('minChoose')} = ${group.choose.min}, ${t(
                              'maxChoose',
                            )} = ${group.choose.max} ${
                              !isModifier ? `(${t('perproduct')})` : ``
                            }`}
                          </Typography>
                        </Grid>
                        {isModifier &&
                          group.modifiers &&
                          group.modifiers.length > 0 && (
                            <OptionalItem
                              id={index}
                              qtyProduct={qtyProduct}
                              prepItem={group.modifiers}
                              checkItem={(itemIndex) =>
                                checkItem(index, itemIndex)
                              }
                              max={group.choose.max}
                              isOrderable={isOrderable}
                              isModifier={isModifier}
                              getColorApp={getColorApp}
                              getSetting={getSetting}
                              t={t}
                            />
                          )}
                        {!isModifier &&
                          group.prep_item &&
                          group.prep_item.length > 0 && (
                            <OptionalItem
                              id={index}
                              qtyProduct={qtyProduct}
                              prepItem={group.prep_item}
                              checkItem={(itemIndex) =>
                                checkItem(index, itemIndex)
                              }
                              changeQuantity={(itemIndex, qty) =>
                                changeQuantity(index, itemIndex, qty)
                              }
                              max={isModifier ? 0 : group.choose.max}
                              isOrderable={isOrderable}
                              getColorApp={getColorApp}
                              getSetting={getSetting}
                              t={t}
                            />
                          )}
                        {!isModifier && group.item && group.item.length > 0 && (
                          <OptionalItem
                            id={index}
                            qtyProduct={qtyProduct}
                            prepItem={group.item}
                            checkItem={(itemIndex) =>
                              checkItem(index, itemIndex)
                            }
                            changeQuantity={(itemIndex, qty) =>
                              changeQuantity(index, itemIndex, qty)
                            }
                            max={isModifier ? 0 : group.choose.max}
                            isOrderable={isOrderable}
                            getColorApp={getColorApp}
                            getSetting={getSetting}
                            t={t}
                          />
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Box>
            ),
        )}
    </Grid>
  );
};

export default RePrepItemAccordion;
