import {
  Box,
  ButtonBase,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import theme from '@styles/theme';
import { convertCurrency } from '@functions/convertNumber';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { ChevronLeft, SearchRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '@context/AuthContext';
import { useCartContext } from '@context/CartContext';
import { useGeneralContext } from '@context/GeneralContext';
import { getPriceBySellBand } from '@functions/getItemPriceBySellBand';
import { getImageThumnailList } from '@functions/imageOptimization';
import { v4 as uuidv4 } from 'uuid';
import ReLegend from '@components/ReLegend';
import URLS from '@/URLS';
import ReButtonOrder from '@components/ReButtonOrder';
import { AutoSizer, List } from 'react-virtualized';
import ReLazyImage from '@components/ReLazyImage';
import { getColorApp } from '@data/localStorageGetter';
import { sendPostMessageToParent } from '@functions/trafficController';
import { useStoreNutriGrade } from '@state/useStoreNutriGrade';
import { useStoreCategory } from '@state/useStoreCategory';
import { useStoreAppColor } from '@state/useStoreAppColor';
import { useStoreSellTime } from '@state/useStoreSellTime';
import useSearchProductPage from '@hooks/useSearchProductPage';

const useStyles = makeStyles({
  layoutToolbar: {
    alignItems: 'center',
    textAlign: 'center',
    height: theme.spacing(13),
    width: '100%',
  },
  iconButton: {
    width: 50,
    marginLeft: theme.spacing(3),
  },
  title: {
    width: '100%',
    fontWeight: 'bold',
  },
  inputForm: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: getColorApp().backgroundColor,
    '& label.Mui-focused': {
      color: (props) => props.primaryFontColor,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: (props) => props.primaryFontColor,
      },
    },
  },

  itemHeight: {
    height: 90,
  },
  panelItem: {
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
    textAlign: 'left',
    maxHeight: 90,
  },
  itemImage: {
    display: 'inline-block',
    float: 'left',
    width: 120,
  },
  itemInformationWrapper: {
    display: 'inline-block',
    float: 'left',
    width: `calc(100% - 120px)`,
    height: '100%',
  },
  itemInformationWithImage: {
    paddingTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flex: 1,
  },
  itemInformationNoImage: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  productName: {
    height: '30px',
    width: '55%',
    float: 'left',
    minHeight: 10,
  },
  price: {
    width: '45%',
    float: 'left',
    textAlign: 'right',
  },
  menus: {
    borderRadius: 10,
  },
  disabledMenus: {
    opacity: 0.7,
    filter: 'grayscale(1)',
  },
  disablePanel: {
    backgroundColor: '#F5F5F5',
  },
});

const PanelComponent = ({
  classes,
  t,
  getSellBandId,
  getSetting,
  data,
  handleClickItem,
}) => {
  const { urlNutriGrade } = useStoreNutriGrade((state) => ({
    urlNutriGrade: state.urlNutriGrade,
  }));
  return (
    <Grid
      item
      className={`${classes.panelItem} ${classes.itemHeight} ${
        !data.is_active && classes.disablePanel
      }`}
      sx={{
        background: getColorApp().backgroundProduct,
      }}>
      <Box
        className={classes.itemImage}
        onClick={() => {
          handleClickItem(data, 'all');
        }}>
        {data.url && (
          <ReLazyImage
            url={getImageThumnailList(data.url)}
            style={{ width: 120, height: 120 }}
            index="product"
          />
        )}
      </Box>
      <Box
        className={classes.itemInformationWrapper}
        sx={data.url ? { width: `calc(100% - 120px)` } : { width: '100%' }}
        onClick={() => {
          handleClickItem(data, 'all');
        }}>
        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          sx={{ height: '100%' }}>
          <Box
            className={
              data.url
                ? classes.itemInformationWithImage
                : classes.itemInformationNoImage
            }>
            <Typography
              className={`${classes.productName} clampOnLine2`}
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                wordBreak: 'break-word',
                WebkitLineClamp: '2',
                width: data.url ? '55%' : '65%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                lineHeight: 1.05,
                marginTop: data.url ? theme.spacing(1) : 2,
              }}
              variant="subtitle2">
              {data.plu_name}
            </Typography>
            <Box
              component="div"
              textOverflow="ellipsis"
              overflow="hidden"
              fontSize={12}
              className={classes.price}
              sx={
                data.url
                  ? { marginTop: theme.spacing(1) }
                  : { whiteSpace: 'nowrap' }
              }>
              <b>
                {data.is_active
                  ? convertCurrency(
                      getPriceBySellBand(data, getSellBandId(), true),
                    )
                  : getSetting() && getSetting().soldOutText
                  ? getSetting().soldOutText
                  : t('outOfStock')}
              </b>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            flex="1">
            {urlNutriGrade(data, 'h') && (
              <Box
                className={`nutri-grade`}
                sx={{ paddingLeft: theme.spacing(2) }}>
                <ReLazyImage
                  url={urlNutriGrade(data, 'h')}
                  style={{ width: 80 }}
                  index="nutri-grade-product"
                />
              </Box>
            )}
            {data.legend && <ReLegend legend={data.legend} type="list" />}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

const SearchProductPage = () => {
  const history = useHistory();
  const classes = useStyles(getColorApp());
  const { t } = useTranslation();
  const { getProductList, getSetting } = useAuthContext();
  const { getBadgeCount, isLoggedIn } = useCartContext();
  const { getSellBandId } = useGeneralContext();
  const [localState, setLocalState] = useState({
    products: [],
    tempProducts: [],
    keyword: '',
  });

  const { sellTimeProducts } = useStoreSellTime((state) => ({
    sellTimeProducts: state.sellTimeProducts,
  }));

  const { appColor } = useStoreAppColor((state) => ({
    appColor: state.appColor,
  }));

  const { removeActiveSection } = useStoreCategory((state) => ({
    removeActiveSection: state.removeActiveSection,
  }));

  const [actionSearch, handleClickItem] = useSearchProductPage();

  const getLocalState = (key) => {
    return key ? localState[key] : localState;
  };

  const updateLocalState = (newData) => {
    setLocalState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const rowRenderer = ({ key, index, style }) => {
    const tempProducts = getLocalState('tempProducts');
    const dataItem = tempProducts[index];

    // Get the matching sellTimeProduct from the array.
    const matchingSellTimeProduct = sellTimeProducts.find(
      (prod) => prod.uuid_product === dataItem.uuid_product,
    );

    // Determine the is_active value. You can provide a default if not found.
    const isActive = matchingSellTimeProduct
      ? matchingSellTimeProduct.is_active
      : dataItem.is_active;

    const data = { ...dataItem, is_active: isActive };

    const isLastItem = index === tempProducts.length - 1;
    return (
      <div
        id={`item-${index}`}
        key={key}
        style={{
          ...style,
          paddingTop: '5px',
          paddingRight: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          marginBottom: isLastItem ? '10px' : '0px',
        }}>
        <Box
          boxShadow={3}
          className={`${classes.menus} ${
            !data.is_active && classes.disabledMenus
          }`}
          key={index}>
          <ButtonBase style={{ width: '100%' }} disabled={!data.is_active}>
            <PanelComponent
              classes={classes}
              t={t}
              getSellBandId={getSellBandId}
              getSetting={getSetting}
              data={data}
              handleClickItem={(data, type) =>
                handleClickItem({ ...data, signature_token: uuidv4() }, type)
              }
            />
          </ButtonBase>
        </Box>
      </div>
    );
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.match(/safari/i)) {
      window.history.replaceState(null, null, history.location.pathname);
    }

    window.history.pushState(null, null, history.location.pathname);
    window.addEventListener('popstate', () => {
      history.go(1);
    });

    const productCategories = getProductList();

    const localProducts = !productCategories
      ? []
      : productCategories?.reduce((prev, curr) => {
          let result = [...prev];
          if (
            Array.isArray(curr.categories) &&
            curr.categories !== null &&
            curr.categories.length > 0
          ) {
            curr.categories.forEach((cat) => {
              cat.products.forEach((prod) => (result = [...result, prod]));
            });
          } else {
            curr.products.forEach((prod) => (result = [...result, prod]));
          }

          return result;
        }, []);
    updateLocalState({ products: localProducts });
    removeActiveSection();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      overflow="hidden"
      style={
        getBadgeCount() > 0 && isLoggedIn()
          ? { paddingBottom: theme.spacing(18) }
          : { paddingBottom: theme.spacing(1) }
      }>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          boxShadow={3}
          bgcolor={getColorApp().primaryThemeColor}
          color={getColorApp().secondaryFontColor}
          className={classes.layoutToolbar}>
          <ButtonBase className={classes.iconButton}>
            <ChevronLeft
              style={{ width: 32, height: 32 }}
              onClick={() => {
                sendPostMessageToParent('path', URLS.MENU_CATALOGUE);
                history.replace(URLS.MENU_CATALOGUE);
              }}
            />
          </ButtonBase>
          <Typography className={classes.title} variant="h6">
            {t('searchProduct').toUpperCase()}
          </Typography>
          <Box className={classes.iconButton} />
        </Box>
        <Box
          display="flex"
          className={classes.inputForm}
          sx={{ backgroundColor: appColor.backgroundColor }}>
          <FormControl
            variant="outlined"
            size="small"
            color="primary"
            fullWidth
            focused>
            <OutlinedInput
              id="inputSearch"
              type={'text'}
              placeholder={t('searchPlaceholder')}
              labelWidth={0}
              autoFocus
              onChange={(event) => {
                updateLocalState({ keyword: event.target.value });
              }}
              style={{ backgroundColor: 'white' }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    id="actionClickSearch"
                    onClick={() => {
                      const res = actionSearch(
                        getLocalState('keyword'),
                        getLocalState('products'),
                      );
                      updateLocalState({ tempProducts: res });
                    }}
                    edge="end">
                    <SearchRounded />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Box>

      <Box display="flex" flexGrow={1}>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <List
                id="listResultSearch"
                width={width}
                height={height}
                rowCount={
                  getLocalState().tempProducts
                    ? getLocalState().tempProducts?.length
                    : 0
                }
                rowHeight={100}
                rowRenderer={rowRenderer}
              />
            );
          }}
        </AutoSizer>
      </Box>

      {getBadgeCount() > 0 && isLoggedIn() ? (
        <ReButtonOrder />
      ) : (
        <Box display="flex" style={{ paddingTop: theme.spacing(2) }} />
      )}
    </Box>
  );
};

export default SearchProductPage;
