import React, { useEffect, useState } from 'react';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useStoreFont } from '@state/useStoreFont';
const ReDropDownFont = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedFont, setSelectedFont] = useState("'Roboto', sans-serif");

  const { font, updateFont } = useStoreFont((state) => ({
    font: state.font,
    updateFont: state.updateFont,
  }));
  const fontOptions = {
    merri: 'MerriWeather',
    oswald: 'Oswald, serif',
    roboto: 'Roboto, sans-serif',
    timesNewRoman: 'Times New Roman, Times, serif',
    helvetica: '"Helvetica Neue", sans-serif',
    urbanist: '"Urbanist", serif',
    publicsans: '"Public Sans", serif',
    manrope: '"Manrope", serif',
    inter: '"Inter", serif',
    outfit: '"Outfit", serif',
    DMSans: '"DM Sans", serif',
    // Add more fonts as needed
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (fontKey) => {
    if (fontKey) {
      // setSelectedFont(fontOptions[fontKey]);
      updateFont(fontOptions[fontKey]);
    }
    setAnchorEl(null);
  };

  const getFontName = (fontValue) => {
    return (
      Object.keys(fontOptions).find((key) => fontOptions[key] === fontValue) ||
      'Font'
    ).replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
  };

  return (
    <div style={{ fontFamily: font, zIndex: 10 }}>
      <Button
        id="DropdownFont"
        onClick={handleClick}
        variant="contained"
        color="inherit"
        size="small"
        style={{ minWidth: 45 }}>
        {getFontName(font)} <KeyboardArrowDown />
      </Button>
      <Menu
        hideBackdrop
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
        disablePortal>
        {Object.keys(fontOptions).map((fontKey) => (
          <MenuItem key={fontKey} onClick={() => handleClose(fontKey)}>
            {fontKey.charAt(0).toUpperCase() + fontKey.slice(1)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ReDropDownFont;
